import maplibregl from "maplibre-gl";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sessionActions } from "store";
import { usePreference } from "../../common/util/preferences";
import { map } from "../core/MapView";

const MapDefaultCamera = () => {
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const positions = useSelector((state) => state.session.positions);
  const user = useSelector((state) => Object.values(state.session.user));
  const dispatch = useDispatch();
  const initialized = useSelector((state) => state.session.initialized);
  const latitude = usePreference("latitude");
  const longitude = usePreference("longitude");
  const zoom = usePreference("zoom", 0);
  const defaultLatitude = user[9] ? user[9] : latitude;
  const defaultLongitude = user[10] ? user[10] : longitude;
  const defaultZoom = user[11] ? user[11] : zoom;

  useEffect(() => {
    if (selectedDeviceId) {
      dispatch(sessionActions.setInitialized(true));
    } else if (!initialized) {
      if (defaultLatitude && defaultLongitude) {
        map.jumpTo({
          center: [defaultLongitude, defaultLatitude],
          zoom: defaultZoom,
        });
        dispatch(sessionActions.setInitialized(true));
      } else {
        const coordinates = Object.values(positions).map((item) => [
          item.longitude,
          item.latitude,
        ]);
        if (coordinates.length > 1) {
          const bounds = coordinates.reduce(
            (bounds, item) => bounds.extend(item),
            new maplibregl.LngLatBounds(coordinates[0], coordinates[1])
          );
          const canvas = map.getCanvas();
          map.fitBounds(bounds, {
            duration: 0,
            padding: Math.min(canvas.width, canvas.height) * 0.1,
          });
          dispatch(sessionActions.setInitialized(true));
        } else if (coordinates.length) {
          const [individual] = coordinates;
          map.jumpTo({
            center: individual,
            zoom: Math.max(map.getZoom(), 10),
          });
          dispatch(sessionActions.setInitialized(true));
        }
      }
    }
  }, [
    selectedDeviceId,
    initialized,
    defaultLatitude,
    defaultLongitude,
    defaultZoom,
    positions,
  ]);

  return null;
};

export default MapDefaultCamera;
