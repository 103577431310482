import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  useMediaQuery,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  TextField,
  Link,
  Snackbar,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sessionActions } from "../store";
import {
  useLocalization,
  useTranslation,
} from "../common/components/LocalizationProvider";
import LoginLayout from "./LoginLayout";
import usePersistedState from "../common/util/usePersistedState";
import {
  handleLoginTokenListeners,
  nativeEnvironment,
  nativePostMessage,
} from "../common/components/NativeInterface";
import LogoImage from "./LogoImage";
import { useCatch } from "../reactHelper";

const useStyles = makeStyles((theme) => ({
  options: {
    position: "fixed",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: " 45px",
    marginBottom: "28px",
    cursor: " pointer",
    fontSize: " 0.875rem",
    padding: " 0.375rem 0.75rem",
    color: " #0090e7 !important",
    borderColor: " #0090e7 !important",
    fontWeight: " initial",
    "&:hover": {
      color: " #000 !important",
      backgroundColor: " #0090e7",
      borderColor: " #0090e7",
    },
  },
  extraContainer: {
    display: "flex",
    gap: theme.spacing(2),
  },
  registerButton: {
    minWidth: "unset",
  },
  resetPassword: {
    cursor: "pointer",
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  Input: {
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "#fff" },
    "& .MuiInputLabel-formControl": { color: "#ffffff" },
    "& .MuiInputBase-root": { background: "initial" },
    "& .MuiInputBase-input": { color: "#ffffff" },
    "& label": {
      fontSize: "1.275rem",
      lineHeight: "1",
      verticaAlign: "top",
      color: "#00ac69 !important",
    },
    "& input": {
      border: "1px solid #2c2e33",
      height: " calc(2.25rem + 2px)",
      fontWeight: " normal",
      fontSize: "16px",
      padding: "0.625rem 0.6875rem",
      paddingTop: " 0.625rem",
      paddingBottom: " 0.625rem",
      backgroundColor: " #2A3038",
      borderRadius: " 2px",
      color: " #ffffff",
      marginTop: "10px",
    },
    "& input:hover::before": {
      display: "none !important",
    },
    "& input:hover::after": {
      display: "none !important",
    },
    "& input:focus": {
      fontSize: "16px",
      border: " 1px solid rgba(71, 164, 71, 0.5)",
      backgroundColor: " #2A3038",
      color: " #ffffff",
    },
    [theme.breakpoints.down("md")]: {
      "& input": {
        height: "calc(1.25rem + 2px)  !important",
      },
    },
  },
  CheckboxInput: {
    "& .MuiFormControlLabel-label": { color: "#00e5ff" },
  },
  dialogBox: {
    backgroundColor: "green",
    "& .MuiPaper-root .MuiDialog-paperScrollPaper .MuiDialog-paper .MuiDialogTitle-root":
      {
        backgroundColor: "#0f0e15",
      },
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const t = useTranslation();

  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({
    code: values[0],
    name: values[1].name,
  }));

  const [failed, setFailed] = useState(false);
  const [email, setEmail] = usePersistedState("loginEmail", "");
  const [password, setPassword] = useState("");

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isAccountDisabledDialog, setAccountDisabledDialog] = useState(false);

  const registrationEnabled = useSelector(
    (state) => state.session.server.registration
  );
  const languageEnabled = useSelector(
    (state) => !state.session.server.attributes["ui.disableLoginLanguage"]
  );
  const emailEnabled = useSelector(
    (state) => state.session.server.emailEnabled
  );
  const openIdEnabled = useSelector(
    (state) => state.session.server.openIdEnabled
  );
  const openIdForced = useSelector(
    (state) =>
      state.session.server.openIdEnabled && state.session.server.openIdForce
  );

  const [announcementShown, setAnnouncementShown] = useState(false);
  const announcement = useSelector(
    (state) => state.session.server.announcement
  );

  const generateLoginToken = async () => {
    if (nativeEnvironment) {
      let token = "";
      try {
        const expiration = moment().add(6, "months").toISOString();
        const response = await fetch("/api/session/token", {
          method: "POST",
          body: new URLSearchParams(`expiration=${expiration}`),
        });
        if (response.ok) {
          token = await response.text();
        }
      } catch (error) {
        token = "";
      }
      nativePostMessage(`login|${token}`);
    }
  };

  const handlePasswordLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("/api/session", {
        method: "POST",
        body: new URLSearchParams(
          `email=${encodeURIComponent(email)}&password=${encodeURIComponent(
            password
          )}`
        ),
      });
      if (response.ok) {
        const user = await response.json();
        generateLoginToken();
        dispatch(sessionActions.updateUser(user));
        navigate("/");
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      setFailed(true);
      setPassword("");
    }
  };

  const handleTokenLogin = useCatch(async (token) => {
    const response = await fetch(
      `/api/session?token=${encodeURIComponent(token)}`
    );
    if (response.ok) {
      const user = await response.json();
      dispatch(sessionActions.updateUser(user));
      navigate("/");
    } else {
      throw Error(await response.text());
    }
  });

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && email && password) {
      handlePasswordLogin(e);
    }
  };

  const handleOpenIdLogin = () => {
    document.location = "/api/session/openid/auth";
  };

  useEffect(() => nativePostMessage("authentication"), []);

  useEffect(() => {
    const listener = (token) => handleTokenLogin(token);
    handleLoginTokenListeners.add(listener);
    return () => handleLoginTokenListeners.delete(listener);
  }, []);

  useEffect(() => {
    if (openIdForced) {
      handleOpenIdLogin();
      return <LinearProgress />;
    }
  }, [openIdForced]);

  const AccountDisabledDialog = () => (
    <Dialog
      fullScreen={fullScreen}
      open={isAccountDisabledDialog}
      onClose={() => {
        setAccountDisabledDialog(false);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        id="responsive-dialog-title"
        style={{ backgroundColor: "#0f0e15" }}
      >
        Your account is disabled
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "#0f0e15" }}>
        <DialogContentText>
          Please contact customer service{" "}
          <a href="tel:0421 888 738">0421 888 738</a>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ backgroundColor: "#0f0e15" }}>
        <Button
          onClick={() => {
            setAccountDisabledDialog(false);
          }}
          color="primary"
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <LoginLayout>
      <div className={classes.dialogBox}>
        <AccountDisabledDialog />
      </div>
      <div className={classes.options}>
        {nativeEnvironment && (
          <Tooltip title={t("settingsServer")}>
            <IconButton onClick={() => navigate("/change-server")}>
              <LockOpenIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>

      <div className={classes.container}>
        <TextField
          required
          error={failed}
          label={t("userEmail")}
          InputLabelProps={{ shrink: true }}
          InputProps={{ disableUnderline: true }}
          name="email"
          value={email}
          variant="standard"
          placeholder="Enter Email Address"
          autoComplete="email"
          autoFocus={!email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyUp={handleSpecialKey}
          className={classes.Input}
          helperText={failed && "Invalid username or password"}
        />
        <TextField
          required
          error={failed}
          label={t("userPassword")}
          InputLabelProps={{ shrink: true }}
          InputProps={{ disableUnderline: true }}
          variant="standard"
          name="password"
          placeholder="Enter Password"
          value={password}
          type="password"
          autoComplete="current-password"
          autoFocus={!!email}
          className={classes.Input}
          onChange={(e) => setPassword(e.target.value)}
          onKeyUp={handleSpecialKey}
        />
        <Button
          onClick={handlePasswordLogin}
          onKeyUp={handleSpecialKey}
          className={classes.submit}
          variant="outlined"
          color="primary"
          disabled={!email || !password}
        >
          {t("loginLogin")}
        </Button>
        {openIdEnabled && (
          <Button
            onClick={() => (document.location = "/api/session/openid/auth")}
            variant="contained"
            color="secondary"
          >
            {t("loginOpenId")}
          </Button>
        )}
        <div className={classes.extraContainer}>
          {/* <Button
            className={classes.registerButton}
            onClick={() => navigate('/register')}
            disabled={!registrationEnabled}
            color="secondary"
          >
            {t('loginRegister')}
          </Button> */}
          {/* {languageEnabled && (
            <FormControl fullWidth>
              <InputLabel>{t('loginLanguage')}</InputLabel>
              <Select label={t('loginLanguage')} value={language} onChange={(e) => setLanguage(e.target.value)}>
                {languageList.map((it) => <MenuItem key={it.code} value={it.code}>{it.name}</MenuItem>)}
              </Select>
            </FormControl>
          )} */}
        </div>
        {/* {emailEnabled && (
          <Link
            onClick={() => navigate("/reset-password")}
            className={classes.resetPassword}
            underline="none"
            variant="caption"
          >
            {t("loginReset")}
          </Link>
        )} */}
      </div>
      <Snackbar
        open={!!announcement && !announcementShown}
        message={announcement}
        action={
          <IconButton
            size="small"
            color="inherit"
            onClick={() => setAnnouncementShown(true)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </LoginLayout>
  );
};

export default LoginPage;
