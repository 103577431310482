import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation as t } from "common/components/LocalizationProvider";
import { useSelector } from "react-redux";

import RemoveDialog from "RemoveDialog";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const EditCollectionView = ({ content, editPath, endpoint, type }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState(null);
  const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
  const [removeDialogShown, setRemoveDialogShown] = useState(false);
  const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());
  const adminEnabled = useSelector(
    (state) => state.session.user && state.session.user.administrator
  );

  const menuShow = (anchorId, itemId) => {
    setSelectedAnchorEl(anchorId);
    setSelectedId(itemId);
  };

  const menuHide = () => {
    setSelectedAnchorEl(null);
  };

  const handleAdd = () => {
    navigate(editPath);
    menuHide();
  };

  const handleEdit = () => {
    navigate(`${editPath}/${selectedId}`);
    menuHide();
  };

  const handleRemove = () => {
    setRemoveDialogShown(true);
    menuHide();
  };

  const handleRemoveResult = () => {
    setRemoveDialogShown(false);
    setUpdateTimestamp(Date.now());
  };

  const Content = content;
  return (
    <>
      {type == "standalone" ? (
        <>{Content}</>
      ) : (
        <Content updateTimestamp={updateTimestamp} onMenuClick={menuShow} />
      )}

      {/** * adminEnabled &&
        <Fab size="medium" color="primary" className={classes.fab} onClick={handleAdd}>
          <AddIcon />
        </Fab>
      ** */}
      <Menu
        open={!!selectedAnchorEl}
        anchorEl={selectedAnchorEl}
        onClose={menuHide}
      >
        <MenuItem onClick={handleEdit}>{t("sharedEdit")}</MenuItem>
        <MenuItem onClick={handleRemove}>{t("sharedRemove")}</MenuItem>
      </Menu>
      <RemoveDialog
        open={removeDialogShown}
        endpoint={endpoint}
        itemId={selectedId}
        onResult={handleRemoveResult}
      />
    </>
  );
};

export default EditCollectionView;
