import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  greetingText: {
    fontSize: "16px",
    color: "#fff",
    marginBottom: 0,
    fontWeight: 700,
    paddingBottom: "4px",
  },
  currentDate: {
    fontSize: "20px",
    color: "#00ac69",
    marginBottom: 0,
    fontWeight: 700,
    paddingBottom: "4px",
  },
  currentTime: {
    color: "#ffffff",
  },
}));

const Greetings = () => {
  const [greetings, setGreetings] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const classes = useStyles();

  useEffect(() => {
    const timeInterval = setInterval(() => {
      const currentHour = moment().format("HH");
      let greetingsText;

      if (currentHour >= 3 && currentHour < 12) {
        greetingsText = "Good Morning";
      } else if (currentHour >= 12 && currentHour < 15) {
        greetingsText = "Good Afternoon";
      } else if (currentHour >= 15 && currentHour < 20) {
        greetingsText = "Good Evening";
      } else if (currentHour >= 20 && currentHour < 3) {
        greetingsText = "Good Night";
      } else {
        greetingsText = "Hello";
      }

      setGreetings(greetingsText);
      setCurrentDate(moment().format("dddd DD MMM yyyy"));
      setCurrentTime(moment().format("H:mm:ss"));
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, []);

  return (
    <>
      <Typography className={classes.greetingText}>{greetings}</Typography>
      <Typography className={classes.currentDate}>{currentDate}</Typography>
      <Typography className={classes.currentTime}>{currentTime}</Typography>
    </>
  );
};
export default Greetings;
