import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDKriFW8edvY-Um-0OoFkWSaYldBDxY4Y8",
  authDomain: "icugps.firebaseapp.com",
  projectId: "icugps",
  storageBucket: "icugps.appspot.com",
  messagingSenderId: "110610280921",
  appId: "1:110610280921:web:69db46f3977b0b38d369c8",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound, setCurrentNotificationToken) => {
  if (isSupported()) {
    return getToken(messaging, {
      vapidKey:
        "BAJoa18C07MzPiN0FyvEXcDId3FEB2iFYpSvmbpzz9Hy3C7fu8zwhzfW0FHNvmmO91NoPQOaoFMow_ihmkcwodM",
    })
      .then((currentToken) => {
        if (currentToken) {
          // console.log('current token for client: ', currentToken);
          setCurrentNotificationToken(currentToken);
          setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          // console.log('No registration token available. Request permission to generate one.');
          setCurrentNotificationToken(null);
          setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        // console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      });
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
