import React, { useEffect, useState } from "react";
import MainToolbar from "components/MainToolbar";
import makeStyles from "@mui/styles/makeStyles";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Paper,
  FormControl,
  FormControlLabel,
  Switch,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Card,
  CardHeader,
  FormGroup,
} from "@mui/material";
import { convertUnicode, useEffectAsync } from "reactHelper";
import { useDispatch, useSelector } from "react-redux";
import { AlarmAddOutlined } from "@mui/icons-material";
import _ from "lodash";
import { devicesActions, notificationActions, settingActions } from "../store";
import { speedToKnots } from "common/util/converter";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
    "& .MuiSwitch-switchBase": {
      color: "#f30707",
    },
    "& .MuiSwitch-colorPrimary.Mui-checked": {
      color: "#3f51b5",
    },
  },
  btn: {
    "& div": {
      margin: "5px",
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  columnAction: {
    // width: theme.spacing(1),
    // padding: theme.spacing(0, 1),
  },
}));

const SpeedAlarmPage = () => {
  const classes = useStyles();

  const selectedDevice = useSelector((state) => state.devices.selectedItem);
  const selectedDeviceId = selectedDevice?.id;

  const items = useSelector((state) => Object.values(state.devices.items));

  // const [PurchasedDevices, setPurchasedDevices] = useState([])

  // const PurchasedDevices = useSelector(state => state.setting.PurchasedDevices);
  const dispatch = useDispatch();
  const [deviceId, setDeviceId] = useState();

  const [SelectedDeviceData, setSelectedDeviceData] = useState();

  useEffectAsync(async () => {
    const response = await fetch("/api/devices");
    if (response.ok) {
      // console.log("Device list updated");
      const devices = await response.json();
      dispatch(devicesActions.refresh(devices));
    }

    const res = await fetch(`/api/devices/${selectedDeviceId}`);
    if (res.ok) {
      const data = await res.json();

      const attributes = data?.attributes;
      const accessConfiguration = attributes.accessConfiguration?.split(",");

      dispatch(settingActions.UpdateAttributes({ ...attributes }));

      if (accessConfiguration && accessConfiguration.length > 0) {
        dispatch(
          settingActions.SetisSetIgnitionVisible(accessConfiguration[0] === "1")
        );
        dispatch(
          settingActions.SetIsSpeedingAlarmVisible(
            accessConfiguration[1] === "1"
          )
        );
        dispatch(
          settingActions.SetIsSleepModeVisible(accessConfiguration[2] === "1")
        );
        dispatch(
          settingActions.SetisTempVisible(accessConfiguration[3] === "1")
        );
        dispatch(
          settingActions.SetisTempMapVisible(accessConfiguration[9] === "1")
        );
        dispatch(
          settingActions.SetisGroupVisible(accessConfiguration[8] === "1")
        );
        dispatch(
          settingActions.UpdateDriverVisible(accessConfiguration[4] === "1")
        );
        dispatch(
          settingActions.UpdateDriverBehaviourVisible(
            accessConfiguration[5] === "1"
          )
        );
        dispatch(
          settingActions.UpdateMaintenanceVisible(
            accessConfiguration[6] === "1"
          )
        );
        dispatch(
          settingActions.UpdateNotificationsVisible(
            accessConfiguration[7] === "1"
          )
        );
        dispatch(
          settingActions.UpdateGroupsVisible(accessConfiguration[8] === "1")
        );
        dispatch(
          settingActions.SetHoursEnabled(accessConfiguration[10] === "1")
        );
        dispatch(
          settingActions.SetisMileageVisible(accessConfiguration[11] === "1")
        );
        dispatch(
          settingActions.SetisPreferenceVisible(accessConfiguration[12] === "1")
        );
        dispatch(
          settingActions.SetisCalendarVisible(accessConfiguration[13] === "1")
        );
        dispatch(
          settingActions.SetisAssignedGroupVisible(
            accessConfiguration[14] === "1"
          )
        );
      } else {
        dispatch(settingActions.SetisSetIgnitionVisible(false));
        dispatch(settingActions.SetIsSpeedingAlarmVisible(false));
        dispatch(settingActions.SetIsSleepModeVisible(false));
        dispatch(settingActions.SetisTempVisible(true));
        dispatch(settingActions.SetisTempMapVisible(false));
        dispatch(settingActions.SetisGroupVisible(false));
        dispatch(settingActions.UpdateDriverVisible(false));
        dispatch(settingActions.UpdateDriverBehaviourVisible(false));
        dispatch(settingActions.UpdateMaintenanceVisible(false));
        dispatch(settingActions.UpdateNotificationsVisible(false));
        dispatch(settingActions.UpdateGroupsVisible(false));
        dispatch(settingActions.SetHoursEnabled(false));
        dispatch(settingActions.SetisMileageVisible(false));
        dispatch(settingActions.SetisPreferenceVisible(false));
        dispatch(settingActions.SetisCalendarVisible(false));
        dispatch(settingActions.SetisAssignedGroupVisible(false));
      }

      const contact = data.contact?.split(",");
      if (contact && contact.length > 0) {
        dispatch(settingActions.UpdateIgnition(contact[0] === "1"));
        dispatch(settingActions.UpdateSleepMode(contact[2] !== "0"));
        dispatch(settingActions.UpdateSpeedingAlarm(contact[1]));
        dispatch(settingActions.UpdateTempAlarm(contact[3]));
      }
      dispatch(settingActions.UpdateGroupId(data?.groupId));
    }
  }, [deviceId, selectedDeviceId]);

  return (
    <div className={classes.root}>
      <MainToolbar />
      <div className={classes.content}>
        <Grid container spacing={2}>
          {/* {true && ( */}
          <>
            <Grid item xs={12} md={3} lg={2}>
              <Paper className={classes.form}>
                <>
                  <FormControl variant="filled" margin="normal" fullWidth>
                    <InputLabel>Vehicle</InputLabel>

                    <Select
                      value={deviceId}
                      onChange={(e) => {
                        setDeviceId(e.target.value);
                        setSelectedDeviceData(
                          items.filter((d) => d.id === e.target.value)[0]
                        );
                      }}
                      MenuProps={{
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >
                      {_.orderBy(
                        items,
                        [(item) => item.name.toLowerCase()],
                        ["asc"]
                      ).map((device) => {
                        let deviceAccessConfig = !!device?.attributes
                          ?.accessConfiguration
                          ? device?.attributes?.accessConfiguration?.split(",")
                          : false;

                        if (
                          !!deviceAccessConfig &&
                          deviceAccessConfig[1] === "1"
                        ) {
                          return (
                            <MenuItem value={device.id}>
                              {convertUnicode(device.name)}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                  {/* <FormControl margin="normal" fullWidth>
                  <ButtonGroup
                    color="primary"
                    orientation="vertical"
                    disabled={!true}
                  >
                    <Button onClick={() => setShowReport(true)}>
                      {t("reportShow")}
                    </Button>
                  </ButtonGroup>
                </FormControl> */}
                </>
              </Paper>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              {SelectedDeviceData && (
                <CardView
                  Ignit={SelectedDeviceData.contact[0] === "1"}
                  items={items}
                  deviceId={deviceId}
                />
              )}
            </Grid>
          </>
          {/* )} */}
        </Grid>
      </div>
    </div>
  );
};

const CardView = ({ Ignit, items, deviceId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SpeedingAlarm = useSelector((state) => state.setting.SpeedingAlarm);
  const [ShowSpeedingAlarmSaveButton, setShowSpeedingAlarmSaveButton] =
    useState(false);
  const [notificator, setNotificator] = useState([]);

  const handleNotificator = (method) => {
    let notificatorTemp = notificator;
    let notificatorIndex = notificatorTemp.indexOf(method);
    if (notificatorIndex > -1) {
      notificatorTemp.splice(notificatorIndex, 1);
    } else {
      notificatorTemp.push(method);
    }

    setNotificator([...notificatorTemp]);
    setShowSpeedingAlarmSaveButton(true);
  };

  const handlePermissionLink = (deviceId, notificationId) => {
    if (deviceId && notificationId) {
      fetch("/api/permissions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          deviceId,
          notificationId,
        }),
      }).then((res) => {
        if (res.ok) {
          //do nothing
        }
      });
    }
  };

  useEffectAsync(async () => {
    if (!!deviceId) {
      let hasSpeedNotification = await fetch(
        `/api/notifications/?deviceId=${deviceId}`
      );
      let result = await hasSpeedNotification.json();
      let notification = _.find(result, { type: "deviceOverspeed" });

      if (!!notification) {
        let notificators = !!notification.notificators
          ? notification?.notificators?.split(",")
          : [];
        setNotificator([...notificators]);
      } else {
        setNotificator([]);
      }
    }
  }, [deviceId]);

  const handleSpeedingAlarmRemove = async () => {
    let res = await fetch(`/api/devices/${deviceId}`);

    let dataOfVehicle = await res.json();
    let attributes = dataOfVehicle.attributes;
    let contact = dataOfVehicle.contact;
    let contactArr = contact.split(",");
    contactArr[1] = 0;
    contact = contactArr.join(",");

    delete attributes.speedLimit;
    var deviceRes = await fetch(`/api/devices/${deviceId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...dataOfVehicle,
        contact: contact,
        attributes: attributes,
      }),
    });

    let hasSpeedNotification = await fetch(
      `/api/notifications/?deviceId=${deviceId}`
    );
    let result = await hasSpeedNotification.json();
    let notifications = _.filter(result, { type: "deviceOverspeed" });
    dispatch(settingActions.UpdateSpeedingAlarm(0));

    if (!!notifications && notifications.length) {
      notifications.forEach((notification) => {
        fetch(
          `/api/notifications/` +
            notification?.id +
            `?_dc=${new Date().getTime()}`,
          { method: "DELETE" }
        );
      });
    }
  };

  const handleSpeedingAlarmSave = async () => {
    var res = await fetch(`/api/devices/${deviceId}`);

    const dataOfVehicle = await res.json();
    const attributes = dataOfVehicle.attributes;
    const contact = dataOfVehicle.contact.split(",");
    const speedLimit = speedToKnots(Number(SpeedingAlarm), "kmh");

    contact[1] = SpeedingAlarm;
    let newContact = "";
    contact.forEach((e, i) => {
      if (contact[i + 1]) {
        newContact = `${newContact + e},`;
      } else {
        newContact += e;
      }
    });

    var res = await fetch(`/api/devices/${deviceId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...dataOfVehicle,
        contact: newContact,
        attributes: {
          ...attributes,
          speedLimit: speedLimit,
        },
      }),
    });

    let hasSpeedNotification = await fetch(
      `/api/notifications/?deviceId=${deviceId}`
    );
    let result = await hasSpeedNotification.json();
    let notification = _.find(result, { type: "deviceOverspeed" });

    if (res.ok) {
      if (!!!notification) {
        fetch("/api/notifications", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: 0,
            type: "deviceOverspeed",
            always: true,
            notificators: notificator.join(","),
            calendarId: 0,
          }),
        })
          .then((res) => res.json())
          .then((result) => {
            // ResetForm();
            handlePermissionLink(deviceId, result?.id);
          });
      } else {
        fetch("/api/notifications/" + notification?.id, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: notification?.id,
            type: "deviceOverspeed",
            always: true,
            notificators: notificator.join(","),
            calendarId: 0,
          }),
        });
      }
    }

    if (res.ok) {
      const response = await fetch("/api/commands/send", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          deviceId: deviceId,
          type: "custom",
          attributes: {
            data: `123,${SpeedingAlarm.toString()}`,
          },
        }),
      });

      if (response.ok) {
        dispatch(notificationActions.updateSpeedingAlarmState(SpeedingAlarm));
        setShowSpeedingAlarmSaveButton(false);
      }
    }
  };

  return (
    <Grid item xs={12} md={4} lg={4}>
      {/* <PasswordDialogBox open={IsDialogVisible} handleConform={handleConform} handelCancel={handelCancel}/> */}
      <Card className={classes.root}>
        <CardHeader
          // avatar={handleStatus(selectedRow.status, selectedRow.id)}
          avatar={<AlarmAddOutlined color="primary" />}
          title={`Speed Alarm`}
        />
        Select to receive a notification when speed exceeds setting
        <FormGroup row>
          <FormControl variant="filled" margin="normal" fullWidth>
            <InputLabel>Select Speeding Alarm</InputLabel>

            <Select
              value={SpeedingAlarm}
              onChange={(event) => {
                // setSpeedingAlarm(event.target.value);
                dispatch(
                  settingActions.UpdateSpeedingAlarm(event.target.value)
                );
                setShowSpeedingAlarmSaveButton(true);
              }}
              MenuProps={{ classes: { paper: classes.dropdownStyle } }}
            >
              <MenuItem value="70"> 70 km/h</MenuItem>
              <MenuItem value="75"> 75 km/h</MenuItem>
              <MenuItem value="80"> 80 km/h</MenuItem>
              <MenuItem value="85"> 85 km/h</MenuItem>
              <MenuItem value="90"> 90 km/h</MenuItem>
              <MenuItem value="95"> 95 km/h</MenuItem>
              <MenuItem value="100"> 100 km/h</MenuItem>
              <MenuItem value="105"> 105 km/h</MenuItem>
              <MenuItem value="110"> 110 km/h</MenuItem>
              <MenuItem value="115"> 115 km/h</MenuItem>
              <MenuItem value="120"> 120 km/h</MenuItem>
              <MenuItem value="125"> 125 km/h</MenuItem>
              <MenuItem value="130"> 130 km/h</MenuItem>
              <MenuItem value="135"> 135 km/h</MenuItem>
              <MenuItem value="140"> 140 km/h</MenuItem>
              <MenuItem value="145"> 145 km/h</MenuItem>
              <MenuItem value="150"> 150 km/h</MenuItem>
            </Select>
          </FormControl>
        </FormGroup>
        {SpeedingAlarm != 0 ? (
          <>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificator.includes("mail")}
                    onChange={(e) => {
                      handleNotificator("mail");
                    }}
                  />
                }
                label="Mail"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificator.includes("sms")}
                    onChange={(e) => {
                      handleNotificator("sms");
                    }}
                  />
                }
                label="SMS"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificator.includes("web")}
                    onChange={(e) => {
                      handleNotificator("web");
                    }}
                  />
                }
                label="Web"
              />
            </FormGroup>
          </>
        ) : null}
        {ShowSpeedingAlarmSaveButton ? (
          <div className={classes.btn}>
            <ButtonGroup orientation="horizontal">
              <Button
                color="primary"
                onClick={(e) => handleSpeedingAlarmSave()}
              >
                Save
              </Button>
            </ButtonGroup>
            <ButtonGroup orientation="horizontal">
              <Button
                color="secondary"
                onClick={(e) => {
                  setShowSpeedingAlarmSaveButton(false);
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </div>
        ) : (
          ""
        )}
        {!ShowSpeedingAlarmSaveButton && (
          <>
            {SpeedingAlarm != 0 ? (
              <div className={classes.btn}>
                <ButtonGroup orientation="horizontal">
                  <Button
                    color="primary"
                    onClick={(e) => handleSpeedingAlarmRemove()}
                  >
                    Remove Speed Warning
                  </Button>
                </ButtonGroup>
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </Card>
    </Grid>
  );
};

export default SpeedAlarmPage;
