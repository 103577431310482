import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import useWindowSize from "common/util/useWindowSize";
import MainToolbar from "components/MainToolbar";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  IconButton,
  Button,
  TextField,
  ButtonGroup,
  Paper,
  InputAdornment,
  createTheme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { useTranslation } from "common/components/LocalizationProvider";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { useAttributePreference, usePreference } from "common/util/preferences";
import MaterialTable from "material-table";
import {
  formatDistance,
  formatSpeed,
  formatHours,
  formatDate,
  formatDateAustralian,
  formatVolume,
  formatTime,
  formatPosition,
} from "common/util/formatter";
import AddressValue from "common/components/AddressValue";

import LinearProgress from "@mui/material/LinearProgress";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import MaterialReactTable from "material-react-table";
import { useLocation } from "react-router-dom";
import { devicesActions } from "store";

import TripReportsMapContainer from "components/maps/TripReportMapContainer";
import { convertUnicode } from "reactHelper";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
  },
  tabel: {
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
  },
  customDatePicker: {
    "& label": {
      color: "#fff",
    },
    "& input": {
      color: "#fff",
    },
  },
  customDatePickerDialog: {
    "& .MuiDialogContent-root": {
      background: "#0f0e15 !important",
      color: "red",
    },
    "& .MuiDialog-paperScrollPaper": {
      background: "#1F1D2B",
      color: "#fff",
    },
    "& .MuiDayPicker-weekDayLabel": {
      color: "#fff",
    },
    "& .MuiPickersCalendarHeader-switchViewButton": {
      color: "#fff",
    },
    "& .MuiPickersArrowSwitcher-button": {
      color: "#fff",
    },
    "& .MuiInputLabel-formControl": {
      color: "#fff",
    },
    "& .MuiPickersToolbar-root": {
      background: "#3f51b5",
      color: "#fff",
    },
    "& .MuiPickersToolbar-root span": {
      color: "#fff",
    },
    "& .PrivatePickersToolbarText-root": {
      color: "#fff",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#f50057",
    },
    "& .MuiTabs-flexContainer": {
      background: "#3f51b5",
      color: "#fff",
    },
    "& .MuiTabs-flexContainer button": {
      color: "#fff",
    },
    "& .MuiDialogActions-root button": {
      color: "#3f51b5",
    },
    "& .MuiPickersDay-root": {
      color: "#fff",
      background: "none",
    },
    "& .Mui-selected.MuiPickersDay-root": {
      color: "#fff",
      background: "#3f51b5",
    },
    "& .MuiClock-amButton": {
      color: "#fff",
    },
    "& .MuiClock-pmButton": {
      color: "#fff",
    },
    "& .MuiClockPicker-root": {
      background: "#0f0e15",
    },
    "& .MuiClockNumber-root": {
      color: "#fff",
    },
    "& .MuiClockNumber-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiCalendarOrClockPicker-mobileKeyboardInputView .MuiInputBase-root": {
      color: "#fff",
    },
    "& .MuiCalendarOrClockPicker-mobileKeyboardInputView label": {
      color: "#fff",
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  datePickerInput: {
    color: "#fff",
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
}));

const materialTheme = createTheme({
  components: {
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: "red",
        },
      },
    },
    MuiPickersToolbar: {
      root: {
        background: "#fff",
        color: "#fff",
      },
    },
    MuiInputLabel: {
      root: {
        color: "#fff",
      },
    },
    MuiIconButton: {
      root: {
        color: "#2436ac",
      },
    },
    MuiInputBase: {
      input: {
        color: "#fff",
      },
      root: {
        color: "#fff",
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: "#000",
      },
    },
  },
});

const columnsArray = [
  ["startTime", "reportStartTime"],
  ["startOdometer", "reportStartOdometer"],
  ["startAddress", "reportStartAddress"],
  ["endTime", "reportEndTime"],
  ["endOdometer", "reportEndOdometer"],
  ["endAddress", "reportEndAddress"],
  ["distance", "sharedDistance"],
  ["averageSpeed", "reportAverageSpeed"],
  ["maxSpeed", "reportMaximumSpeed"],
  ["duration", "reportDuration"],
  ["spentFuel", "reportSpentFuel"],
  ["driverName", "sharedDriver"],
];
const columnsMap = new Map(columnsArray);

const ReportLayoutPage = ({ children, filter }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const volumeUnit = useAttributePreference("volumeUnit");
  const hours12 = usePreference("twelveHourFormat");

  const devices = useSelector((state) => Object.values(state.devices.items));
  const selectedDevice = useSelector((state) => state?.devices?.selectedItem);
  const selectedDeviceId =
    selectedDevice && selectedDevice.hasOwnProperty("id")
      ? selectedDevice?.id
      : null;
  const [columns, setColumns] = useState([]);
  const [selectedColumnsAttributes, setSelectedColumnsAttributes] = useState(
    []
  );

  const [deviceId, setDeviceId] = useState();
  const [period, setPeriod] = useState("today");
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [fromOpen, setFromOpen] = useState(false);
  const [toOpen, setToOpen] = useState(false);

  const distanceUnit = useAttributePreference("distanceUnit");
  const speedUnit = useAttributePreference("speedUnit");
  const [items, setItems] = useState([]);
  const [address, setAddress] = useState([]);
  const [batchStartObj, setbatchStartObj] = useState({});

  const [loader, setLoader] = useState(false);
  const [show, setShow] = React.useState(false);

  const [newitems, setnewItems] = useState([]);

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const progressRef = React.useRef(() => {});

  const location = useLocation();
  const [height, setheight] = useState(window.innerHeight);

  const windowSize = useWindowSize();

  const [selectedRow, setSelectedRow] = useState(null);
  const [Coordinates, setCoordinates] = useState();
  const [isReady, setIsReady] = useState(false);

  const [activeHeight, setActiveHeight] = useState(null);

  const handleSubmit = async (deviceId, from, to, mail, headers) => {
    setLoader(true);
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };

    const found_pos = devices.find((element) => element.id == deviceId);

    let deviceName = "";
    if (found_pos) {
      deviceName = found_pos.name;
    }
    const query = new URLSearchParams({ deviceId, from, to, mail });
    const response = await fetch(`/api/reports/trips?${query.toString()}`, {
      headers,
    });
    const batchStartArr = [];
    if (response.ok) {
      setLoader(false);
      const contentType = response.headers.get("content-type");
      if (contentType) {
        if (contentType === "application/json") {
          const res = await response.json();
          // code to get driver name
          let counter = 0;
          res.forEach(async (entity) => {
            counter++;
            if (entity.driverUniqueId) {
              const DriverResponse = await fetch(
                `/api/drivers?uniqueID=${entity.driverUniqueId}`
              );
              if (DriverResponse.ok) {
                const resp = await DriverResponse.json();
                resp.forEach((driver) => {
                  if (entity.driverUniqueId.includes(driver.uniqueId)) {
                    entity.driverName = driver.name;
                    if (counter === res.length) {
                      setItems(res);
                      const api_data = res;
                      fetchAddress(api_data);
                    }
                  }
                });
              }
            } else {
              setItems(res);
              const api_data = res;
              fetchAddress(api_data);
            }
          });
        } else {
          // window.location.assign(window.URL.createObjectURL(await response.blob()));
          const url = window.URL.createObjectURL(await response.blob());
          const a = document.createElement("a");
          a.href = url;
          a.download = `${deviceName}.xlsx`;
          a.click();
        }
      }
    }
  };

  const fetchAddress = async (api_data) => {
    setLoader(true);
    const batch = [];
    const copied_api_data = [...api_data];
    // console.log("fetch_address_api_data", copied_api_data);

    copied_api_data.map((item_data) => {
      batch.push({ query: `${item_data.startLat},${item_data.startLon}` });
      // console.log("batch");
      // console.log(batch);

      // if (batch.length == copied_api_data.length) {
      if (false) {
        const headers = new Headers();

        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Access-Control-Allow-Origin", "https://mmdgps.com/");

        fetch(
          "https://api.positionstack.com/v1/reverse?access_key=b15fd5e25a100bf07b32ada63d970393&limit=1",
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({
              batch,
            }),
            headers,
          }
        )
          .then((res) => res.json())
          .then((result) => {
            // console.log("result batch request");
            // console.log(result);
          });
      }
    });

    setLoader(true);
    window.setTimeout(() => {
      progressRef.current();
      if (copied_api_data) {
        setnewItems(copied_api_data);
        setLoader(false);
      }
    }, 2000);
  };

  const formatValue = (item, key) => {
    switch (key) {
      case "startTime":
      case "endTime":
        return formatPosition(item[key], "fixTime");
      case "startOdometer":
      case "endOdometer":
      case "distance":
        return formatDistance(item[key], distanceUnit, t);
      case "averageSpeed":
      case "maxSpeed":
        return formatSpeed(item[key], speedUnit, t);
      case "duration":
        return formatHours(item[key]);
      case "spentFuel":
        return formatVolume(item[key], volumeUnit, t);
      case "startAddress":
        return (
          <AddressValue
            latitude={item.startLat}
            longitude={item.startLon}
            originalAddress={item[key]}
          />
        );
      case "endAddress":
        return (
          <AddressValue
            latitude={item.endLat}
            longitude={item.endLon}
            originalAddress={item[key]}
          />
        );
      default:
        return item[key];
    }
  };

  const handleClick = (mail, json) => {
    let selectedFrom;
    let selectedTo;
    switch (period) {
      case "today":
        selectedFrom = moment().startOf("day");
        selectedTo = moment().endOf("day");
        break;
      case "yesterday":
        selectedFrom = moment().subtract(1, "day").startOf("day");
        selectedTo = moment().subtract(1, "day").endOf("day");
        break;
      case "thisWeek":
        selectedFrom = moment().startOf("week");
        selectedTo = moment().endOf("week");
        break;
      case "previousWeek":
        selectedFrom = moment().subtract(1, "week").startOf("week");
        selectedTo = moment().subtract(1, "week").endOf("week");
        break;
      case "thisMonth":
        selectedFrom = moment().startOf("month");
        selectedTo = moment().endOf("month");
        break;
      case "previousMonth":
        selectedFrom = moment().subtract(1, "month").startOf("month");
        selectedTo = moment().subtract(1, "month").endOf("month");
        break;
      default:
        // from.setHours(0, 0, 0, 0);
        selectedFrom = from;
        // to.setHours(23, 59, 59, 999);
        selectedTo = to;
        break;
    }

    const accept = json
      ? "application/json"
      : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    location.pathname == "/reports/trip"
      ? handleSubmit(
          deviceId,
          selectedFrom.toISOString(),
          selectedTo.toISOString(),
          mail,
          { Accept: accept }
        )
      : handleSubmit(
          selectedDeviceId,
          selectedFrom.toISOString(),
          selectedTo.toISOString(),
          mail,
          { Accept: accept }
        );
  };

  const MapView = async (SelectedRow) => {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    const res = await fetch(
      `/api/reports/route?deviceId=${SelectedRow.deviceId}&from=${`${
        SelectedRow.startTime.split(".000")[0]
      }Z`}&to=${`${SelectedRow.endTime.split(".000")[0]}Z`}`,
      {
        method: "GET",
        headers,
      }
    );

    //   if(timer)
    // {
    //   window.clearInterval(timer);
    // }
    if (res.ok) {
      setCoordinates(await res.json());
    }
  };

  useEffect(() => {
    if (location.pathname == "/reports/trip") {
      dispatch(devicesActions.select(null));
    } else {
      dispatch(devicesActions.select(selectedDeviceId));
      setDeviceId(selectedDeviceId);
      // console.log('deviceId-useeffect', deviceId);
      handleClick(false, true);
    }
  }, [selectedDeviceId]);

  useEffect(() => {
    const { height } = windowSize;
    const activeHeight = Math.floor(height / 2 - 186);
    if (activeHeight <= 115) {
      setActiveHeight(150);
    } else if (activeHeight >= 250) {
      setActiveHeight(activeHeight - 20);
    } else {
      return setActiveHeight(Math.floor(height / 2 - 186));
    }
  }, [windowSize]);

  useEffect(() => {
    // let defaultColumns = [
    //   {
    //     header: "Start Time",
    //     accessorKey: "startTime",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatDateAustralian(row.original.startTime),
    //   },
    //   {
    //     header: "Start Odometer",
    //     accessorKey: "startOdometer",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatDistance(row.original.startOdometer, distanceUnit, t),
    //   },
    //   {
    //     header: "Start Address",
    //     accessorKey: "startAddress",
    //     Cell: ({ renderedCellValue, row }) => row.original.startAddress,
    //   },
    //   {
    //     header: "End Time",
    //     accessorKey: "endTime",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatDateAustralian(row.original.endTime),
    //   },
    //   {
    //     header: "End Odometer",
    //     accessorKey: "endOdometer",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatDistance(row.original.endOdometer, distanceUnit, t),
    //   },
    //   {
    //     header: "End Address",
    //     accessorKey: "endAddress",
    //     Cell: ({ renderedCellValue, row }) => row.original.endAddress,
    //   },
    //   {
    //     header: "Distance",
    //     accessorKey: "distance",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatDistance(row.original.distance, distanceUnit, t),
    //   },
    //   {
    //     header: "Average Speed",
    //     accessorKey: "averageSpeed",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatSpeed(row.original.averageSpeed, speedUnit, t),
    //   },
    //   {
    //     header: "Maximum Speed",
    //     accessorKey: "maxSpeed",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatSpeed(row.original.maxSpeed, speedUnit, t),
    //   },
    //   {
    //     header: "Duration",
    //     accessorKey: "duration",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatHours(row.original.duration),
    //   },
    //   {
    //     header: "Driver Name",
    //     accessorKey: "driverName",
    //     Cell: ({ renderedCellValue, row }) => row.original.driverName,
    //   },
    // ];

    let defaultColumns = [
      "startTime",
      "startOdometer",
      "startAddress",
      "endTime",
      "endOdometer",
      "endAddress",
      "distance",
      "averageSpeed",
      "maxSpeed",
      "duration",
    ].map((item) => {
      let itemObj = {
        header: t(columnsMap.get(item)),
        accessorKey: item,
        Cell: ({ renderedCellValue, row }) => {
          return <>{formatValue(row.original, item)}</>;
        },
      };

      return itemObj;
    });

    let selectedColumns = selectedColumnsAttributes.map((item) => {
      let itemObj = {
        header: t(columnsMap.get(item)),
        accessorKey: item,
        Cell: ({ renderedCellValue, row }) => {
          return <>{formatValue(row.original, item)}</>;
        },
      };

      return itemObj;
    });

    let columnsTemp = selectedColumnsAttributes.length
      ? selectedColumns
      : defaultColumns;
    setColumns(columnsTemp);
  }, [selectedColumnsAttributes]);

  useEffect(() => {
    let device = _.find(devices, { id: deviceId });
    if (device && !!device?.attributes?.tripsReportColumns) {
      setSelectedColumnsAttributes(
        device?.attributes?.tripsReportColumns.split(",")
      );
    } else {
      setSelectedColumnsAttributes([]);
    }
  }, [deviceId]);

  return location.pathname == "/reports/trip" ? (
    <div className={classes.root}>
      <MainToolbar />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={2}>
            <Paper className={classes.form}>
              <FormControl variant="filled" margin="normal" fullWidth>
                <InputLabel>Vehicle</InputLabel>

                <Select
                  value={deviceId || ""}
                  onChange={(e) => {
                    setIsReady(false);
                    setDeviceId(e.target.value);
                    setItems([]);
                    setCoordinates(null);
                  }}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  {_.orderBy(
                    devices,
                    [(item) => item.name.toLowerCase()],
                    ["asc"]
                  ).map((device, index) => (
                    <MenuItem value={device.id} key={index}>
                      {convertUnicode(device.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="filled" margin="normal" fullWidth>
                <InputLabel>{t("reportPeriod")}</InputLabel>
                <Select
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  <MenuItem value="today">{t("reportToday")}</MenuItem>
                  <MenuItem value="yesterday">{t("reportYesterday")}</MenuItem>
                  <MenuItem value="thisWeek">{t("reportThisWeek")}</MenuItem>
                  <MenuItem value="previousWeek">
                    {t("reportPreviousWeek")}
                  </MenuItem>
                  <MenuItem value="thisMonth">{t("reportThisMonth")}</MenuItem>
                  <MenuItem value="previousMonth">
                    {t("reportPreviousMonth")}
                  </MenuItem>
                  <MenuItem value="custom">{t("reportCustom")}</MenuItem>
                </Select>
              </FormControl>
              {period === "custom" && (
                <ThemeProvider theme={materialTheme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDateTimePicker
                      className={classes.customDatePicker}
                      label={t("reportFrom")}
                      inputFormat="DD/MM/YYYY hh:mm a"
                      value={from}
                      DialogProps={{
                        className: classes.customDatePickerDialog,
                      }}
                      onChange={(date) => {
                        setFrom(date);
                      }}
                      renderInput={(params) => (
                        <TextField
                          margin="normal"
                          variant="filled"
                          className={classes.datePickerInput}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end">
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...params}
                        />
                      )}
                    />
                    <MobileDateTimePicker
                      className={classes.customDatePicker}
                      label={t("reportTo")}
                      inputFormat="DD/MM/YYYY hh:mm a"
                      value={to}
                      minDate={from}
                      onChange={(date) => setTo(date)}
                      DialogProps={{
                        className: classes.customDatePickerDialog,
                      }}
                      renderInput={(params) => (
                        <TextField
                          margin="normal"
                          variant="filled"
                          className={classes.datePickerInput}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end">
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              )}
              {children}
              <FormControl margin="normal" fullWidth>
                <ButtonGroup
                  color="primary"
                  orientation="vertical"
                  disabled={!deviceId}
                >
                  <Button onClick={() => handleClick(false, true)}>
                    {t("reportShow")}
                  </Button>
                  <Button onClick={() => handleClick(false, false)}>
                    {t("reportExport")}
                  </Button>
                  <Button onClick={() => handleClick(true, false)}>
                    {t("reportEmail")}
                  </Button>
                </ButtonGroup>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} md={9} lg={10}>
            <div style={{ height: height - 100 }}>
              <div style={{ height: "50%", marginTop: 2, marginBottom: 2 }}>
                {/* <button onClick={StopMap}>Stop</button> */}
                <div>
                  {loader ? (
                    <LinearProgress
                      variant="buffer"
                      value={progress}
                      valueBuffer={buffer}
                    />
                  ) : (
                    ""
                  )}
                  {items ? (
                    <MaterialReactTable
                      columns={columns}
                      data={items}
                      enableColumnActions={false}
                      enableColumnFilters={false}
                      enablePagination={items.length > 50}
                      enableSorting={true}
                      enableBottomToolbar={true}
                      enableTopToolbar={true}
                      enableStickyHeader
                      muiTablePaginationProps={{
                        rowsPerPageOptions: [50],
                      }}
                      renderTopToolbarCustomActions={() => (
                        <Typography
                          variant="h6"
                          style={{
                            color: "#00ac69",
                            paddingLeft: "20px",
                            paddingRight: "5px",
                          }}
                        >
                          Trips Report
                        </Typography>
                      )}
                      muiTopToolbarProps={{
                        sx: {
                          backgroundColor: "#1F1D2B",
                          color: "#FFFFFF",
                        },
                      }}
                      muiBottomToolbarProps={{
                        sx: {
                          backgroundColor: "#1F1D2B",
                          color: "#FFFFFF",
                        },
                      }}
                      muiTableHeadProps={{
                        sx: {
                          backgroundColor: "#1F1D2B",
                          color: "#FFFFFF",
                        },
                      }}
                      muiTableContainerProps={{
                        sx: {
                          color: "#57c7d4",
                          overflowY: "scroll",
                          height: height / 2 - 178,
                        },
                      }}
                      muiTableHeadRowProps={({ table, headerGroup }) => ({
                        sx: {
                          backgroundColor: "#1F1D2B",
                          color: "#FFFFFF",
                        },
                      })}
                      muiTableBodyRowProps={({ row }) => ({
                        onClick: (event) => {
                          if (!isReady) {
                            setIsReady(true);
                          }
                          setSelectedRow(row.original.id);
                          setCoordinates(null);
                          MapView(row.original);
                        },
                        sx: {
                          cursor: "pointer",
                          backgroundColor:
                            selectedRow === row.original.id ||
                            selectedRow === row.id
                              ? "#0d0d0d"
                              : "",
                        },
                      })}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div
                style={{
                  height: "50%",
                  marginTop: 2,
                  marginBottom: 2,
                  position: "relative",
                }}
              >
                <TripReportsMapContainer
                  CoordinatesData={Coordinates}
                  isReady={isReady}
                  deviceId={deviceId}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  ) : (
    <Grid container spacing={2} className={classes.tabel}>
      <Grid item xs={12} md={12} lg={12}>
        <Paper style={{ overflowY: "scroll" }}>
          {loader ? (
            <LinearProgress
              variant="buffer"
              value={progress}
              valueBuffer={buffer}
            />
          ) : (
            ""
          )}

          {items && activeHeight !== null ? (
            <MaterialReactTable
              columns={columns}
              data={items}
              enableColumnActions={false}
              enableColumnFilters={false}
              enablePagination={items.length > 50}
              enableSorting={true}
              enableBottomToolbar={true}
              enableTopToolbar={true}
              enableStickyHeader
              muiTablePaginationProps={{
                rowsPerPageOptions: [50],
              }}
              renderTopToolbarCustomActions={() => (
                <Typography
                  variant="h6"
                  style={{
                    color: "#00ac69",
                    paddingLeft: "20px",
                    paddingRight: "5px",
                  }}
                >
                  Trips Report
                </Typography>
              )}
              muiTopToolbarProps={{
                sx: {
                  backgroundColor: "#1F1D2B",
                  color: "#FFFFFF",
                },
              }}
              muiBottomToolbarProps={{
                sx: {
                  backgroundColor: "#1F1D2B",
                  color: "#FFFFFF",
                },
              }}
              muiTableHeadProps={{
                sx: {
                  backgroundColor: "#1F1D2B",
                  color: "#FFFFFF",
                },
              }}
              muiTableContainerProps={{
                sx: {
                  color: "#57c7d4",
                  overflowY: "scroll",
                  height: height / 2 - 178,
                },
              }}
              muiTableHeadRowProps={({ table, headerGroup }) => ({
                sx: {
                  backgroundColor: "#1F1D2B",
                  color: "#FFFFFF",
                },
              })}
              muiTableBodyRowProps={({ row }) => ({
                sx: {
                  cursor: "pointer",
                  backgroundColor:
                    selectedRow === row.original.id || selectedRow === row.id
                      ? "#0d0d0d"
                      : "",
                },
              })}
            />
          ) : (
            ""
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ReportLayoutPage;
