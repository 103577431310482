import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ReportLayoutPage from "reports/layouts/ReportLayoutPage";
import ReportFilter from "reports/components/ReportFilter";
import Graph from "reports/components/Graph";
import { makeStyles } from "@mui/styles";
import { useAttributePreference } from "common/util/preferences";
import { formatDate } from "common/util/formatter";
import { speedFromKnots } from "common/util/converter";
import { useTranslation } from "common/components/LocalizationProvider";

const useStyles = makeStyles((theme) => ({
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
}));

const Filter = ({ children, setItems }) => {
  const t = useTranslation();

  const speedUnit = useAttributePreference("speedUnit");

  const handleSubmit = async (deviceId, from, to, mail, headers) => {
    const query = new URLSearchParams({ deviceId, from, to, mail });
    const response = await fetch(`/api/reports/route?${query.toString()}`, {
      headers,
    });
    if (response.ok) {
      const positions = await response.json();
      const formattedPositions = positions.map((position) => ({
        speed: Number(speedFromKnots(position.speed, speedUnit)),
        altitude: position.altitude,
        accuracy: position.accuracy,
        fixTime: formatDate(position.fixTime),
      }));
      setItems(formattedPositions);
    }
  };
  return (
    <>
      <ReportFilter handleSubmit={handleSubmit} showOnly />
      {children}
    </>
  );
};

const ChartType = ({ type, setType }) => {
  const classes = useStyles();
  const t = useTranslation();
  return (
    <FormControl variant="filled" margin="normal" fullWidth>
      <InputLabel>{t("reportChartType")}</InputLabel>
      <Select
        value={type}
        onChange={(e) => setType(e.target.value)}
        MenuProps={{ classes: { paper: classes.dropdownStyle } }}
      >
        <MenuItem value="speed">{t("positionSpeed")}</MenuItem>
        <MenuItem value="accuracy">{t("positionAccuracy")}</MenuItem>
        <MenuItem value="altitude">{t("positionAltitude")}</MenuItem>
      </Select>
    </FormControl>
  );
};

const ChartReportPage = () => {
  const [items, setItems] = useState([]);
  const [type, setType] = useState("speed");
  const t = useTranslation();

  return (
    <ReportLayoutPage
      filter={
        <Filter setItems={setItems}>
          <ChartType type={type} setType={setType} />
        </Filter>
      }
    >
      <Graph items={items} type={type} />
    </ReportLayoutPage>
  );
};

export default ChartReportPage;
