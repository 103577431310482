import React, { useCallback, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { devicesActions, sessionActions, settingActions } from "store/";
import { map } from "map/core/MapView";
import MapView from "../map/core/MapView";
import MapSelectedDevice from "../map/main/MapSelectedDevice";
import MapAccuracy from "../map/main/MapAccuracy";
import MapGeofence from "../map/MapGeofence";
import MapCurrentLocation from "../map/MapCurrentLocation";
import PoiMap from "../map/main/PoiMap";
import MapPadding from "../map/MapPadding";
import MapDefaultCamera from "../map/main/MapDefaultCamera";
import MapLiveRoutes from "../map/main/MapLiveRoutes";
import MapPositions from "../map/MapPositions";
import MapOverlay from "../map/overlay/MapOverlay";
import MapGeocoder from "../map/geocoder/MapGeocoder";
import MapScale from "../map/MapScale";
import MapNotification from "../map/notification/MapNotification";
import useFeatures from "../common/util/useFeatures";

const MainMap = ({ filteredPositions, selectedPosition, onEventsClick }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const eventsAvailable = useSelector((state) => !!state.events.items.length);
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const isTempVisible = useSelector((state) => state.setting.isTempVisible);
  const user = useSelector((state) => Object.values(state.session.user));
  const [isMapResetEnabled, setIsMapResetEnabled] = useState(true);
  const [isMovingDevice, setIsMovingDevice] = useState(false);
  const [isAutoTrackingEnabled, setIsAutoTrackingEnabled] = useState(true);

  const features = useFeatures();

  const onMarkerClick = useCallback(
    (_, deviceId) => {
      if (deviceId) {
        dispatch(devicesActions.selectId(deviceId));
      }
    },
    [dispatch]
  );

  //   const mapCenter = useSelector(state => {
  //     if (state.devices.selectedId) {
  //       const position = state.session.positions[state.devices.selectedId] || null;
  //       if (position) {
  //         console.log("In center");
  //         console.log(position);
  //         return [position.longitude, position.latitude];
  //       }
  //     }
  //     return null;
  //   });

  // const longitude = user[10];
  // const latitude = user[9];
  // const zoom = user[11];

  // useEffect(() => {
  //   map.jumpTo({
  //     center: [
  //       longitude,
  //       latitude,
  //     ],
  //     zoom: zoom,
  //   });

  // }, []);
  //   const positions = useSelector(state => Object.values(state.session.positions));

  //   const recenterMap = () => {
  //     if(!selectedDeviceId){
  //       map.setCenter([longitude, latitude]);
  //       //map.setZoom(zoom);
  //       map.setZoom(map.getZoom());
  //       map.setCenter([longitude, latitude]);
  //       map.flyTo({center:[longitude, latitude]})
  //     } else {
  //       console.log("Making Recenter")
  //       console.log(mapCenter)
  //       console.log("SelectedDeviceMap")
  //       if(mapCenter)
  //       {
  //         map.setCenter(mapCenter)
  //         map.setZoom('17') ;
  //       }
  //       map.easeTo({ center: mapCenter});
  //     }

  //   }

  // const handleMapInteraction = () => {
  //     if(isMapResetEnabled){
  //       setIsMapResetEnabled(false);
  //     }
  // }

  // useEffect(() => {
  //   if(isMovingDevice){
  //     setIsMapResetEnabled(!isAutoTrackingEnabled);
  //   }

  // }, [isAutoTrackingEnabled, isMovingDevice])

  // useEffect(() => {
  //   map.on('drag', handleMapInteraction);
  //   map.on('wheel', handleMapInteraction);
  //   map.on('touchmove', handleMapInteraction);
  //   if(selectedDeviceId){
  //     setTimeout(() => {
  //       map.setZoom(17) ;
  //       dispatch(sessionActions.toggleTabs(true));
  //       dispatch(sessionActions.SelectTab(0));
  //       dispatch(settingActions.SetIsMapResetEnabled(true));
  //     }, 500);
  //   }
  // }, []);

  // useEffect(() => {
  //   if(selectedDeviceId){
  //     let found_pos = positions.find(element => element.deviceId == selectedDeviceId);
  //     if(found_pos){

  //       if(found_pos.attributes.motion == true){
  //         setIsMovingDevice(true);
  //       } else {
  //         setIsMovingDevice(false);
  //       }
  //     }
  //     setIsMapResetEnabled(true);
  //     recenterMap();
  //   }
  // }, [selectedDeviceId]);

  // useEffect(() => {

  //   if((!selectedDeviceId && isMapResetEnabled ) || (selectedDeviceId && isMovingDevice && isAutoTrackingEnabled)){
  //     recenterMap();
  //   }
  // }, []);

  return (
    <>
      {user && (
        <>
          <MapView>
            <MapOverlay />
            <MapGeofence />
            <MapAccuracy positions={filteredPositions} />
            <MapLiveRoutes />
            <MapPositions
              positions={filteredPositions}
              onClick={onMarkerClick}
              selectedPosition={selectedPosition}
              showStatus
            />
            <MapDefaultCamera />
            <MapSelectedDevice />
            <PoiMap />
          </MapView>
          {/* <MapScale /> */}
          <MapCurrentLocation />
          <MapGeocoder />
          {!features.disableEvents && (
            <MapNotification
              enabled={eventsAvailable}
              onClick={onEventsClick}
            />
          )}
          {desktop && (
            <MapPadding
              left={parseInt(theme.dimensions.drawerWidthDesktop, 10)}
            />
          )}
        </>
      )}
    </>
  );
};

export default MainMap;
