import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  List,
  Container,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  Button,
  TextField,
  ButtonGroup,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MainToolbar from "components/MainToolbar";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "common/components/LocalizationProvider";
import { formatPosition, formatCapAttributes } from "common/formatter/";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import PositionValue from "common/components/PositionValue";
import LinearProgress from "@mui/material/LinearProgress";
import { devicesActions } from "store/";
import { convertUnicode } from "reactHelper";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
}));

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const DetailPage = ({ children, filter }) => {
  const [item, setItem] = useState();
  const [positionId, setPositionId] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation();

  const devices = useSelector((state) => Object.values(state.devices.items));
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);

  const [deviceId, setDeviceId] = useState(selectedDeviceId);
  const selectedDevicePositionId = useSelector((state) => {
    if (deviceId) {
      const position = state.session.positions[deviceId] || null;
      if (position) {
        return position.id;
      }
    }
  });

  const [items, setItems] = useState([]);

  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const location = useLocation();
  const [height, setheight] = useState(window.innerHeight);

  const windowSize = useWindowSize();

  const [Coordinates, setCoordinates] = useState();
  const [isReady, setIsReady] = useState(false);

  const [activeHeight, setActiveHeight] = useState(null);

  const handleClick = async (mail, json) => {
    if (selectedDevicePositionId) {
      setLoader(true);
      const response = await fetch(
        `/api/positions?id=${selectedDevicePositionId}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (response.ok) {
        const items = await response.json();
        setItem(items[0]);
        setLoader(false);
      }
    } else {
      setItem({});
      setLoader(false);
    }
  };

  useEffect(() => {
    const { height } = windowSize;
    const activeHeight = Math.floor(height / 2 - 186);
    if (activeHeight <= 115) {
      setActiveHeight(150);
    } else if (activeHeight >= 250) {
      setActiveHeight(activeHeight - 20);
    } else {
      return setActiveHeight(Math.floor(height / 2 - 186));
    }
  }, [windowSize]);

  const attributesList = () => {
    const combinedList = { ...item, ...item.attributes };
    return Object.entries(combinedList).filter(
      ([_, value]) => typeof value !== "object"
    );
  };

  return (
    <div className={classes.root}>
      <MainToolbar />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={2}>
            <Paper className={classes.form}>
              <FormControl variant="filled" margin="normal" fullWidth>
                <InputLabel>Vehicle</InputLabel>

                <Select
                  value={deviceId}
                  onChange={(e) => {
                    setIsReady(false);
                    setDeviceId(e.target.value);
                    setItems([]);
                    setCoordinates(null);
                  }}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  {_.orderBy(
                    devices,
                    [(item) => item.name.toLowerCase()],
                    ["asc"]
                  ).map((device) => (
                    <MenuItem value={device.id}>
                      {convertUnicode(device.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {children}
              <FormControl margin="normal" fullWidth>
                <ButtonGroup
                  color="primary"
                  orientation="vertical"
                  disabled={!deviceId}
                >
                  <Button onClick={() => handleClick(false, true)}>
                    {t("reportShow")}
                  </Button>
                </ButtonGroup>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} md={9} lg={10}>
            <div style={{ height: height - 100 }}>
              <div style={{ height: "50%", marginTop: 2, marginBottom: 2 }}>
                {/* <button onClick={StopMap}>Stop</button> */}
                <div>
                  {loader ? (
                    <LinearProgress
                      variant="buffer"
                      value={progress}
                      valueBuffer={buffer}
                    />
                  ) : (
                    ""
                  )}
                  {item && (
                    <List
                      style={{
                        overflowY: "scroll",
                        maxHeight: window.innerHeight - 200,
                      }}
                    >
                      {item &&
                        Object.getOwnPropertyNames(item)
                          .filter((it) => it !== "attributes")
                          .map((property) => (
                            <ListItem>
                              <ListItemText
                                style={{ color: "#57c7d4" }}
                                primary={formatCapAttributes(property)}
                              />
                              <ListItemSecondaryAction>
                                <Typography
                                  variant="body2"
                                  style={{ color: "#57c7d4" }}
                                >
                                  <PositionValue
                                    position={item}
                                    property={property}
                                  />
                                </Typography>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                      {item &&
                        Object.getOwnPropertyNames(item.attributes).map(
                          (attribute) => (
                            <ListItem>
                              <ListItemText
                                style={{ color: "#57c7d4" }}
                                primary={formatCapAttributes(attribute)}
                              />
                              <ListItemSecondaryAction>
                                <Typography
                                  variant="body2"
                                  style={{ color: "#57c7d4" }}
                                >
                                  <PositionValue
                                    position={item}
                                    attribute={attribute}
                                  />
                                </Typography>
                              </ListItemSecondaryAction>
                            </ListItem>
                          )
                        )}
                    </List>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DetailPage;
