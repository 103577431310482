import React, { useState } from "react";
import MainToolbar from "components/MainToolbar";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  Button,
  TextField,
  ButtonGroup,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "common/components/LocalizationProvider";
import { useEffectAsync } from "reactHelper";
import EditCollectionView from "EditCollectionView";
import { makeStyles } from "@mui/styles";
import LinearProgress from "@mui/material/LinearProgress";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";

import { AddCircle, Edit, Save } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& .MuiIcon-colorPrimary": {
      color: "#4ac7c9",
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
}));
// const DriversView = ({ updateTimestamp, onMenuClick }) => {
//   const classes = useStyles();

//   const [items, setItems] = useState([]);

//   useEffectAsync(async () => {
//     const response = await fetch('/api/drivers');
//     if (response.ok) {
//       setItems(await response.json());
//     }
//   }, [updateTimestamp]);

//   return (
//     <TableContainer>
//     <Table>
//       <TableHead>
//         <TableRow>
//           <TableCell className={classes.columnAction} />
//           <TableCell>{t('sharedName')}</TableCell>
//           <TableCell>{t('deviceIdentifier')}</TableCell>
//         </TableRow>
//       </TableHead>
//       <TableBody>
//         {items.map((item) => (
//           <TableRow key={item.id}>
//             <TableCell className={classes.columnAction} padding="none">
//               <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.id)}>
//                 <MoreVertIcon />
//               </IconButton>
//             </TableCell>
//             <TableCell>{item.name}</TableCell>
//             <TableCell>{item.uniqueId}</TableCell>
//           </TableRow>
//         ))}
//       </TableBody>
//     </Table>
//     </TableContainer>
//   );
// }

const DriversViewNew = ({ updateTimestamp, onMenuClick }) => {
  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const t = useTranslation();

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);

  const [IsEdit, setIsEdit] = useState(false);
  const [selectedDriverId, setselectedDriverId] = useState(null);

  const [ShowAddDriverContainer, setShowAddDriverContainer] = useState(false);
  const [AddNewObject, setAddNewObject] = useState({
    id: 0,
    name: null,
    uniqueId: null,
    attributes: {},
  });
  const [open, setOpen] = React.useState({
    Isshow: false,
    SuccesCBWithData: null,
    name: null,
  });

  const ResetForm = () => {
    setAddNewObject({
      id: 0,
      name: null,
      uniqueId: null,
      attributes: {},
    });
    setShowAddDriverContainer(false);
  };

  const handleClickOpen = (cb, data) => {
    setOpen({ Isshow: true, SuccesCBWithData: cb, name: data });
  };

  const handelSuccessClose = () => {
    // console.log("handelSuccessClose");
    open.SuccesCBWithData();
    handleClose();
  };

  const handleClose = () => {
    setOpen({ Isshow: false });
  };

  const getAllDriver = async () => {
    const response = await fetch("/api/drivers");
    if (response.ok) {
      setItems(await response.json());
    }
  };

  useEffectAsync(async () => {
    await getAllDriver();
  }, [updateTimestamp]);

  const addNewDriver = () => {
    fetch("/api/drivers", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(AddNewObject),
    })
      .then((res) => res.json())
      .then((result) => {
        ResetForm();
        getAllDriver();
      });
  };

  const EditDriver = () => {
    fetch(`/api/drivers/${selectedDriverId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(AddNewObject),
    })
      .then((res) => res.json())
      .then((result) => {
        setIsEdit(false);
        ResetForm();
        getAllDriver();
      });
  };

  const deleteDriver = (rowData) => {
    setTimeout(() => {
      const resp = fetch("/api/commands/send", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          deviceId: selectedDeviceId,
          type: "custom",
          attributes: {
            data: `145,#00000${
              rowData.uniqueId.length > 7 ? rowData.uniqueId : rowData.uniqueId
              // : "0" + rowData.uniqueId
            }`,
          },
        }),
      });
    }, 1000);

    fetch(`/api/drivers/${rowData.id}`, {
      method: "DELETE",
    }).then((res) => {
      ResetForm();
      getAllDriver();
      // res.json()
    });
  };
  return (
    <div className={classes.root}>
      <AlertDialog
        OnSuccess={handelSuccessClose}
        closeCB={handleClose}
        open={open.Isshow}
        name={open.name}
      />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={2}>
            <Paper className={classes.form}>
              <FormControl margin="normal" fullWidth>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddCircle />}
                  onClick={() => {
                    setShowAddDriverContainer(!ShowAddDriverContainer);
                  }}
                >
                  {IsEdit ? "Edit Driver" : "Add Driver"}
                </Button>
                {ShowAddDriverContainer && (
                  <>
                    <TextField
                      margin="normal"
                      type="text"
                      onChange={(event) =>
                        setAddNewObject({
                          ...AddNewObject,
                          name: event.target.value,
                        })
                      }
                      label="Driver"
                      value={AddNewObject.name}
                      variant="filled"
                    />
                    <TextField
                      margin="normal"
                      type="text"
                      value={AddNewObject.uniqueId}
                      onChange={(event) =>
                        setAddNewObject({
                          ...AddNewObject,
                          uniqueId: event.target.value,
                        })
                      }
                      label="Tag number"
                      variant="filled"
                    />
                    {AddNewObject.name && AddNewObject.uniqueId && IsEdit ? (
                      <Button
                        onClick={() => EditDriver()}
                        color="secondary"
                        variant="contained"
                        startIcon={<Edit />}
                      >
                        Save Changes
                      </Button>
                    ) : (
                      <Button
                        onClick={() => addNewDriver()}
                        color="secondary"
                        variant="contained"
                        startIcon={<Save />}
                      >
                        Save
                      </Button>
                    )}
                  </>
                )}
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            {loader ? (
              <LinearProgress
                variant="buffer"
                value={progress}
                valueBuffer={buffer}
              />
            ) : (
              ""
            )}
            {items ? (
              <MaterialTable
                columns={[
                  {
                    title: t("sharedDriver"),
                    field: "name",
                    render: (rowData) => rowData.name,
                  },
                  {
                    title: "Tag number", // t('deviceIdentifier'),
                    field: "uniqueId",
                    render: (rowData) => rowData.uniqueId,
                  },
                ]}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Edit",
                    iconProps: {
                      color: "primary",
                    },
                    onClick: (event, rowData) => {
                      // console.log(rowData);
                      setselectedDriverId(rowData.id);
                      setAddNewObject({
                        ...AddNewObject,
                        name: rowData.name,
                        uniqueId: rowData.uniqueId,
                        id: rowData.id,
                      });
                      setIsEdit(true);
                      setShowAddDriverContainer(true);
                    },
                  },
                  {
                    icon: "delete",
                    tooltip: "Delete",
                    iconProps: {
                      color: "primary",
                    },
                    onClick: (event, rowData) => {
                      ResetForm();
                      handleClickOpen(
                        () => deleteDriver(rowData),
                        rowData.name
                      );
                      // ;
                      // console.log("clicked");
                    },
                  },
                ]}
                // editable={{

                //   onRowUpdate: (newData, oldData) =>
                //     new Promise((resolve) => {
                //      // handleRowUpdate(newData, oldData, resolve);
                // }),
                // onRowDelete: (oldData) =>
                //   new Promise((resolve) => {
                //     //handleRowDelete(oldData, resolve)
                //   }),
                // }}
                data={_.orderBy(
                  items,
                  [(item) => item.name.toLowerCase()],
                  ["asc"]
                )}
                title=""
                // icons={tableIcons}
                options={{
                  cellStyle: {
                    padding: "6px 16px",
                    borderBottom: "1px solid #3f403f",
                  },
                  actionsCellStyle: {
                    borderBottom: "1px solid #3f403f",
                  },
                  headerStyle: {
                    backgroundColor: "#1F1D2B",
                    color: "#FFFFFF",
                    borderBottom: "2px solid #cbcad0",
                  },
                  actionsColumnIndex: -1,
                  // exportButton: true,
                  sorting: true,
                  paging: false,
                  /* maxBodyHeight: calcHeight+'px', */
                  defaultSort: "asc",
                }}
                style={{ color: "#57c7d4" }}

                // onRowClick={(event) => onMenuClick(event.currentTarget, rowData => rowData.uniqueId)}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const DriversPage = () => (
  <>
    <MainToolbar />
    <EditCollectionView
      content={DriversViewNew}
      editPath="/settings/driver"
      endpoint="drivers"
    />
  </>
);

const AlertDialog = ({ open, OnSuccess, closeCB, name }) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={closeCB}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.root}
      >
        <DialogTitle id="alert-dialog-title">Confirm ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="secondary">
            Are you sure you want to delete Driver {name}, this cannot be
            reversed?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCB} color="primary">
            No
          </Button>
          <Button onClick={OnSuccess} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DriversPage;
