import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import MainToolbar from "components/MainToolbar";
import {
  Paper,
  FormControl,
  FormControlLabel,
  Switch,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  ButtonGroup,
  Button,
  TextField,
  Card,
  CardHeader,
  FormGroup,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "common/components/LocalizationProvider";
import { convertUnicode, DialogBox, useEffectAsync } from "reactHelper";

import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import { AddCircle, Edit, Save } from "@mui/icons-material";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { devicesActions, notificationActions, settingActions } from "store";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
    "& .MuiSwitch-switchBase": {
      color: "#f30707",
    },
    "& .MuiSwitch-colorPrimary.Mui-checked": {
      color: "#3f51b5",
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  columnAction: {
    // width: theme.spacing(1),
    // padding: theme.spacing(0, 1),
  },
}));

const SleepModePage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);

  const items = useSelector((state) => Object.values(state.devices.items));
  const dispatch = useDispatch();
  const [deviceId, setDeviceId] = useState(selectedDeviceId);

  const [SelectedDeviceData, setSelectedDeviceData] = useState();

  useEffectAsync(async () => {
    const response = await fetch("/api/devices");
    if (response.ok) {
      const devices = await response.json();
      dispatch(devicesActions.refresh(devices));
    }

    const res = await fetch(`/api/devices/${deviceId}`);
    if (res.ok) {
      const data = await res.json();
      const attributes = data?.attributes;
      const accessConfiguration = attributes?.accessConfiguration?.split(",");

      dispatch(settingActions.UpdateAttributes({ ...attributes }));

      if (accessConfiguration && accessConfiguration.length > 0) {
        dispatch(
          settingActions.SetisSetIgnitionVisible(accessConfiguration[0] === "1")
        );
        dispatch(
          settingActions.SetIsSpeedingAlarmVisible(
            accessConfiguration[1] === "1"
          )
        );
        dispatch(
          settingActions.SetIsSleepModeVisible(accessConfiguration[2] === "1")
        );
        dispatch(
          settingActions.SetisTempVisible(accessConfiguration[3] === "1")
        );
        dispatch(
          settingActions.SetisTempMapVisible(accessConfiguration[9] === "1")
        );
        dispatch(
          settingActions.SetisGroupVisible(accessConfiguration[8] === "1")
        );
        dispatch(
          settingActions.UpdateDriverVisible(accessConfiguration[4] === "1")
        );
        dispatch(
          settingActions.UpdateDriverBehaviourVisible(
            accessConfiguration[5] === "1"
          )
        );
        dispatch(
          settingActions.UpdateMaintenanceVisible(
            accessConfiguration[6] === "1"
          )
        );
        dispatch(
          settingActions.UpdateNotificationsVisible(
            accessConfiguration[7] === "1"
          )
        );
        dispatch(
          settingActions.UpdateGroupsVisible(accessConfiguration[8] === "1")
        );
        dispatch(
          settingActions.SetHoursEnabled(accessConfiguration[10] === "1")
        );
        dispatch(
          settingActions.SetisMileageVisible(accessConfiguration[11] === "1")
        );
        dispatch(
          settingActions.SetisPreferenceVisible(accessConfiguration[12] === "1")
        );
        dispatch(
          settingActions.SetisCalendarVisible(accessConfiguration[13] === "1")
        );
        dispatch(
          settingActions.SetisAssignedGroupVisible(
            accessConfiguration[14] === "1"
          )
        );
      } else {
        dispatch(settingActions.SetisSetIgnitionVisible(false));
        dispatch(settingActions.SetIsSpeedingAlarmVisible(false));
        dispatch(settingActions.SetIsSleepModeVisible(false));
        dispatch(settingActions.SetisTempVisible(true));
        dispatch(settingActions.SetisTempMapVisible(false));
        dispatch(settingActions.SetisGroupVisible(false));
        dispatch(settingActions.UpdateDriverVisible(false));
        dispatch(settingActions.UpdateDriverBehaviourVisible(false));
        dispatch(settingActions.UpdateMaintenanceVisible(false));
        dispatch(settingActions.UpdateNotificationsVisible(false));
        dispatch(settingActions.UpdateGroupsVisible(false));
        dispatch(settingActions.SetHoursEnabled(false));
        dispatch(settingActions.SetisMileageVisible(false));
        dispatch(settingActions.SetisPreferenceVisible(false));
        dispatch(settingActions.SetisCalendarVisible(false));
        dispatch(settingActions.SetisAssignedGroupVisible(false));
      }

      const contact = data.contact?.split(",");
      if (contact && contact.length > 0) {
        dispatch(settingActions.UpdateIgnition(contact[0] === "1"));
        dispatch(settingActions.UpdateSleepMode(contact[2] !== "0"));
        dispatch(settingActions.UpdateSpeedingAlarm(contact[1]));
        dispatch(settingActions.UpdateTempAlarm(contact[3]));
      }
      dispatch(settingActions.UpdateGroupId(data?.groupId));
    }
  }, [deviceId, selectedDeviceId]);

  return (
    <div className={classes.root}>
      <MainToolbar />
      <div className={classes.content}>
        <Grid container spacing={2}>
          {/* {true && ( */}
          <>
            <Grid item xs={12} md={3} lg={2}>
              <Paper className={classes.form}>
                <>
                  <FormControl variant="filled" margin="normal" fullWidth>
                    <InputLabel>{t("reportDevice")}</InputLabel>

                    <Select
                      value={deviceId}
                      onChange={(e) => {
                        setDeviceId(e.target.value);
                        setSelectedDeviceData(
                          items.filter((d) => d.id === e.target.value)[0]
                        );
                      }}
                      MenuProps={{
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >
                      {_.orderBy(
                        items,
                        [(item) => item.name.toLowerCase()],
                        ["asc"]
                      ).map((device) => {
                        let deviceAccessConfig = !!device?.attributes
                          ?.accessConfiguration
                          ? device?.attributes?.accessConfiguration?.split(",")
                          : false;
                        if (
                          deviceAccessConfig &&
                          deviceAccessConfig[2] === "1"
                        ) {
                          return (
                            <MenuItem value={device.id}>
                              {convertUnicode(device.name)}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </>
              </Paper>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              {SelectedDeviceData && (
                <CardView
                  SleepStatus={SelectedDeviceData.contact[2] === "0"}
                  items={items}
                  deviceId={deviceId}
                />
              )}
            </Grid>
          </>
          {/* )} */}
        </Grid>
      </div>
    </div>
  );
};

const CardView = ({ SleepStatus, items, deviceId }) => {
  const selectedDeviceId = deviceId;
  const Position = useSelector((state) => {
    if (state.devices.selectedId) {
      const position = state.session.positions[deviceId] || null;
      return position;
    }
    return null;
  });

  const [Dialog, setDialog] = useState({
    isOpen: false,
    handleSuccess: null,
    handleClose: null,
    decription: null,
    title: null,
    type: "no",
    closeBtnText: "Close",
    confmBtnText: "Agree",
    handleDisagreeClose: null,
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const SleepMode = useSelector((state) => state.setting.SleepMode);

  const handleSleepmodeChange = async (event) => {
    if (Position && Position.attributes && Position.attributes.ignition) {
      const status = event.target.checked;
      const data = status ? "124,1" : "102,10,240";

      var res = await fetch(`/api/devices/${selectedDeviceId}`);

      const dataOfVehicle = await res.json();

      const contact = dataOfVehicle.contact.split(",");

      contact[2] = status ? "1" : "0";
      let newContact = "";
      contact.forEach((e, i) => {
        if (contact[i + 1]) {
          newContact = `${newContact + e},`;
        } else {
          newContact += e;
        }
      });

      var res = await fetch(`/api/devices/${selectedDeviceId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...dataOfVehicle, contact: newContact }),
      });

      if (res.ok) {
        const res = await fetch("/api/commands/send", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId: selectedDeviceId,
            type: "custom",
            attributes: {
              data,
            },
          }),
        });

        if (res.ok) {
          dispatch(settingActions.UpdateSleepMode(status));
          // setSleepMode(status);
          dispatch(
            notificationActions.SetCustomAlertNotification(
              status ? "Sleep Mode Activated" : "Sleep Mode Deactivated"
            )
          );
        }
      }
    } else {
      setDialog({
        isOpen: true,
        handleClose: () => setDialog({ isOpen: false }),
        handleDisagreeClose: () => {
          setDialog({ isOpen: false });
        },
        handleSuccess: () => {},
        decription: "Ignition needs to be on to activate or deactivate",
        title: "Stop",
        type: "",
        closeBtnText: "Close",
      });
    }
  };

  return (
    <Grid item xs={12} md={4} lg={4}>
      <DialogBox
        handleDisagreeClose={Dialog.handleDisagreeClose}
        handleClose={Dialog.handleClose}
        handleSuccess={Dialog.handleSuccess}
        open={Dialog.isOpen}
        decription={Dialog.decription}
        title={Dialog.title}
        type={Dialog.type}
        closeBtnText={Dialog.closeBtnText}
        confmBtnText={Dialog.confmBtnText}
      />
      <Card className={classes.root}>
        <CardHeader
          // avatar={handleStatus(selectedRow.status, selectedRow.id)}
          avatar={<HotelOutlinedIcon color="primary" />}
          title="Set Sleep Mode"
        />
        Sleep mode powers off the GPS module and puts the GSM module to sleep
        therefore, conserving battery drainage. Vibration or starting the
        vehicle will resume normal operation
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={SleepMode}
                onChange={handleSleepmodeChange}
                name="SleepMode"
                color="primary"
              />
            }
            label={SleepMode ? "Sleep Mode - On" : "Sleep Mode - Off"}
          />
        </FormGroup>
      </Card>
    </Grid>
  );
};

export default SleepModePage;
