import React, { useState } from "react";
import MainToolbar from "components/MainToolbar";
import { useNavigate, useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { useTranslation } from "common/components/LocalizationProvider";
import { useEffectAsync } from "reactHelper";

import { sessionActions } from "store";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-evenly",
    "& > *": {
      flexBasis: "33%",
    },
  },
}));

const EditItemView = ({ children, endpoint, item, setItem, disableGoBack }) => {
  const [openToast, setOpenToast] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const t = useTranslation();

  const dispatch = useDispatch();
  useEffectAsync(async () => {
    if (id) {
      const response = await fetch(`/api/${endpoint}/${id}`);
      if (response.ok) {
        setItem(await response.json());
      }
    } else {
      setItem({});
    }
  }, [id]);

  const handleSave = async () => {
    let url = `/api/${endpoint}`;
    if (id) {
      url += `/${id}`;
    }

    const response = await fetch(url, {
      method: !id ? "POST" : "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(item),
    });

    if (response.ok) {
      const user = await response.json();
      // debugger
      dispatch(sessionActions.updateUser(user));

      if (!disableGoBack) {
        navigate(-1);
      } else {
        setOpenToast(true);
      }
    }
  };

  return (
    <>
      <MainToolbar />
      <Container maxWidth="xs" className={classes.container}>
        {children}
        <Snackbar
          open={openToast}
          autoHideDuration={6000}
          onClose={() => {
            setOpenToast(false);
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            severity="success"
            variant="filled"
            onClose={() => {
              setOpenToast(false);
            }}
          >
            Updated Successfully
          </Alert>
        </Snackbar>
        <FormControl fullWidth margin="normal">
          <div className={classes.buttons}>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              {t("sharedCancel")}
            </Button>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={handleSave}
            >
              {t("sharedSave")}
            </Button>
          </div>
        </FormControl>
      </Container>
    </>
  );
};

export default EditItemView;
