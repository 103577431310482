import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "common/components/LocalizationProvider";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  List,
  Container,
  Paper,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffectAsync } from "reactHelper";
import { formatPosition, formatCapAttributes } from "common/formatter/";
import { prefixString } from "common/util/stringUtils";
import { useDispatch, useSelector } from "react-redux";
import PositionValue from "common/components/PositionValue";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const PositionPage = (props) => {
  const classes = useStyles();
  // const t = useTranslation();

  const { deviceId } = useParams();
  // const selectedDevicePositionId = useSelector((state) => {
  //   if (deviceId) {
  //     const position = state.positions.items[deviceId] || null;
  //     if (position) {
  //       return position.id;
  //     }
  //   }
  // });

  const { id } = props;
  // console.log(props);
  const [positionId, setPositionId] = useState(null);

  useEffect(() => {
    if (id) {
      setPositionId(id);
    }
  }, [id]);

  const navigate = useNavigate();
  const t = useTranslation();
  const [item, setItem] = useState();

  useEffectAsync(async () => {
    if (id) {
      const response = await fetch(`/api/positions?id=${id}`);
      if (response.ok) {
        const positions = await response.json();
        if (positions.length > 0) {
          setItem(positions[0]);
        }
      } else {
        throw Error(await response.text());
      }
    }
  }, [id]);

  const deviceName = useSelector((state) => {
    if (item) {
      const device = state.devices.items[item.deviceId];
      if (device) {
        return device.name;
      }
    }
    return null;
  });

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Paper>
        {item && (
          <List
            style={{
              overflowY: "scroll",
              maxHeight: window.innerHeight - 200,
            }}
          >
            {item &&
              Object.getOwnPropertyNames(item)
                .filter((it) => it !== "attributes")
                .map((property) => (
                  <ListItem>
                    <ListItemText
                      style={{ color: "#57c7d4" }}
                      primary={formatCapAttributes(property)}
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="body2" style={{ color: "#57c7d4" }}>
                        <PositionValue position={item} property={property} />
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            {item &&
              Object.getOwnPropertyNames(item.attributes).map((attribute) => (
                <ListItem>
                  <ListItemText
                    style={{ color: "#57c7d4" }}
                    primary={formatCapAttributes(attribute)}
                  />
                  <ListItemSecondaryAction>
                    <Typography variant="body2" style={{ color: "#57c7d4" }}>
                      <PositionValue position={item} attribute={attribute} />
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        )}
      </Paper>
    </Container>
  );
};

export default PositionPage;
