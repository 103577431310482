import React, { useState, useEffect } from "react";
import { makeStyles, ThemeProvider } from "@mui/styles";
import MainToolbar from "components/MainToolbar";
import { useTranslation } from "common/components/LocalizationProvider";
import { formatDateAustralian } from "common/formatter";
import {
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  ButtonGroup,
  InputAdornment,
  IconButton,
  createTheme,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import EventIcon from "@mui/icons-material/Event";
import LinearProgress from "@mui/material/LinearProgress";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import usePositionAttributes from "../common/attributes/usePositionAttributes";
import _ from "lodash";
import {
  CustomAlertBox,
  convertUnicode,
  DialogBox,
  useEffectAsync,
} from "reactHelper";
import EditCollectionView from "../EditCollectionView";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const materialTheme = createTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        color: "#fff",
      },
    },
    MuiIconButton: {
      root: {
        color: "#2436ac",
      },
    },
    MuiInputBase: {
      input: {
        color: "#fff",
      },
      root: {
        color: "#fff",
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: "#000",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    // height: "100%",
    // display: "flex",
    // flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& .MuiIcon-colorPrimary": {
      color: "#4ac7c9",
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
      display: "none",
    },
  },
  loaderOverlay: {
    position: "fixed",
    height: "100%",
    width: "100%",
    background: "rgba(0, 0, 0, 0.61)",
    zIndex: 999,
  },
  apiLoader: {
    top: 0,
    left: 0,
    position: "relative",
    height: "100vh",
    width: "100%",
    alignContent: "center",
    display: "flex",
    "& .MuiCircularProgress-root": {
      width: "40px",
      height: "40px",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%)",
    },
  },
  warningroot: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& .MuiIcon-colorPrimary": {
      color: "#4ac7c9",
    },
  },
  chip: {
    margin: 2,
    backgroundColor: "#616161",
    color: "#fff",
  },
  tablechip: {
    margin: 1,
    backgroundColor: "#616161",
    color: "#fff",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
    marginBottom: "10px",
  },
  heading: {
    color: "#00ac69",
  },
  fieldsetWithPadding: {
    padding: "0 25px",
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  customDatePickerDialog: {
    "& .MuiDialogContent-root": {
      background: "#0f0e15 !important",
      color: "red",
    },
    "& .MuiDialog-paperScrollPaper": {
      background: "#1F1D2B",
      color: "#fff",
    },
    "& .MuiDayPicker-weekDayLabel": {
      color: "#fff",
    },
    "& .MuiPickersCalendarHeader-switchViewButton": {
      color: "#fff",
    },
    "& .MuiPickersArrowSwitcher-button": {
      color: "#fff",
    },
    "& .MuiInputLabel-formControl": {
      color: "#fff",
    },
    "& .MuiPickersToolbar-root": {
      background: "#3f51b5",
      color: "#fff",
    },
    "& .MuiPickersToolbar-root span": {
      color: "#fff",
    },
    "& .PrivatePickersToolbarText-root": {
      color: "#fff",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#f50057",
    },
    "& .MuiTabs-flexContainer": {
      background: "#3f51b5",
      color: "#fff",
    },
    "& .MuiTabs-flexContainer button": {
      color: "#fff",
    },
    "& .MuiDialogActions-root button": {
      color: "#3f51b5",
    },
    "& .MuiPickersDay-root": {
      color: "#fff",
      background: "none",
    },
    "& .Mui-selected.MuiPickersDay-root": {
      color: "#fff",
      background: "#3f51b5",
    },
    "& .MuiClock-amButton": {
      color: "#fff",
    },
    "& .MuiClock-pmButton": {
      color: "#fff",
    },
    "& .MuiClockPicker-root": {
      background: "#0f0e15",
    },
    "& .MuiClockNumber-root": {
      color: "#fff",
    },
    "& .MuiClockNumber-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiCalendarOrClockPicker-mobileKeyboardInputView .MuiInputBase-root": {
      color: "#fff",
    },
    "& .MuiCalendarOrClockPicker-mobileKeyboardInputView label": {
      color: "#fff",
    },
  },
}));

const DevicesViewNew = ({ updateTimestamp, onMenuClick }) => {
  const classes = useStyles();
  const t = useTranslation();

  const [items, setItems] = useState([]);
  const [users, setUsers] = useState([]);

  const [Dialog, setDialog] = useState({
    isOpen: false,
    handleSuccess: null,
    handleClose: null,
    decription: null,
    title: null,
    type: "no",
    closeBtnText: "Close",
    confmBtnText: "Agree",
    handleDisagreeClose: null,
  });

  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const [period, setPeriod] = useState("today");
  const [from, setFrom] = useState(moment().startOf("day"));
  const [to, setTo] = useState(moment().endOf("day"));
  const [selectedTo, setSelectedTo] = useState(null);
  const [selectedFrom, setSelectedFrom] = useState(null);
  const [positions, setPositions] = useState([]);
  const [deviceId, setDeviceId] = useState(null);
  const [userId, setUserId] = useState("all");
  const [userDevices, setUserDevices] = useState([]);
  const [showDisabled, setShowDisabled] = useState(false);

  const [open, setOpen] = React.useState({
    Isshow: false,
    SuccesCBWithData: null,
    name: null,
  });

  const [openWarning, setOpenWarning] = useState(false);
  const [warning, setWarning] = useState(null);
  const handleWarningClose = () => {
    setOpenWarning(false);
  };

  const handleClickOpen = (cb, data) => {
    setOpen({ Isshow: true, SuccesCBWithData: cb, name: data });
  };

  const handelSuccessClose = () => {
    open.SuccesCBWithData();
    handleClose();
  };

  const handleClose = () => {
    setOpen({ Isshow: false });
  };

  const getAllUsers = async () => {
    const response = await fetch("/api/users");
    if (response.ok) {
      setUsers(await response.json());
    }
  };

  const getUserDevices = async () => {
    const ApiPath =
      userId != "all"
        ? `/api/devices?userId=${userId}`
        : "/api/devices?all=true";

    const response = await fetch(ApiPath);
    if (response.ok) {
      const userDevicesTemp = await response.json();
      let userDevicesIdTemp = userDevicesTemp;
      userDevicesIdTemp = userId != "all" ? userDevicesIdTemp : [];

      setUserDevices(userDevicesIdTemp);
    }
  };

  const getUserPositions = async () => {
    setLoader(true);
    const ApiPath =
      userId != "all"
        ? `/api/positions?deviceId=${deviceId}&from=${selectedFrom.toISOString()}&to=${selectedTo.toISOString()}`
        : "/api/positions?all=true";

    const response = await fetch(ApiPath);

    if (response.ok) {
      const positionsTemp = await response.json();
      let deviceName = _.find(userDevices, { id: deviceId });
      let result = [
        {
          deviceId: deviceId,
          name: deviceName?.name,
          positionsCount: positionsTemp.length,
          startDate: selectedFrom.toString(),
          endDate: selectedTo.toString(),
        },
      ];

      setPositions([...result]);
    }
    if (!!response) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getUserDevices();
  }, [userId]);

  useEffectAsync(async () => {
    await getAllUsers();
  }, [updateTimestamp]);

  useEffect(() => {
    switch (period) {
      case "today":
        setSelectedFrom(moment().startOf("day"));
        setSelectedTo(moment().endOf("day"));
        break;
      case "yesterday":
        setSelectedFrom(moment().subtract(1, "day").startOf("day"));
        setSelectedTo(moment().subtract(1, "day").endOf("day"));
        break;
      case "thisWeek":
        setSelectedFrom(moment().startOf("week"));
        setSelectedTo(moment().endOf("week"));
        break;
      case "previousWeek":
        setSelectedFrom(moment().subtract(1, "week").startOf("week"));
        setSelectedTo(moment().subtract(1, "week").endOf("week"));
        break;
      case "thisMonth":
        setSelectedFrom(moment().startOf("month"));
        setSelectedTo(moment().endOf("month"));
        break;
      case "previousMonth":
        setSelectedFrom(moment().subtract(1, "month").startOf("month"));
        setSelectedTo(moment().subtract(1, "month").endOf("month"));
        break;
      case "3Month":
        setSelectedFrom(moment().subtract(2, "year").startOf("day"));
        setSelectedTo(moment().subtract(3, "month").endOf("day"));
        break;
      case "6Month":
        setSelectedFrom(moment().subtract(2, "year").startOf("day"));
        setSelectedTo(moment().subtract(6, "month").endOf("day"));
        break;
      case "12Month":
        setSelectedFrom(moment().subtract(2, "year").startOf("day"));
        setSelectedTo(moment().subtract(12, "month").endOf("day"));
        break;
      default:
        // from.setHours(0, 0, 0, 0);
        setSelectedFrom(from);
        // to.setHours(23, 59, 59, 999);
        setSelectedTo(to);
        break;
    }
  }, [period]);

  useEffect(() => {
    if (period == "custom") {
      setSelectedFrom(from);
    }
  }, [period, from]);

  useEffect(() => {
    if (period == "custom") {
      setSelectedTo(to);
    }
  }, [period, to]);

  useEffect(() => {
    if (!!deviceId && !!userId && !!to && !!from) {
      setShowDisabled(false);
    } else {
      setShowDisabled(true);
    }
  }, [deviceId, userId, to, from]);

  const deletePositions = async (rowData) => {
    let startDate = !!rowData.startDate ? moment(rowData.startDate) : false;
    let endDate = !!rowData.endDate ? moment(rowData.endDate) : false;
    if (startDate && endDate) {
      setLoader(true);
      const ApiPath =
        userId != "all"
          ? `/api/positions?deviceId=${
              rowData.deviceId
            }&from=${startDate.toISOString()}&to=${endDate.toISOString()}`
          : "/api/positions?all=true";

      const response = await fetch(ApiPath, {
        method: "DELETE",
      });
      if (!!response) {
        setLoader(false);
      }
      if (response.ok) {
        setPositions([]);
      }
    }
  };

  return (
    <div className={classes.root}>
      {loader && (
        <div className={classes.loaderOverlay}>
          <Box className={classes.apiLoader} sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </div>
      )}

      <DialogBox
        handleDisagreeClose={Dialog.handleDisagreeClose}
        handleClose={Dialog.handleClose}
        handleSuccess={Dialog.handleSuccess}
        open={Dialog.isOpen}
        decription={Dialog.decription}
        title={Dialog.title}
        type={Dialog.type}
        closeBtnText={Dialog.closeBtnText}
        confmBtnText={Dialog.confmBtnText}
      />
      <AlertDialog
        OnSuccess={handelSuccessClose}
        closeCB={handleClose}
        open={open.Isshow}
        name={open.name}
      />
      <WarningDialog
        closeWarningCB={handleWarningClose}
        openWarning={openWarning}
        warning={warning}
      />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={3}
            lg={2}
            style={{ height: "calc(100vh - 40px)", overflow: "scroll" }}
          >
            <Paper className={classes.form}>
              <FormControl margin="normal" fullWidth>
                <>
                  <FormControl variant="filled" margin="normal" fullWidth>
                    <InputLabel>Users</InputLabel>
                    <Select
                      value={userId}
                      onChange={(e) => {
                        setUserId(e.target.value);
                      }}
                      MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    >
                      <MenuItem value="all">Select User</MenuItem>
                      {_.orderBy(
                        users,
                        [(item) => item.name.toLowerCase()],
                        ["asc"]
                      ).map((user) => (
                        <MenuItem value={user.id}>{user.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="filled" margin="normal" fullWidth>
                    <InputLabel>Vehicle</InputLabel>
                    <Select
                      value={deviceId}
                      onChange={(e) => {
                        setDeviceId(e.target.value);
                        setItems([]);
                      }}
                      MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    >
                      {_.orderBy(
                        userDevices,
                        [(item) => item.name.toLowerCase()],
                        ["asc"]
                      ).map((device) => (
                        <MenuItem value={device.id}>
                          {convertUnicode(device.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="filled" margin="normal" fullWidth>
                    <InputLabel>{t("reportPeriod")}</InputLabel>
                    <Select
                      value={period}
                      onChange={(e) => setPeriod(e.target.value)}
                      MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    >
                      <MenuItem value="today">{t("reportToday")}</MenuItem>
                      <MenuItem value="yesterday">
                        {t("reportYesterday")}
                      </MenuItem>
                      <MenuItem value="thisWeek">
                        {t("reportThisWeek")}
                      </MenuItem>
                      <MenuItem value="previousWeek">
                        {t("reportPreviousWeek")}
                      </MenuItem>
                      <MenuItem value="thisMonth">
                        {t("reportThisMonth")}
                      </MenuItem>
                      <MenuItem value="previousMonth">
                        {t("reportPreviousMonth")}
                      </MenuItem>
                      <MenuItem value="custom">{t("reportCustom")}</MenuItem>
                      <MenuItem value="3Month">3 Months</MenuItem>
                      <MenuItem value="6Month">6 Months</MenuItem>
                      <MenuItem value="12Month">12 Months</MenuItem>
                    </Select>
                  </FormControl>
                  {period === "custom" && (
                    <ThemeProvider theme={materialTheme}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDateTimePicker
                          className={classes.customDatePicker}
                          label={t("reportFrom")}
                          inputFormat="DD/MM/YYYY hh:mm a"
                          value={from}
                          onChange={(date) => {
                            setFrom(date);
                          }}
                          DialogProps={{
                            className: classes.customDatePickerDialog,
                          }}
                          renderInput={(params) => (
                            <TextField
                              margin="normal"
                              variant="filled"
                              className={classes.datePickerInput}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton edge="end">
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              {...params}
                            />
                          )}
                        />
                        <MobileDateTimePicker
                          className={classes.customDatePicker}
                          label={t("reportTo")}
                          inputFormat="DD/MM/YYYY hh:mm a"
                          value={to}
                          minDate={from}
                          onChange={(date) => setTo(date)}
                          DialogProps={{
                            className: classes.customDatePickerDialog,
                          }}
                          renderInput={(params) => (
                            <TextField
                              margin="normal"
                              variant="filled"
                              className={classes.datePickerInput}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton edge="end">
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </ThemeProvider>
                  )}
                  <FormControl margin="normal" fullWidth>
                    <ButtonGroup
                      color="primary"
                      orientation="vertical"
                      disabled={!userId && !deviceId}
                    >
                      <Button
                        onClick={() => getUserPositions()}
                        disabled={showDisabled}
                      >
                        {t("reportShow")}
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </>
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            {loader ? (
              <LinearProgress
                variant="buffer"
                value={progress}
                valueBuffer={buffer}
              />
            ) : (
              ""
            )}

            {positions ? (
              <MaterialTable
                columns={[
                  {
                    title: "Vehicle Name",
                    field: "name",
                    render: (rowData) => rowData?.name,
                  },
                  {
                    title: "No. of Positions",
                    field: "positionsCount",
                    render: (rowData) => rowData.positionsCount,
                  },
                  {
                    title: "First Position Date",
                    field: "startDate",
                    render: (rowData) =>
                      formatDateAustralian(rowData.startDate),
                  },
                  {
                    title: "Last Position Date",
                    field: "endDate",
                    render: (rowData) => formatDateAustralian(rowData.endDate),
                  },
                ]}
                actions={[
                  {
                    icon: "delete",
                    tooltip: "Delete",
                    iconProps: {
                      color: "primary",
                    },
                    cellStyle: {
                      padding: "10px 16px",
                      borderBottom: "1px solid #3f403f",
                    },
                    onClick: (event, rowData) => {
                      handleClickOpen(
                        () => deletePositions(rowData),
                        rowData.name
                      );
                      // ;
                    },
                  },
                ]}
                data={positions}
                title="Positions"
                // icons={tableIcons}
                options={{
                  actionsColumnIndex: -1,
                  // exportButton: true,
                  cellStyle: {
                    padding: "6px 16px",
                    borderBottom: "1px solid #3f403f",
                  },
                  actionsCellStyle: {
                    borderBottom: "1px solid #3f403f",
                  },
                  headerStyle: {
                    backgroundColor: "#1F1D2B",
                    color: "#FFFFFF",
                    borderBottom: "2px solid #cbcad0",
                  },
                  rowStyle: (rowData) => ({
                    backgroundColor: "#0d0d0d",
                    display: "table-row",
                  }),

                  sorting: true,
                  paging: items.length > 10,
                  pageSize: 10,
                  pageSizeOptions: [],
                  /* maxBodyHeight: calcHeight+'px', */
                  defaultSort: "asc",
                }}
                style={{ color: "#57c7d4" }}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const DevicesPage = () => (
  <>
    <MainToolbar />
    <EditCollectionView
      content={DevicesViewNew}
      editPath="/settings/driver"
      endpoint="devices"
    />
  </>
);

const AlertDialog = ({ open, OnSuccess, closeCB, name }) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={closeCB}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.root}
      >
        <DialogTitle id="alert-dialog-title">Confirm ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="secondary">
            Are you sure you want to delete positions associated with Device{" "}
            {name}, this cannot be reversed?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCB} color="primary">
            No
          </Button>
          <Button onClick={OnSuccess} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const WarningDialog = ({ openWarning, warning, closeWarningCB }) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={openWarning}
        onClose={closeWarningCB}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.warningroot}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="secondary">
            {warning}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeWarningCB} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DevicesPage;
