import React, { useState } from "react";
import { Button, Paper, createTheme, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainToolbar from "components/MainToolbar";
import makeStyles from "@mui/styles/makeStyles";
import { useEffectAsync } from "../reactHelper";
import { useTranslation } from "../common/components/LocalizationProvider";
import LinearProgress from "@mui/material/LinearProgress";
import MaterialReactTable from "material-react-table";
import { AddCircle } from "@mui/icons-material";
import CollectionActions from "./components/CollectionActions";

const materialTheme = createTheme({
  overrides: {
    // MuiPickersToolbar: {
    //   toolbar: {
    //     backgroundColor: "#3f51b5",
    //   },
    // },
    // MuiPickersCalendarHeader: {
    //   switchHeader: {
    //     // backgroundColor: lightBlue.A200,
    //     // color: "white",
    //   },
    // },
    // MuiPickersDay: {
    //   day: {
    //     color: "#3f51b5",
    //   },
    //   daySelected: {
    //     backgroundColor: "#3f51b5",
    //   },
    //   dayDisabled: {
    //     color: "#3f51b5",
    //   },
    //   current: {
    //     color: "#3f51b5",
    //   },
    // },
    MuiInputLabel: {
      root: {
        color: "#fff",
      },
    },
    MuiIconButton: {
      root: {
        color: "#2436ac",
      },
    },
    MuiInputBase: {
      input: {
        color: "#fff",
      },
      root: {
        color: "#fff",
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: "#000",
      },
    },
    // MuiPickersModal: {
    //   dialogAction: {
    //     color: "#3f51b5",
    //   },
    // },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& svg": {
      height: 30,
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  customDatePickerDialog: {
    "& .MuiDialogContent-root": {
      background: "#0f0e15 !important",
      color: "red",
    },
    "& .MuiDialog-paperScrollPaper": {
      background: "#1F1D2B",
      color: "#fff",
    },
    "& .MuiDayPicker-weekDayLabel": {
      color: "#fff",
    },
    "& .MuiPickersCalendarHeader-switchViewButton": {
      color: "#fff",
    },
    "& .MuiPickersArrowSwitcher-button": {
      color: "#fff",
    },
    "& .MuiInputLabel-formControl": {
      color: "#fff",
    },
    "& .MuiPickersToolbar-root": {
      background: "#3f51b5",
      color: "#fff",
    },
    "& .MuiPickersToolbar-root span": {
      color: "#fff",
    },
    "& .PrivatePickersToolbarText-root": {
      color: "#fff",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#f50057",
    },
    "& .MuiTabs-flexContainer": {
      background: "#3f51b5",
      color: "#fff",
    },
    "& .MuiTabs-flexContainer button": {
      color: "#fff",
    },
    "& .MuiDialogActions-root button": {
      color: "#3f51b5",
    },
    "& .MuiPickersDay-root": {
      color: "#fff",
      background: "none",
    },
    "& .Mui-selected.MuiPickersDay-root": {
      color: "#fff",
      background: "#3f51b5",
    },
    "& .MuiClock-amButton": {
      color: "#fff",
    },
    "& .MuiClock-pmButton": {
      color: "#fff",
    },
    "& .MuiClockPicker-root": {
      background: "#0f0e15",
    },
    "& .MuiClockNumber-root": {
      color: "#fff",
    },
    "& .MuiClockNumber-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiCalendarOrClockPicker-mobileKeyboardInputView .MuiInputBase-root": {
      color: "#fff",
    },
    "& .MuiCalendarOrClockPicker-mobileKeyboardInputView label": {
      color: "#fff",
    },
  },
}));

const CalendarsPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [timestamp, setTimestamp] = useState(Date.now());
  const t = useTranslation();
  let columns = [
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      header: "Action",
      accessorKey: "action",
      Cell: ({ renderedCellValue, row }) => {
        return (
          <>
            <CollectionActions
              itemId={row.original.id}
              editPath="/settings/calendar"
              endpoint="calendars"
              setTimestamp={setTimestamp}
            />
          </>
        );
      },
    },
  ];

  const [items, setItems] = useState([]);
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const [loading, setLoading] = useState(false);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/calendars");
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  return (
    <>
      <div className={classes.root}>
        <MainToolbar />
        <div className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={2}>
              <Paper className={classes.form}>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddCircle />}
                  onClick={() => {
                    navigate("/settings/calendar");
                  }}
                >
                  {"Add New"}
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              {loader ? (
                <LinearProgress
                  variant="buffer"
                  value={progress}
                  valueBuffer={buffer}
                />
              ) : (
                ""
              )}
              <MaterialReactTable
                columns={columns}
                data={items
                  .slice(0, 4000)
                  .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0))}
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={items.length > 50}
                enableSorting={true}
                enableBottomToolbar={true}
                enableTopToolbar={true}
                enableStickyHeader
                muiTablePaginationProps={{
                  rowsPerPageOptions: [50],
                }}
                renderTopToolbarCustomActions={() => (
                  <Typography
                    variant="h6"
                    style={{
                      color: "#00ac69",
                      paddingLeft: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Calendars
                  </Typography>
                )}
                muiTopToolbarProps={{
                  sx: {
                    backgroundColor: "#1F1D2B",
                    color: "#FFFFFF",
                  },
                }}
                muiBottomToolbarProps={{
                  sx: {
                    backgroundColor: "#1F1D2B",
                    color: "#FFFFFF",
                  },
                }}
                muiTableHeadProps={{
                  sx: {
                    backgroundColor: "#1F1D2B",
                    color: "#FFFFFF",
                  },
                }}
                muiTableContainerProps={{
                  sx: {
                    color: "#57c7d4",
                    overflowY: "scroll",
                  },
                }}
                muiTableHeadRowProps={({ table, headerGroup }) => ({
                  sx: {
                    backgroundColor: "#1F1D2B",
                    color: "#FFFFFF",
                  },
                })}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    //navigate(`/settings/calendar/${row.original.id}`);
                  },
                  sx: {
                    cursor: "pointer",
                  },
                })}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default CalendarsPage;
