import React, { useEffect, useState } from "react";
import MainToolbar from "components/MainToolbar";
import makeStyles from "@mui/styles/makeStyles";
import {
  Paper,
  FormControl,
  FormControlLabel,
  Switch,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Card,
  CardHeader,
  FormGroup,
} from "@mui/material";
import { convertUnicode, useEffectAsync } from "reactHelper";
import { useDispatch, useSelector } from "react-redux";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import DialBoxContainer from "common/util/keyboard";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import { devicesActions, notificationActions, settingActions } from "../store";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
    "& .MuiSwitch-switchBase": {
      color: "#f30707",
    },
    "& .MuiSwitch-colorPrimary.Mui-checked": {
      color: "#3f51b5",
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  columnAction: {
    // width: theme.spacing(1),
    // padding: theme.spacing(0, 1),
  },
}));

const IgnitionPage = () => {
  const classes = useStyles();

  const selectedDevice = useSelector((state) => state.devices.selectedItem);
  const selectedDeviceId = selectedDevice?.id;

  const items = useSelector((state) => Object.values(state.devices.items));

  // const [PurchasedDevices, setPurchasedDevices] = useState([])

  // const PurchasedDevices = useSelector(state => state.setting.PurchasedDevices);
  const dispatch = useDispatch();
  const [deviceId, setDeviceId] = useState();

  const [SelectedDeviceData, setSelectedDeviceData] = useState();

  useEffectAsync(async () => {
    const response = await fetch("/api/devices");
    if (response.ok) {
      // console.log("Device list updated");
      const devices = await response.json();
      dispatch(devicesActions.refresh(devices));
    }

    const res = await fetch(`/api/devices/${deviceId}`);
    if (res.ok) {
      const data = await res.json();
      const accessConfiguration = data.accessConfiguration?.split(",");

      const attributes = data?.attributes;

      dispatch(settingActions.UpdateAttributes({ ...attributes }));

      if (accessConfiguration && accessConfiguration.length > 0) {
        dispatch(
          settingActions.SetisSetIgnitionVisible(accessConfiguration[0] === "1")
        );
        dispatch(
          settingActions.SetIsSpeedingAlarmVisible(
            accessConfiguration[1] === "1"
          )
        );
        dispatch(
          settingActions.SetIsSleepModeVisible(accessConfiguration[2] === "1")
        );
        dispatch(
          settingActions.SetisTempVisible(accessConfiguration[3] === "1")
        );
        dispatch(
          settingActions.SetisTempMapVisible(accessConfiguration[9] === "1")
        );
        dispatch(
          settingActions.SetisGroupVisible(accessConfiguration[8] === "1")
        );
        dispatch(
          settingActions.UpdateDriverVisible(accessConfiguration[4] === "1")
        );
        dispatch(
          settingActions.UpdateDriverBehaviourVisible(
            accessConfiguration[5] === "1"
          )
        );
        dispatch(
          settingActions.UpdateMaintenanceVisible(
            accessConfiguration[6] === "1"
          )
        );
        dispatch(
          settingActions.UpdateNotificationsVisible(
            accessConfiguration[7] === "1"
          )
        );
        dispatch(
          settingActions.UpdateGroupsVisible(accessConfiguration[8] === "1")
        );
        dispatch(
          settingActions.SetHoursEnabled(accessConfiguration[10] === "1")
        );
        dispatch(
          settingActions.SetisMileageVisible(accessConfiguration[11] === "1")
        );
        dispatch(
          settingActions.SetisPreferenceVisible(accessConfiguration[12] === "1")
        );
        dispatch(
          settingActions.SetisCalendarVisible(accessConfiguration[13] === "1")
        );
        dispatch(
          settingActions.SetisAssignedGroupVisible(
            accessConfiguration[14] === "1"
          )
        );
      } else {
        dispatch(settingActions.SetisSetIgnitionVisible(false));
        dispatch(settingActions.SetIsSpeedingAlarmVisible(false));
        dispatch(settingActions.SetIsSleepModeVisible(false));
        dispatch(settingActions.SetisTempVisible(true));
        dispatch(settingActions.SetisTempMapVisible(false));
        dispatch(settingActions.SetisGroupVisible(false));
        dispatch(settingActions.UpdateDriverVisible(false));
        dispatch(settingActions.UpdateDriverBehaviourVisible(false));
        dispatch(settingActions.UpdateMaintenanceVisible(false));
        dispatch(settingActions.UpdateNotificationsVisible(false));
        dispatch(settingActions.UpdateGroupsVisible(false));
        dispatch(settingActions.SetHoursEnabled(false));
        dispatch(settingActions.SetisMileageVisible(false));
        dispatch(settingActions.SetisPreferenceVisible(false));
        dispatch(settingActions.SetisCalendarVisible(false));
        dispatch(settingActions.SetisAssignedGroupVisible(false));
      }

      const contact = data.contact?.split(",");
      if (contact && contact.length > 0) {
        dispatch(settingActions.UpdateIgnition(contact[0] === "1"));
        dispatch(settingActions.UpdateSleepMode(contact[2] !== "0"));
        dispatch(settingActions.UpdateSpeedingAlarm(contact[1]));
        dispatch(settingActions.UpdateTempAlarm(contact[3]));
      }
      dispatch(settingActions.UpdateGroupId(data?.groupId));
    }
  }, [deviceId, selectedDeviceId]);

  return (
    <div className={classes.root}>
      <MainToolbar />
      <div className={classes.content}>
        <Grid container spacing={2}>
          {/* {true && ( */}
          <>
            <Grid item xs={12} md={3} lg={2}>
              <Paper className={classes.form}>
                <>
                  <FormControl variant="filled" margin="normal" fullWidth>
                    <InputLabel>Vehicle</InputLabel>

                    <Select
                      value={deviceId}
                      onChange={(e) => {
                        setDeviceId(e.target.value);
                        setSelectedDeviceData(
                          items.filter((d) => d.id === e.target.value)[0]
                        );
                      }}
                      MenuProps={{
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >
                      {_.orderBy(
                        items,
                        [(item) => item.name.toLowerCase()],
                        ["asc"]
                      ).map((device) => {
                        if (
                          device?.attributes?.accessConfiguration &&
                          device?.attributes?.accessConfiguration[0] === "1"
                        ) {
                          return (
                            <MenuItem value={device.id}>
                              {convertUnicode(device.name)}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                  {/* <FormControl margin="normal" fullWidth>
                  <ButtonGroup
                    color="primary"
                    orientation="vertical"
                    disabled={!true}
                  >
                    <Button onClick={() => setShowReport(true)}>
                      {t("reportShow")}
                    </Button>
                  </ButtonGroup>
                </FormControl> */}
                </>
              </Paper>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              {SelectedDeviceData && (
                <CardView
                  Ignit={SelectedDeviceData.contact[0] === "1"}
                  items={items}
                  deviceId={deviceId}
                />
              )}
            </Grid>
          </>
          {/* )} */}
        </Grid>
      </div>
    </div>
  );
};

const CardView = ({ Ignit, items, deviceId }) => {
  const [Ignition, setIgnition] = useState(Ignit);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const found_device = items.find((element) => element.id == deviceId);
  const Position = useSelector((state) => {
    if (state.devices.selectedId) {
      const position =
        state.session.positions[state.devices.selectedId] || null;
      return position;
    }
    return null;
  });

  const [IsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIgnition(Ignit);
  }, [Ignit]);

  const handleIgnitionChange = async (d) => {
    setPassword("");
    const status = !Ignition;
    const data = status ? "900,1,0" : "900,1,0,20";

    var res = await fetch(`/api/devices/${deviceId}`);

    const dataOfVehicle = await res.json();

    const contact = dataOfVehicle.contact.split(",");

    contact[0] = status ? "1" : "0";
    if (contact[4] === d.toString()) {
      let newContact = "";
      contact.forEach((e, i) => {
        if (contact[i + 1]) {
          newContact = `${newContact + e},`;
        } else {
          newContact += e;
        }
      });

      var res = await fetch(`/api/devices/${deviceId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...dataOfVehicle, contact: newContact }),
      });

      if (res.ok) {
        var res;
        // const res = await  fetch( `/api/commands/send`, {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({
        //     deviceId: deviceId,
        //     type: "custom",
        //     attributes: {
        //       data: data,
        //     },
        //   }),
        // });

        if (!status) {
          if (Position && Position.speed > 20) {
            res = null;
            let newContact = "";
            contact[0] = 1;
            contact.forEach((e, i) => {
              if (contact[i + 1]) {
                newContact = `${newContact + e},`;
              } else {
                newContact += e;
              }
            });

            await fetch(`/api/devices/${deviceId}`, {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ ...dataOfVehicle, contact: newContact }),
            });

            dispatch(
              notificationActions.SetCustomAlertNotification(
                "Cannot disable Speed High!"
              )
            );
          } else {
            res = await fetch("/api/commands/send", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                // id:1,
                deviceId,
                type: "engineStop",
                description: "Ignition Disable",
                attributes: {
                  /// data: data,
                },
              }),
            });
          }
        } else {
          res = await fetch("/api/commands/send", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              // id:2,
              deviceId,
              type: "engineResume",
              description: "Ignition Enable",
              attributes: {
                /// data: data,
              },
            }),
          });
        }

        if (res.ok) {
          dispatch(notificationActions.updateIgnitionState(status));
          // alert("Setitng ignition update "+ status)
          setIgnition(status);
          isMobile
            ? alert(status ? "Ignition Enabled" : "Igniton Disabled")
            : dispatch(
                notificationActions.SetCustomAlertNotification(
                  status ? "Ignition Enabled" : "Igniton Disabled"
                )
              );
        }
      }
    } else {
      isMobile
        ? alert("Incorrect Password")
        : dispatch(
            notificationActions.SetCustomAlertNotification("Incorrect Password")
          );
    }
  };

  return (
    <Grid item xs={12} md={4} lg={4}>
      {/* <PasswordDialogBox open={IsDialogVisible} handleConform={handleConform} handelCancel={handelCancel}/> */}
      <Card className={classes.root}>
        <CardHeader
          // avatar={handleStatus(selectedRow.status, selectedRow.id)}
          avatar={<VpnKeyOutlinedIcon color="primary" />}
          title="Set Ignition"
        />
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={Ignition}
                //  onChange={handleIgnitionChange}
                name="Ignition"
                color="primary"
              />
            }
            label={Ignition ? "Ignition Enabled" : "Igniton Disabled"}
          />
        </FormGroup>
        This function will disable or enable the vehicle's ignition remotely if
        the vehicle is traveling below 20 km's per hour .
        <TextField
          keyboardAppearance="dark"
          margin="normal"
          value={password}
          onClick={() => setIsOpen(true)}
          label="Please Click to enter password"
          variant="filled"
          inputProps={{ readOnly: true }}
        />
        {IsOpen && (
          <DialBoxContainer
            handleSubmit={(d) => {
              if (d) {
                setPassword(Number(d));
                handleIgnitionChange(d);
              }
              setIsOpen(false);
            }}
            open={IsOpen}
            setIsOpen={() => setIsOpen(false)}
          />
        )}
      </Card>
    </Grid>
  );
};

export default IgnitionPage;
