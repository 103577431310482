import React, { useState } from "react";
import MainToolbar from "components/MainToolbar";
import {
  Paper,
  Button,
  FormControl,
  Checkbox,
  Chip,
  ListItemText,
  Input,
  Grid,
  ButtonGroup,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "common/components/LocalizationProvider";
import { DialogBox, convertUnicode, useEffectAsync } from "reactHelper";
import makeStyles from "@mui/styles/makeStyles";
import EditCollectionView from "EditCollectionView";
import { prefixString } from "common/util/stringUtils";
import { formatBoolean } from "common/util/formatter";
import MaterialTable from "material-table";
import { AddCircle, Edit, Save } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useCatch } from "reactHelper";
import SelectField from "common/components/SelectField";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
      display: "none",
    },
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  buttonGroup: {
    marginTop: "15px",
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  chip: {
    margin: 2,
    backgroundColor: "#616161",
    color: "#fff",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const NotificationsView = ({ updateTimestamp, onMenuClick }) => {
  const classes = useStyles();
  const t = useTranslation();
  const user = useSelector((state) => state.session.user);
  const [items, setItems] = useState([]);
  const [isNotifcationFormVisible, setIsNotifcationFormVisible] =
    useState(false);
  const [SelectedRow, setSelectedRow] = useState();
  const [IsEdit, setIsEdit] = useState(false);
  const [NotificationObject, setNotificationObject] = useState({
    id: -1,
    always: false,
    type: null,
    calendarId: 0,
    attributes: {},
    notificators: [],
  });

  const [Type, setType] = useState([]);
  const CoreType = [
    { key: "commandResult", value: "Command result" },
    { key: "deviceOnline", value: "Status online" },
    { key: "deviceUnknown", value: "Status unknown" },
    { key: "deviceOffline", value: "Status offline" },
    { key: "deviceInactive", value: "Device inactive" },
    { key: "deviceMoving", value: "Device moving" },
    { key: "deviceStopped", value: "Device stopped" },
    { key: "deviceOverspeed", value: "Speed limit exceeded" },
    { key: "deviceFuelDrop", value: "Fuel drop" },
    { key: "deviceFuelIncrease", value: "Fuel increase" },
    { key: "geofenceEnter", value: "Geofence entered" },
    { key: "geofenceExit", value: "Geofence exited" },
    { key: "alarm", value: "Alarm" },
    { key: "ignitionOn", value: "Ignition on" },
    { key: "ignitionOff", value: "Ignition off" },
    { key: "maintenance", value: "Maintenance required" },
    { key: "textMessage", value: "Text message received" },
    { key: "driverChanged", value: "Driver changed" },
    { key: "media", value: "Media" },
  ];

  const AlarmType = [
    { key: "general", value: "General" },
    { key: "sos", value: "SOS" },
    { key: "vibration", value: "Vibration" },
    { key: "movement", value: "Movement" },
    { key: "lowspeed", value: "Low Speed" },
    { key: "overspeed", value: "Overspeed" },
    { key: "fallDown", value: "Fall Down" },
    { key: "lowPower", value: "Low Power" },
    { key: "lowBattery", value: "Low Battery" },
    { key: "fault", value: "Fault" },
    { key: "powerOff", value: "Power Off" },
    { key: "powerOn", value: "Power On" },
    { key: "door", value: "Door" },
    { key: "lock", value: "Lock" },
    { key: "unlock", value: "Unlock" },
    { key: "geofence", value: "Geofence" },
    { key: "geofenceEnter", value: "Geofence Enter" },
    { key: "geofenceExit", value: "Geofence Exit" },
    { key: "gpsAntennaCut", value: "GPS Antenna Cut" },
    { key: "accident", value: "Accident" },
    { key: "tow", value: "Tow" },
    { key: "idle", value: "Idle" },
    { key: "highRpm", value: "High RPM" },
    { key: "hardAcceleration", value: "Hard Acceleration" },
    { key: "hardBraking", value: "Hard Braking" },
    { key: "hardCornering", value: "Hard Cornering" },
    { key: "laneChange", value: "Lane Change" },
    { key: "fatigueDriving", value: "Fatigue Driving" },
    { key: "powerCut", value: "Power Cut" },
    { key: "powerRestored", value: "Power Restored" },
    { key: "jamming", value: "Jamming" },
    { key: "temperature", value: "Temperature" },
    { key: "parking", value: "Parking" },
    { key: "Bonnet", value: "Bonnet" },
    { key: "footBrake", value: "Foot Brake" },
    { key: "fuelLeak", value: "Fuel Leak" },
    { key: "tampering", value: "Tampering" },
    { key: "removing", value: "Removing" },
  ];

  const [isSelectorOpen, setisSelectorOpen] = useState(false);
  const devices = useSelector((state) => Object.values(state.devices.items));
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const [deviceId, setDeviceId] = useState(selectedDeviceId);

  const handleClick = async () => {
    const deviceItems =
      deviceId !== "all" ? _.filter(devices, { id: deviceId }) : devices;
    let notificationItems = [];

    deviceItems.forEach(async (item, index) => {
      const notificationApiUrl = `/api/notifications?&deviceId=${item.id}`;
      const response = await fetch(notificationApiUrl);
      const deviceName = item.name;
      let newArr = [];
      if (response.ok) {
        newArr = _.map(await response.json(), (o) =>
          _.extend({ vehicleName: deviceName }, o)
        );
      }

      notificationItems = [...notificationItems, ...newArr];
      setItems(
        _.orderBy(
          notificationItems,
          [(notificationItem) => notificationItem.vehicleName.toLowerCase()],
          ["asc"]
        )
      );
    });
  };

  const handleReset = () => {
    setNotificationObject({
      id: -1,
      always: false,
      type: null,
      calendarId: 0,
      attributes: {},
      notificators: [],
    });
    setSelectedRow(null);
    setIsEdit(false);
    setIsNotifcationFormVisible(false);
  };

  useEffectAsync(async () => {
    await getAllNotification();
  }, [updateTimestamp]);

  const formatList = (prefix, value) => {
    if (value) {
      return value
        .split(/[, ]+/)
        .filter(Boolean)
        .map((it) => t(prefixString(prefix, it)))
        .join(", ")
        .replace("Firebase", "Push");
    }
    return "";
  };

  const getChipValue = (selected) => {
    const SelectedKeysValues = [
      {
        key: "web",
        value: "Web",
      },
      {
        key: "mail",
        value: "Mail",
      },
      {
        key: "sms",
        value: "SMS",
      },
      // {
      //   key: "firebase",
      //   value: "Push",
      // },
    ];
    const a = [];
    SelectedKeysValues.map((d) => {
      if (selected.length > 0) {
        const x = selected.map((e) => {
          if (e === d.key) {
            a.push(d);
          }
        });
      }
    });
    return a;
  };

  const getAllNotification = async () => {
    handleClick();
    handleReset();
    const response = await fetch("/api/notifications");
    if (response.ok) {
      const data = await response.json();
      setItems(data);

      const t = CoreType.filter(
        ({ key: id1 }) => !data.some(({ type: id2 }) => id2 === id1)
      );
      setType(CoreType);
    }
  };

  const addNewNotification = () => {
    const NotificationObjects = { ...NotificationObject };
    let str;
    // debugger
    NotificationObjects.notificators.map((e, index) => {
      if (e != null || e != undefined) {
        if (str) {
          str = `${e},${str}`;
        } else {
          str = e;
        }
      }
    });

    NotificationObjects.notificators = str;

    if (NotificationObjects.attributes.alarms === null) {
      delete NotificationObjects.attributes.alarms;
    }
    fetch("/api/notifications", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(NotificationObjects),
    })
      .then((res) => res.json())
      .then((result) => {
        // ResetForm();
        handlePermissionLink(deviceId, result?.id);
        handleClick();
        handleReset();
      });
  };

  const handlePermissionLink = (deviceId, notificationId) => {
    if (deviceId && notificationId) {
      fetch("/api/permissions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          deviceId,
          notificationId,
        }),
      }).then((res) => {
        if (res.ok) {
          setIsEdit(false);
          handleClick();
          setIsNotifcationFormVisible(false);
        }
      });
    }
  };

  const testNotificators = useCatch(async () => {
    await Promise.all(
      NotificationObject.notificators.map(async (notificator) => {
        const response = await fetch(`/api/notifications/test/${notificator}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(NotificationObject),
        });
        if (!response.ok) {
          throw Error(await response.text());
        }
      })
    );
  });

  const EditNotification = () => {
    const notificators = [];
    if (NotificationObject.notificators.indexOf("web") > -1) {
      notificators.push("web");
    }
    if (NotificationObject.notificators.indexOf("mail") > -1) {
      notificators.push("mail");
    }
    if (NotificationObject.notificators.indexOf("sms") > -1) {
      notificators.push("sms");
    }
    // if (NotificationObject.notificators.indexOf("firebase") > -1) {
    //   notificators.push("firebase");
    // }

    const firebaseIndex = NotificationObject.notificators.indexOf("firebase");
    if (firebaseIndex > -1) {
      // only splice array when item is found
      NotificationObject.notificators.splice(firebaseIndex, 1); // 2nd parameter means remove one item only
    }

    const NotificationObjectTemp = {
      id: NotificationObject?.id,
      type: NotificationObject?.type,
      always: NotificationObject?.always,
      notificators: notificators.join(","),
      calendarId: NotificationObject?.calendarId,
      attributes: NotificationObject?.attributes,
    };

    fetch(`/api/notifications/${NotificationObject.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(NotificationObjectTemp),
    })
      .then((res) => res.json())
      .then((result) => {
        // ResetForm();
        handleClick();
        setIsEdit(false);
        setIsNotifcationFormVisible(false);
        handleReset();
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={2}>
            <Paper className={classes.form}>
              <FormControl variant="filled" margin="normal" fullWidth>
                <InputLabel>Vehicle</InputLabel>

                <Select
                  value={deviceId}
                  onChange={(e) => {
                    setDeviceId(e.target.value);
                  }}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  <MenuItem value="all">All</MenuItem>
                  {_.orderBy(
                    devices,
                    [(item) => item.name.toLowerCase()],
                    ["asc"]
                  ).map((device) => (
                    <MenuItem value={device.id}>
                      {convertUnicode(device.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl margin="normal" fullWidth>
                <ButtonGroup
                  color="primary"
                  orientation="vertical"
                  disabled={!deviceId}
                >
                  <Button onClick={() => handleClick(false, true)}>
                    {t("reportShow")}
                  </Button>
                </ButtonGroup>
              </FormControl>
            </Paper>
            <Paper className={classes.form}>
              <ButtonGroup>
                {/* { false ?
                <Button color="secondary" onClick={() => {}} >Close</Button> :
                 */}

                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddCircle />}
                  onClick={() =>
                    setIsNotifcationFormVisible(!isNotifcationFormVisible)
                  }
                >
                  {IsEdit ? "Edit Notification" : "Add Notification "}
                </Button>
              </ButtonGroup>
              <>
                {isNotifcationFormVisible && (
                  <>
                    <FormControl variant="filled" margin="normal" fullWidth>
                      <InputLabel>Select Type</InputLabel>
                      <Select
                        value={NotificationObject.type}
                        onChange={(event) => {
                          setNotificationObject({
                            ...NotificationObject,
                            type: event.target.value,
                          });
                        }}
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                        // value={GeoFencesData.description}
                      >
                        {_.orderBy(
                          Type,
                          [(item) => item.value.toLowerCase()],
                          ["asc"]
                        ).map((type) => (
                          <MenuItem key={type.key} value={type.key}>
                            {type.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {NotificationObject.type === "alarm" ? (
                      <FormControl variant="filled" margin="normal" fullWidth>
                        <InputLabel>Alarms</InputLabel>
                        <Select
                          value={NotificationObject.attributes.alarms}
                          onChange={(e) =>
                            setNotificationObject({
                              ...NotificationObject,
                              attributes: {
                                alarms: e.target.value,
                              },
                            })
                          }
                          MenuProps={{
                            classes: { paper: classes.dropdownStyle },
                          }}
                        >
                          {_.orderBy(
                            AlarmType,
                            [(item) => item.value.toLowerCase()],
                            ["asc"]
                          ).map((type) => (
                            <MenuItem key={type.key} value={type.key}>
                              {type.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      ""
                    )}
                    <FormControl variant="filled" margin="normal" fullWidth>
                      <InputLabel>Channels</InputLabel>
                      <Select
                        value={NotificationObject.notificators}
                        onChange={(e) => {
                          setNotificationObject({
                            ...NotificationObject,
                            notificators: e.target.value,
                          });
                        }}
                        multiple
                        renderValue={(selected) => (
                          <ChipsSelectView selected={getChipValue(selected)} />
                        )}
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                        open={isSelectorOpen}
                        input={
                          <Input
                            onClick={() => {
                              if (!isSelectorOpen) {
                                setisSelectorOpen(true);
                              }
                            }}
                          />
                        }
                      >
                        <MenuItem key="mail" value="mail">
                          <Checkbox
                            checked={
                              NotificationObject.notificators.indexOf("mail") >
                              -1
                            }
                          />
                          <ListItemText primary="Mail" />
                        </MenuItem>
                        <MenuItem key="web" value="web">
                          <Checkbox
                            checked={
                              NotificationObject.notificators.indexOf("web") >
                              -1
                            }
                          />
                          <ListItemText primary="Web" />
                        </MenuItem>
                        <MenuItem key="sms" value="sms">
                          <Checkbox
                            checked={
                              NotificationObject.notificators.indexOf("sms") >
                              -1
                            }
                          />
                          <ListItemText primary="SMS" />
                        </MenuItem>
                        {/* <MenuItem key="firebase" value="firebase">
                          <Checkbox
                            checked={
                              NotificationObject.notificators.indexOf(
                                "firebase"
                              ) > -1
                            }
                          />
                          <ListItemText primary="Push" />
                        </MenuItem> */}
                        <MenuItem>
                          <ButtonGroup
                            color="primary"
                            fullWidth
                            mb={2}
                            style={{
                              marginBottom: "10px",
                            }}
                          >
                            <Button
                              color="primary"
                              onClick={() => setisSelectorOpen(false)}
                            >
                              Ok
                            </Button>
                          </ButtonGroup>
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {user?.attributes?.calendar && (
                      <SelectField
                        value={NotificationObject.calendarId || 0}
                        variant="filled"
                        fullWidth
                        onChange={(event) =>
                          setNotificationObject({
                            ...NotificationObject,
                            calendarId: Number(event.target.value),
                          })
                        }
                        endpoint="/api/calendars"
                        label={t("sharedCalendar")}
                      />
                    )}

                    <FormControl margin="normal" fullWidth>
                      {IsEdit ? (
                        <>
                          <ButtonGroup
                            color="primary"
                            orientation="vertical"
                            disabled={!true}
                            className={classes.buttonGroup}
                          >
                            <Button
                              color="secondary"
                              onClick={() => {
                                testNotificators();
                              }}
                            >
                              Test Notification
                            </Button>
                          </ButtonGroup>
                          <ButtonGroup
                            color="primary"
                            orientation="vertical"
                            disabled={!true}
                            className={classes.buttonGroup}
                          >
                            <Button onClick={() => EditNotification()}>
                              Save Changes
                            </Button>
                          </ButtonGroup>
                        </>
                      ) : (
                        <ButtonGroup
                          color="primary"
                          orientation="vertical"
                          disabled={!true}
                        >
                          <Button onClick={() => addNewNotification()}>
                            Save
                          </Button>
                        </ButtonGroup>
                      )}

                      <ButtonGroup
                        color="primary"
                        orientation="vertical"
                        disabled={!true}
                        className={classes.buttonGroup}
                      >
                        <Button
                          color="secondary"
                          onClick={() => {
                            handleReset();
                          }}
                        >
                          Cancel
                        </Button>
                      </ButtonGroup>
                    </FormControl>
                  </>
                )}
              </>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <MaterialTable
              columns={[
                {
                  title: t("notificationType"),
                  field: "type",
                  render: (rowData) =>
                    CoreType.filter((t) => t.key === rowData.type).length > 0
                      ? CoreType.filter((t) => t.key === rowData.type)[0].value
                      : rowData.type,
                },
                {
                  title: "Vehicle",
                  field: "vehicleName",
                  editable: false,
                  render: (rowData) => rowData.vehicleName,
                },
                {
                  title: t("notificationNotificators"),
                  field: "notificators",
                  render: (rowData) =>
                    formatList("notificator", rowData.notificators),
                },
                {
                  title: "Action",
                  field: "Edit / Delete",
                  render: (rowData) => {
                    const EditCB = (e) => {
                      setNotificationObject({
                        ...NotificationObject,
                        type: rowData.type,
                        id: rowData.id,
                        notificators: rowData.notificators.split(","),
                        calendarId: rowData.calendarId,
                        attributes: {
                          alarms:
                            rowData.type === "alarm"
                              ? rowData.attributes.alarms
                              : [],
                        },
                      });

                      // setCurrentDeviceCoordinates({latitude:Number(latitude),longitude:Number(longitude)});

                      // setGeoFencesData({...GeoFencesData,
                      //   id: rowData.id,
                      //   name: rowData.name,
                      //   //description: rowData.description,
                      //   area: area,
                      //   calendarId: rowData.calendarId,
                      //   attributes: {},
                      // });
                      setSelectedRow(rowData.tableData.id);
                      setIsEdit(true);
                      setIsNotifcationFormVisible(true);

                      e.stopPropagation();
                    };

                    const DeleteCb = async (e) => {
                      const response = await fetch(
                        `/api/notifications/${rowData.id}`,
                        {
                          method: "DELETE",
                        }
                      );
                      if (response) {
                        // formReset();
                        handleClick();
                        handleReset();
                        e.stopPropagation();
                      }
                      e.stopPropagation();
                    };
                    return (
                      <div style={{ display: "flex" }}>
                        <EditView cb={EditCB} />
                        <DeleteView cb={DeleteCb} />
                      </div>
                    );
                  },
                },
              ]}
              data={items}
              title=""
              // icons={tableIcons}
              onRowClick={(event, rowData) => {}}
              options={{
                // exportButton: true,
                headerStyle: {
                  backgroundColor: "#1F1D2B",
                  color: "#FFFFFF",
                },
                sorting: true,
                paging: items.length > 10,
                pageSize: 10,
                pageSizeOptions: [],
                /* maxBodyHeight: calcHeight+'px', */
                defaultSort: "desc",
                rowStyle: (rowData) => ({
                  backgroundColor:
                    SelectedRow === rowData.tableData.id ? "#0d0d0d" : "",
                  display: rowData.id ? "table-row" : "none",
                }),
              }}
              style={{
                color: "#57c7d4",
                // overflowY: "scroll",
                // height: height / 2 - 60,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const EditView = ({ cb }) => {
  const edit = (e) => {
    e.stopPropagation();
    cb(e);
  };
  return (
    <div
      style={{
        padding: "3px",
        margin: "0px 10px",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <EditOutlinedIcon onClick={(e) => edit(e)} />
    </div>
  );
};

const DeleteView = ({ cb }) => {
  const [Dialog, setDialog] = useState({
    isOpen: false,
    handleSuccess: null,
    handleClose: null,
    decription: "Description",
    title: "titile",
    type: "conform",
    closeBtnText: "Close",
    confmBtnText: "Agree",
    handleDisagreeClose: null,
  });
  const deleteRow = (e) => {
    const fun = async () => {
      setDialog({ isOpen: false });
      await cb(e);
    };

    e.stopPropagation();
    setDialog({
      isOpen: true,
      handleClose: () => setDialog({ isOpen: false }),
      handleDisagreeClose: () => {
        setDialog({ isOpen: false });
        // setMileage(ValueBuffer);
      },
      handleSuccess: fun,
      decription: "Are you sure you want to delete this record  ?",
      title: "Alert !!",
      type: "conform",
      confmBtnText: "Yes  ",
      closeBtnText: "No ",
    });
    // setDialog({...Dialog,handleSuccess:() => {cb(e)}})
  };

  return (
    <div
      style={{
        padding: "3px",
        margin: "0px 10px",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <DialogBox
        handleDisagreeClose={Dialog.handleDisagreeClose}
        handleClose={Dialog.handleClose}
        handleSuccess={Dialog.handleSuccess}
        open={Dialog.isOpen}
        decription={Dialog.decription}
        title={Dialog.title}
        type={Dialog.type}
        closeBtnText={Dialog.closeBtnText}
        confmBtnText={Dialog.confmBtnText}
      />
      <DeleteOutlineOutlinedIcon onClick={(e) => deleteRow(e)} />
    </div>
  );
};

const NotificationsPage = () => (
  <>
    <MainToolbar />
    <EditCollectionView
      content={NotificationsView}
      editPath="/settings/notification"
      endpoint="notifications"
    />
  </>
);

const ChipsSelectView = ({ selected }) => {
  const classes = useStyles();

  return (
    <div className={classes.chips}>
      {selected.length > 0 &&
        selected.map(
          (d) =>
            d && <Chip key={d.value} label={d.value} className={classes.chip} />
        )}
    </div>
  );
};
export default NotificationsPage;
