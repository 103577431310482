import { useTranslation as t } from "common/components/LocalizationProvider";
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const RemoveDialog = ({ open, endpoint, itemId, onResult }) => {
  const handleRemove = async () => {
    const response = await fetch(`/api/${endpoint}/${itemId}`, {
      method: "DELETE",
    });
    if (response.ok) {
      onResult(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onResult(false);
      }}
    >
      <DialogContent>
        <DialogContentText>{t("sharedRemoveConfirm")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleRemove}>
          {t("sharedRemove")}
        </Button>
        <Button autoFocus onClick={() => onResult(false)}>
          {t("sharedCancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;
