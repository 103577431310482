import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import MainToolbar from "components/MainToolbar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "common/components/LocalizationProvider";
import {
  Stack,
  Checkbox,
  Divider,
  Chip,
  Grid,
  Paper,
  FormLabel,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  Switch,
  Select,
  Menu,
  MenuItem,
  Button,
  TextField,
  ButtonGroup,
  FormGroup,
  Typography,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { AddCircle, Edit, Save, Send } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import usePositionAttributes from "../common/attributes/usePositionAttributes";
import _ from "lodash";
import {
  CustomAlertBox,
  convertUnicode,
  DialogBox,
  useEffectAsync,
} from "reactHelper";
import EditCollectionView from "../EditCollectionView";

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    // height: "100%",
    // display: "flex",
    // flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& .MuiIcon-colorPrimary": {
      color: "#4ac7c9",
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
      display: "none",
    },
  },
  warningroot: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& .MuiIcon-colorPrimary": {
      color: "#4ac7c9",
    },
  },
  chip: {
    margin: 2,
    backgroundColor: "#616161",
    color: "#fff",
  },
  tablechip: {
    margin: 1,
    backgroundColor: "#616161",
    color: "#fff",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
    marginBottom: "10px",
  },
  heading: {
    color: "#00ac69",
  },
  fieldsetWithPadding: {
    padding: "0 25px",
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
}));

const DevicesViewNew = ({ updateTimestamp, onMenuClick }) => {
  const classes = useStyles();
  const t = useTranslation();

  const [items, setItems] = useState([]);
  const [groups, setGroups] = useState([]);
  const [Dialog, setDialog] = useState({
    isOpen: false,
    handleSuccess: null,
    handleClose: null,
    decription: null,
    title: null,
    type: "no",
    closeBtnText: "Close",
    confmBtnText: "Agree",
    handleDisagreeClose: null,
  });

  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const [SelectedRow, setSelectedRow] = useState(null);

  const [groupId, setGroupId] = useState("all");
  const [groupDevices, setGroupDevices] = useState([]);
  const [assignedDevices, setAssignedDevices] = useState([]);

  const [showUnassignedVehicles, setShowUnassignedVehicles] = useState(false);
  const [vehicles, setVehicles] = useState([]);

  const [open, setOpen] = React.useState({
    Isshow: false,
    SuccesCBWithData: null,
    name: null,
  });

  const [openWarning, setOpenWarning] = useState(false);
  const [warning, setWarning] = useState(null);
  const handleWarningClose = () => {
    setOpenWarning(false);
  };
  let deviceStatus;

  const handelSuccessClose = () => {
    open.SuccesCBWithData();
    handleClose();
  };

  const handleClose = () => {
    setOpen({ Isshow: false });
  };

  const getAllDevice = async () => {
    const response = await fetch("/api/devices?all=true");
    if (response.ok) {
      setItems(await response.json());
    }
  };

  const getAllGroups = async () => {
    const response = await fetch("/api/groups");
    if (response.ok) {
      setGroups(await response.json());
    }
  };

  const getGroupDevices = () => {
    let groupsId = _.map(groups, "id");
    let assignedDevicesTemp = _.filter(items, (o) => {
      return groupsId.includes(o?.groupId);
    });

    let assignedDevicesId = _.map(assignedDevicesTemp, "id");
    setAssignedDevices(assignedDevicesId);

    let groupDevicesTemp = _.filter(items, { groupId: groupId });
    let groupDevicesId = _.map(groupDevicesTemp, "id");
    setGroupDevices(groupDevicesId);
  };

  const handleGroupDeviceChange = async (rowData, assigned) => {
    if (groupId != "all") {
      fetch(`/api/devices/${rowData?.id}`)
        .then((response) => response.json())
        .then((deviceData) => {
          const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              ...deviceData,
              groupId: assigned ? groupId : null,
            }),
          };
          fetch(`/api/devices/${rowData?.id}`, requestOptions)
            .then((response) => response.json())
            .then(async (responseAPI) => {
              await getAllDevice();
            });
        });
    }
  };

  useEffect(() => {
    if (items.length) {
      let vehiclesTemp = _.orderBy(
        items,
        [(item) => item.name.toLowerCase()],
        ["asc"]
      );

      getGroupDevices();
      setVehicles(vehiclesTemp);
    }
  }, [items]);

  useEffectAsync(async () => {
    await getAllDevice();
    await getAllGroups();
  }, [updateTimestamp]);

  return (
    <div className={classes.root}>
      <DialogBox
        handleDisagreeClose={Dialog.handleDisagreeClose}
        handleClose={Dialog.handleClose}
        handleSuccess={Dialog.handleSuccess}
        open={Dialog.isOpen}
        decription={Dialog.decription}
        title={Dialog.title}
        type={Dialog.type}
        closeBtnText={Dialog.closeBtnText}
        confmBtnText={Dialog.confmBtnText}
      />
      <AlertDialog
        OnSuccess={handelSuccessClose}
        closeCB={handleClose}
        open={open.Isshow}
        name={open.name}
      />
      <WarningDialog
        closeWarningCB={handleWarningClose}
        openWarning={openWarning}
        warning={warning}
      />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={3}
            lg={2}
            style={{ height: "calc(100vh - 40px)", overflow: "scroll" }}
          >
            <Paper className={classes.form}>
              <FormControl margin="normal" fullWidth>
                <>
                  <FormControl variant="filled" margin="normal" fullWidth>
                    <InputLabel>Groups</InputLabel>
                    <Select
                      value={groupId}
                      onChange={(e) => {
                        setGroupId(e.target.value);
                        getGroupDevices();
                      }}
                      MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    >
                      <MenuItem value="all">Select Group</MenuItem>
                      {_.orderBy(
                        groups,
                        [(item) => item.name.toLowerCase()],
                        ["asc"]
                      ).map((group) => (
                        <MenuItem value={group.id}>{group.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            {loader ? (
              <LinearProgress
                variant="buffer"
                value={progress}
                valueBuffer={buffer}
              />
            ) : (
              ""
            )}

            {items ? (
              <MaterialTable
                columns={[
                  {
                    title: "Select",
                    field: "",
                    render: (rowData) => (
                      <Checkbox
                        checked={assignedDevices.includes(rowData?.id)}
                        disabled={
                          (!!rowData?.groupId && rowData?.groupId != groupId) ||
                          groupId == "all"
                        }
                        onChange={(event) => {
                          let groupDevicesIndex = groupDevices.indexOf(
                            rowData?.id
                          );
                          if (groupDevicesIndex > -1) {
                            let groupDevicesTemp = groupDevices;
                            groupDevicesTemp.splice(groupDevicesIndex, 1);
                            setGroupDevices([...groupDevicesTemp]);
                            handleGroupDeviceChange(rowData, false);
                          } else {
                            let groupDevicesTemp = [
                              ...groupDevices,
                              rowData?.id,
                            ];
                            setGroupDevices([...groupDevicesTemp]);
                            handleGroupDeviceChange(rowData, true);
                          }
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    ),
                  },
                  {
                    title: "Vehicle Name",
                    field: "name",
                    render: (rowData) => rowData.name,
                  },
                  {
                    title: "Group", // t('deviceIdentifier'),
                    field: "groupId",
                    render: (rowData) => {
                      let group = _.find(groups, { id: rowData.groupId });
                      return <>{group?.name}</>;
                    },
                  },
                ]}
                // editable={{

                //   onRowUpdate: (newData, oldData) =>
                //     new Promise((resolve) => {
                //      // handleRowUpdate(newData, oldData, resolve);
                // }),
                // onRowDelete: (oldData) =>
                //   new Promise((resolve) => {
                //     //handleRowDelete(oldData, resolve)
                //   }),
                // }}
                data={vehicles}
                title="Vehicles"
                // icons={tableIcons}
                options={{
                  actionsColumnIndex: -1,
                  // exportButton: true,
                  cellStyle: {
                    padding: "6px 16px",
                    borderBottom: "1px solid #3f403f",
                  },
                  actionsCellStyle: {
                    borderBottom: "1px solid #3f403f",
                  },
                  headerStyle: {
                    backgroundColor: "#1F1D2B",
                    color: "#FFFFFF",
                    borderBottom: "2px solid #cbcad0",
                  },
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      SelectedRow === rowData.tableData.id ? "#0d0d0d" : "",
                    display: rowData.id ? "table-row" : "none",
                  }),

                  sorting: true,
                  paging: items.length > 10,
                  pageSize: 10,
                  pageSizeOptions: [],
                  /* maxBodyHeight: calcHeight+'px', */
                  defaultSort: "asc",
                }}
                style={{ color: "#57c7d4" }}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const VehicleAssignedGroup = () => (
  <>
    <MainToolbar />
    <EditCollectionView
      content={DevicesViewNew}
      editPath="/settings/vehicleAssignedGroup"
      endpoint="devices"
    />
  </>
);

const ChipsSelectView = ({ selected, type }) => {
  const classes = useStyles();

  return (
    <div className={classes.chips}>
      {selected.length > 0 &&
        selected.map(
          (d) =>
            d && (
              <Chip
                key={d.id}
                label={d.name}
                className={type == "table" ? classes.tablechip : classes.chip}
              />
            )
        )}
    </div>
  );
};

const AlertDialog = ({ open, OnSuccess, closeCB, name }) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={closeCB}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.root}
      >
        <DialogTitle id="alert-dialog-title">Confirm ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="secondary">
            Are you sure you want to delete Device {name}, this cannot be
            reversed?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCB} color="primary">
            No
          </Button>
          <Button onClick={OnSuccess} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const WarningDialog = ({ openWarning, warning, closeWarningCB }) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={openWarning}
        onClose={closeWarningCB}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.warningroot}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="secondary">
            {warning}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeWarningCB} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VehicleAssignedGroup;
