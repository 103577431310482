import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

import { useTranslation } from "common/components/LocalizationProvider";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  FormControl,
  Container,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import MainToolbar from "components/MainToolbar";
import { sessionActions } from "store";
import EditAttributesView from "components/attributes/EditAttributesView";
import useUserAttributes from "common/attributes/useUserAttributes";
import useDeviceAttributes from "common/attributes/useDeviceAttributes";
import useServerAttributes from "common/attributes/useServerAttributes";
import EditAttributesAccordion from "settings/components/EditAttributesAccordion";
import useCommonUserAttributes from "common/attributes/useCommonUserAttributes";
import useCommonDeviceAttributes from "common/attributes/useCommonDeviceAttributes";
import useMapStyles from "map/core/useMapStyles";
import { map } from "map/core/MapView";
import SelectField from "common/components/SelectField";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#000",
  },
  chevron: {
    color: "#fff",
  },
  dialog: {},
  container: {
    marginTop: theme.spacing(2),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  summary: {
    background: "rgb(31, 29, 43)",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-evenly",
    "& > *": {
      flexBasis: "33%",
    },
  },
  details: {
    flexDirection: "column",
    background: "rgb(31, 29, 43)",
  },
}));

const ServerPage = () => {
  const navigate = useNavigate();
  const t = useTranslation();
  const mapStyles = useMapStyles();
  const dispatch = useDispatch();
  const classes = useStyles();
  const commonUserAttributes = useCommonUserAttributes(t);
  const user = useSelector((state) => state.session.user);
  const userAttributes = useUserAttributes(t);
  const deviceAttributes = useDeviceAttributes(t);
  const serverAttributes = useServerAttributes(t);
  const commonDeviceAttributes = useCommonDeviceAttributes(t);
  const [availableMaps, setAvailableMaps] = useState([]);
  const item = useSelector((state) => state.session.server);

  const setItem = (updatedItem) =>
    dispatch(sessionActions.updateServer(updatedItem));

  const handleSave = async () => {
    const response = await fetch("/api/server", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(item),
    });

    if (response.ok) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (mapStyles.length && user.attributes.activeMapStyles) {
      const activeStyles = user.attributes.activeMapStyles?.split(",");
      const filtered_ids = _.filter(mapStyles, (p) =>
        _.includes(activeStyles, p.id)
      );
      setAvailableMaps(filtered_ids);
    } else {
      setAvailableMaps(mapStyles);
    }

    mapStyles.filter((style) => style.available);
  }, [mapStyles]);

  return (
    <>
      <MainToolbar />
      <Container maxWidth="xs" className={classes.container}>
        {item && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1">
                  {t("sharedPreferences")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TextField
                  value={item.mapUrl || ""}
                  margin="normal"
                  variant="filled"
                  fullWidth
                  onChange={(event) =>
                    setItem({ ...item, mapUrl: event.target.value })
                  }
                  label={t("mapCustomLabel")}
                />
                <TextField
                  value={item.overlayUrl || ""}
                  margin="normal"
                  variant="filled"
                  fullWidth
                  onChange={(event) =>
                    setItem({ ...item, overlayUrl: event.target.value })
                  }
                  label={t("mapOverlayCustom")}
                />
                <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel>{t("mapDefault")}</InputLabel>
                  <Select
                    label={t("mapDefault")}
                    value={item.map || "locationIqStreets"}
                    onChange={(e) => setItem({ ...item, map: e.target.value })}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                  >
                    {availableMaps.map((style) => (
                      <MenuItem key={style.id} value={style.id}>
                        {style.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel>{t("settingsCoordinateFormat")}</InputLabel>
                  <Select
                    label={t("settingsCoordinateFormat")}
                    value={item.coordinateFormat || "dd"}
                    onChange={(event) =>
                      setItem({ ...item, coordinateFormat: event.target.value })
                    }
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                  >
                    <MenuItem value="dd">{t("sharedDecimalDegrees")}</MenuItem>
                    <MenuItem value="ddm">
                      {t("sharedDegreesDecimalMinutes")}
                    </MenuItem>
                    <MenuItem value="dms">
                      {t("sharedDegreesMinutesSeconds")}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel>{t("settingsSpeedUnit")}</InputLabel>
                  <Select
                    label={t("settingsSpeedUnit")}
                    value={item.attributes.speedUnit || "kn"}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        attributes: {
                          ...item.attributes,
                          speedUnit: e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value="kn">{t("sharedKn")}</MenuItem>
                    <MenuItem value="kmh">{t("sharedKmh")}</MenuItem>
                    <MenuItem value="mph">{t("sharedMph")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel>{t("settingsDistanceUnit")}</InputLabel>
                  <Select
                    label={t("settingsDistanceUnit")}
                    value={item.attributes.distanceUnit || "km"}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        attributes: {
                          ...item.attributes,
                          distanceUnit: e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value="km">{t("sharedKm")}</MenuItem>
                    <MenuItem value="mi">{t("sharedMi")}</MenuItem>
                    <MenuItem value="nmi">{t("sharedNmi")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel>{t("settingsAltitudeUnit")}</InputLabel>
                  <Select
                    label={t("settingsAltitudeUnit")}
                    value={item.attributes.altitudeUnit || "m"}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        attributes: {
                          ...item.attributes,
                          altitudeUnit: e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value="m">{t("sharedMeters")}</MenuItem>
                    <MenuItem value="ft">{t("sharedFeet")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel>{t("settingsVolumeUnit")}</InputLabel>
                  <Select
                    label={t("settingsVolumeUnit")}
                    value={item.attributes.volumeUnit || "ltr"}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        attributes: {
                          ...item.attributes,
                          volumeUnit: e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value="ltr">{t("sharedLiter")}</MenuItem>
                    <MenuItem value="usGal">{t("sharedUsGallon")}</MenuItem>
                    <MenuItem value="impGal">{t("sharedImpGallon")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <SelectField
                    value={item.attributes.timezone || ""}
                    variant="filled"
                    margin="normal"
                    emptyValue=""
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        attributes: {
                          ...item.attributes,
                          timezone: e.target.value,
                        },
                      })
                    }
                    endpoint="/api/server/timezones"
                    keyGetter={(it) => it}
                    titleGetter={(it) => it}
                    label={t("sharedTimezone")}
                  />
                </FormControl>
                <TextField
                  variant="filled"
                  margin="normal"
                  fullWidth
                  value={item.poiLayer || ""}
                  onChange={(event) =>
                    setItem({ ...item, poiLayer: event.target.value })
                  }
                  label={t("mapPoiLayer")}
                />
                <TextField
                  variant="filled"
                  margin="normal"
                  fullWidth
                  value={item.announcement || ""}
                  onChange={(event) =>
                    setItem({ ...item, announcement: event.target.value })
                  }
                  label={t("serverAnnouncement")}
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.twelveHourFormat}
                        onChange={(event) =>
                          setItem({
                            ...item,
                            twelveHourFormat: event.target.checked,
                          })
                        }
                      />
                    }
                    label={t("settingsTwelveHourFormat")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.forceSettings}
                        onChange={(event) =>
                          setItem({
                            ...item,
                            forceSettings: event.target.checked,
                          })
                        }
                      />
                    }
                    label={t("serverForceSettings")}
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1">
                  {t("sharedLocation")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TextField
                  variant="filled"
                  type="number"
                  margin="normal"
                  fullWidth
                  value={item.latitude || 0}
                  onChange={(event) =>
                    setItem({ ...item, latitude: Number(event.target.value) })
                  }
                  label={t("positionLatitude")}
                />
                <TextField
                  variant="filled"
                  fullWidth
                  type="number"
                  margin="normal"
                  value={item.longitude || 0}
                  onChange={(event) =>
                    setItem({ ...item, longitude: Number(event.target.value) })
                  }
                  label={t("positionLongitude")}
                />
                <TextField
                  variant="filled"
                  type="number"
                  margin="normal"
                  fullWidth
                  value={item.zoom || 0}
                  onChange={(event) =>
                    setItem({ ...item, zoom: Number(event.target.value) })
                  }
                  label={t("serverZoom")}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    const { lng, lat } = map.getCenter();
                    setItem({
                      ...item,
                      latitude: Number(lat.toFixed(6)),
                      longitude: Number(lng.toFixed(6)),
                      zoom: Number(map.getZoom().toFixed(1)),
                    });
                  }}
                >
                  {t("mapCurrentLocation")}
                </Button>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1">
                  {t("sharedPermissions")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.registration}
                        onChange={(event) =>
                          setItem({
                            ...item,
                            registration: event.target.checked,
                          })
                        }
                      />
                    }
                    label={t("serverRegistration")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.readonly}
                        onChange={(event) =>
                          setItem({ ...item, readonly: event.target.checked })
                        }
                      />
                    }
                    label={t("serverReadonly")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.deviceReadonly}
                        onChange={(event) =>
                          setItem({
                            ...item,
                            deviceReadonly: event.target.checked,
                          })
                        }
                      />
                    }
                    label={t("userDeviceReadonly")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.limitCommands}
                        onChange={(event) =>
                          setItem({
                            ...item,
                            limitCommands: event.target.checked,
                          })
                        }
                      />
                    }
                    label={t("userLimitCommands")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.disableReports}
                        onChange={(event) =>
                          setItem({
                            ...item,
                            disableReports: event.target.checked,
                          })
                        }
                      />
                    }
                    label={t("userDisableReports")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.fixedEmail}
                        onChange={(e) =>
                          setItem({ ...item, fixedEmail: e.target.checked })
                        }
                      />
                    }
                    label={t("userFixedEmail")}
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <EditAttributesAccordion
              attributes={item.attributes}
              className={classes.summary}
              setAttributes={(attributes) => setItem({ ...item, attributes })}
              definitions={{
                ...commonUserAttributes,
                ...commonDeviceAttributes,
                ...serverAttributes,
              }}
            />
          </>
        )}
        <FormControl fullWidth margin="normal">
          <div className={classes.buttons}>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              {t("sharedCancel")}
            </Button>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={handleSave}
            >
              {t("sharedSave")}
            </Button>
          </div>
        </FormControl>
      </Container>
    </>
  );
};

export default ServerPage;
