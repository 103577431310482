import MaterialTable from "material-table";
import React, { useState } from "react";
import { useEffectAsync } from "reactHelper";
import { useTranslation } from "common/components/LocalizationProvider";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import LinearProgress from "@mui/material/LinearProgress";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& table th:first-child span": {
      display: "none",
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      border: "1px solid #3f403f",
    },
  },
}));

const DriverTabPage = () => {
  const [items, setItems] = useState([]);
  const classes = useStyles();
  const t = useTranslation();

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const userId = useSelector(
    (state) => state.session.user && state.session.user.id
  );
  const [loader, setLoader] = useState(false);

  useEffectAsync(async () => {
    if (selectedDeviceId != 0) {
      await getAllDriver();
    }
  }, [selectedDeviceId]);

  const getAllDriver = async () => {
    const response = await fetch("/api/drivers");
    if (response.ok) {
      const Drivers = await response.json();
      Drivers.map((driver) => ({ ...driver, isChecked: false }));
      setItems(Drivers);
      if (selectedDeviceId && Drivers.length > 0) {
        setLoader(true);

        const response = await fetch(
          `/api/drivers?deviceId=${selectedDeviceId}`
        );
        if (response.ok) {
          const selectedDevice = await response.json();
          const deviceItems = [];
          selectedDevice.forEach((device) => {
            Drivers.map((item, index) => {
              deviceItems[index] = item;
              if (device.id == item.id) {
                deviceItems[index].tableData = {
                  ...item.tableData,
                  checked: true,
                };
              }
            });
            setItems(deviceItems);
          });
          setLoader(false);
        }
      }
    }
  };

  const handleCheckboxClick = (rowData) => {
    if (!rowData.tableData.checked) {
      setTimeout(() => {
        const resp = fetch("/api/commands/send", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId: selectedDeviceId,
            type: "custom",
            textChannel: true,
            attributes: {
              data: `0000,145,#00000${
                rowData.uniqueId.length > 7
                  ? rowData.uniqueId
                  : rowData.uniqueId
                // : "0" + rowData.uniqueId
              }`,
            },
          }),
        });
      }, 1000);

      // unlink code
      const response = fetch("/api/permissions", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          // "managedUserId": userId,
          deviceId: selectedDeviceId,
          driverId: rowData.id,
        }),
      });

      if (response.ok) {
        getAllDriver();
      }
    } else {
      setTimeout(() => {
        const resp = fetch("/api/commands/send", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId: selectedDeviceId,
            type: "custom",
            textChannel: true,
            attributes: {
              data: `0000,144,#00000${
                rowData.uniqueId.length > 7
                  ? rowData.uniqueId
                  : rowData.uniqueId
                // : "0" + rowData.uniqueId
              }`,
            },
          }),
        });
      }, 1000);

      const response = fetch("/api/permissions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          // "managedUserId": userId,
          deviceId: selectedDeviceId,
          driverId: rowData.id,
        }),
      });

      if (response.ok) {
        getAllDriver();
      }
      // Link COde
    }
    // rowData.tableData.checked = true
  };
  return (
    <div className={classes.root}>
      {loader ? <LinearProgress variant="buffer" /> : ""}

      <div style={{ overflowY: "scroll", maxHeight: window.innerHeight - 400 }}>
        <MaterialTable
          class
          adjustForCheckbox={false}
          displaySelectAll={false}
          showTextRowsSelected={false}
          columns={[
            {
              title: t("sharedDriver"),
              field: "name",
              render: (rowData) => rowData.name,
            },
            {
              title: "Tag number", // t('deviceIdentifier'),
              field: "uniqueId",
              render: (rowData) => rowData.uniqueId,
            },
          ]}
          title=""
          data={
            loader
              ? []
              : _.orderBy(items, [(item) => item.name.toLowerCase()], ["asc"])
          }
          options={{
            selection: true,
            actionsColumnIndex: -1,
            // exportButton: true,
            headerStyle: {
              backgroundColor: "#1F1D2B",
              color: "#FFFFFF",
            },
            sorting: true,
            paging: false,
            /* maxBodyHeight: calcHeight+'px', */
            defaultSort: "asc",
            showTextRowsSelected: false,
          }}
          style={{ color: "#57c7d4" }}
          onSelectionChange={(evt, rowData) => {
            handleCheckboxClick(rowData);
          }}
        />
      </div>
    </div>
  );
};

export default DriverTabPage;
