import React, { useState, useEffect } from "react";

import {
  Paper,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  TextField,
  ButtonGroup,
  createTheme,
  ThemeProvider,
  Typography,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import { useTranslation } from "common/components/LocalizationProvider";
import {
  formatDistance,
  formatHours,
  formatDate,
  formatVolume,
  formatDateAustralian,
  formatTime,
  formatPosition,
} from "common/util/formatter";

import { useAttributePreference, usePreference } from "common/util/preferences";
import MainToolbar from "components/MainToolbar";
import moment from "moment";
import MaterialReactTable from "material-react-table";
import LinearProgress from "@mui/material/LinearProgress";

import { useSelector, useDispatch } from "react-redux";
import EventIcon from "@mui/icons-material/Event";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import AddressValue from "common/components/AddressValue";

import { useLocation } from "react-router-dom";
import { devicesActions } from "store";

import { convertUnicode } from "reactHelper";
import StopReportMapContainer from "components/maps/StopReportMapContainer";

import useWindowSize from "common/util/useWindowSize";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "0.4em",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
        outline: "1px solid slategrey",
        border: "none !important",
      },
    },
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& .MuiMenu-list": {
      // backgroundColor:'#303030'
      backgroundColor: "#12151e", // '#1F1D2B'
    },
    "& .MuiList-padding": {
      backgroundColor: "#12151e", // '#1F1D2B'
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
  },
  tabel: {
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  customDatePicker: {
    "& label": {
      color: "#fff",
    },
    "& input": {
      color: "#fff",
    },
  },
  customDatePickerDialog: {
    "& .MuiDialogContent-root": {
      background: "#0f0e15 !important",
      color: "red",
    },
    "& .MuiDialog-paperScrollPaper": {
      background: "#1F1D2B",
      color: "#fff",
    },
    "& .MuiDayPicker-weekDayLabel": {
      color: "#fff",
    },
    "& .MuiPickersCalendarHeader-switchViewButton": {
      color: "#fff",
    },
    "& .MuiPickersArrowSwitcher-button": {
      color: "#fff",
    },
    "& .MuiInputLabel-formControl": {
      color: "#fff",
    },
    "& .MuiPickersToolbar-root": {
      background: "#3f51b5",
      color: "#fff",
    },
    "& .MuiPickersToolbar-root span": {
      color: "#fff",
    },
    "& .PrivatePickersToolbarText-root": {
      color: "#fff",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#f50057",
    },
    "& .MuiTabs-flexContainer": {
      background: "#3f51b5",
      color: "#fff",
    },
    "& .MuiTabs-flexContainer button": {
      color: "#fff",
    },
    "& .MuiDialogActions-root button": {
      color: "#3f51b5",
    },
    "& .MuiPickersDay-root": {
      color: "#fff",
      background: "none",
    },
    "& .Mui-selected.MuiPickersDay-root": {
      color: "#fff",
      background: "#3f51b5",
    },
    "& .MuiClock-amButton": {
      color: "#fff",
    },
    "& .MuiClock-pmButton": {
      color: "#fff",
    },
    "& .MuiClockPicker-root": {
      background: "#0f0e15",
    },
    "& .MuiClockNumber-root": {
      color: "#fff",
    },
    "& .MuiClockNumber-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiCalendarOrClockPicker-mobileKeyboardInputView .MuiInputBase-root": {
      color: "#fff",
    },
    "& .MuiCalendarOrClockPicker-mobileKeyboardInputView label": {
      color: "#fff",
    },
  },
  form: {
    padding: theme.spacing(1, 2, 2),
    backgroundColor: "#12151e", // '#1F1D2B'
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
}));

const materialTheme = createTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        color: "#fff",
      },
    },
    MuiIconButton: {
      root: {
        color: "#2436ac",
      },
    },
    MuiInputBase: {
      input: {
        color: "#fff",
      },
      root: {
        color: "#fff",
        "&$focused": {
          caretColor: "#fff",
        },
        "&$error": {
          caretColor: "#fff",
        },
        "&:not($disabled) .MuiInputAdornment-root": {
          color: "#fff",
        },
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: "#000",
      },
    },
  },
});

const columnsArray = [
  ["startTime", "reportStartTime"],
  ["startOdometer", "positionOdometer"],
  ["address", "positionAddress"],
  ["endTime", "reportEndTime"],
  ["duration", "reportDuration"],
  ["engineHours", "reportEngineHours"],
  ["spentFuel", "reportSpentFuel"],
];
const columnsMap = new Map(columnsArray);

const StopReportPage = ({ children, filter }) => {
  const t = useTranslation();
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const devices = useSelector((state) => Object.values(state.devices.items));
  const [deviceId, setDeviceId] = useState(selectedDeviceId);
  const [period, setPeriod] = useState("today");
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [columns, setColumns] = useState([]);
  const [selectedColumnsAttributes, setSelectedColumnsAttributes] = useState(
    []
  );

  const distanceUnit = useAttributePreference("distanceUnit");
  const [items, setItems] = useState([]);
  const [newitems, setnewItems] = useState([]);
  const [loader, setLoader] = useState(false);

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const progressRef = React.useRef(() => {});
  const [activeHeight, setActiveHeight] = useState(null);

  const windowSize = useWindowSize();

  const classes = useStyles();
  const dispatch = useDispatch();

  const location = useLocation();
  const volumeUnit = useAttributePreference("volumeUnit");
  const hours12 = usePreference("twelveHourFormat");
  const [height, setheight] = useState(window.innerHeight);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [setRowObject, setSetRowObject] = useState();

  const handleSubmit = async (deviceId, from, to, mail, headers) => {
    setLoader(true);
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };

    const found_pos = devices.find((element) => element.id == deviceId);
    let deviceName = "";
    if (found_pos) {
      deviceName = found_pos.name;
    }
    const query = new URLSearchParams({ deviceId, from, to, mail });
    const response = await fetch(`/api/reports/stops?${query.toString()}`, {
      headers,
    });
    if (response.ok) {
      setLoader(false);
      const contentType = response.headers.get("content-type");
      if (contentType) {
        if (contentType === "application/json") {
          setItems(await response.json());

          /* const api_data = await response.json();
          const copied_api_data = [...api_data];

          copied_api_data.map(item_data => {
            fetch(`https://icu-protection.com/reverse/api/v1.0/reverse?lat=${item_data.latitude}&long=${item_data.longitude}`)
            .then(res => res.json())
            .then(
            (result) => {
              item_data.addressNew = result.data ? result.data :'';
            },
            (error) => {
              item_data.addressNew = '';
            }
          )
          });

          setLoader(true);
          window.setTimeout(() => {
            progressRef.current();
            if(copied_api_data){
              setItems(copied_api_data);
              setLoader(false);
            }
          }, 2000); */
        } else {
          // window.location.assign(window.URL.createObjectURL(await response.blob()));
          const url = window.URL.createObjectURL(await response.blob());
          const a = document.createElement("a");
          a.href = url;
          a.download = `${deviceName}.xlsx`;
          a.click();
        }
      }
    }
  };

  const handleClick = (mail, json) => {
    let selectedFrom;
    let selectedTo;
    switch (period) {
      case "today":
        selectedFrom = moment().startOf("day");
        selectedTo = moment().endOf("day");
        break;
      case "yesterday":
        selectedFrom = moment().subtract(1, "day").startOf("day");
        selectedTo = moment().subtract(1, "day").endOf("day");
        break;
      case "thisWeek":
        selectedFrom = moment().startOf("week");
        selectedTo = moment().endOf("week");
        break;
      case "previousWeek":
        selectedFrom = moment().subtract(1, "week").startOf("week");
        selectedTo = moment().subtract(1, "week").endOf("week");
        break;
      case "thisMonth":
        selectedFrom = moment().startOf("month");
        selectedTo = moment().endOf("month");
        break;
      case "previousMonth":
        selectedFrom = moment().subtract(1, "month").startOf("month");
        selectedTo = moment().subtract(1, "month").endOf("month");
        break;
      default:
        selectedFrom = from;
        selectedTo = to;
        break;
    }

    const accept = json
      ? "application/json"
      : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    location.pathname == "/reports/stop"
      ? handleSubmit(
          deviceId,
          selectedFrom.toISOString(),
          selectedTo.toISOString(),
          mail,
          { Accept: accept }
        )
      : handleSubmit(
          selectedDeviceId,
          selectedFrom.toISOString(),
          selectedTo.toISOString(),
          mail,
          { Accept: accept }
        );
  };

  useEffect(() => {
    if (location.pathname == "/reports/stop") {
      // dispatch(devicesActions.select({ id: null }));
    } else {
      // dispatch(devicesActions.select({ id: selectedDeviceId }));
      // setDeviceId(selectedDeviceId);
      // console.log('deviceId-useeffect', deviceId);
      handleClick(false, true);
    }
  }, [selectedDeviceId]);

  useEffect(() => {
    const { height } = windowSize;
    const activeHeight = Math.floor(height / 2 - 196);
    if (activeHeight <= 115) {
      setActiveHeight(150);
    } else if (activeHeight >= 250) {
      setActiveHeight(activeHeight - 20);
    } else {
      return setActiveHeight(Math.floor(height / 2 - 196));
    }
  }, [windowSize]);

  useEffect(() => {
    // let defaultColumns = [
    //   {
    //     header: "Start Time",
    //     accessorKey: "startTime",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatDateAustralian(row.startTime),
    //   },
    //   {
    //     header: "Odometer",
    //     accessorKey: "startOdometer",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatDistance(row.startOdometer, distanceUnit, t),
    //   },
    //   {
    //     header: "End Time",
    //     accessorKey: "endTime",
    //     Cell: ({ renderedCellValue, row }) => formatDateAustralian(row.endTime),
    //   },
    //   {
    //     header: "Address",
    //     accessorKey: "address",
    //     Cell: ({ renderedCellValue, row }) => row.address,
    //   },
    //   {
    //     header: "Duration",
    //     accessorKey: "duration",
    //     Cell: ({ renderedCellValue, row }) => formatHours(row.duration),
    //   },
    // ];

    let defaultColumns = [
      "startOdometer",
      "address",
      "endTime",
      "duration",
      "startTime",
    ].map((item) => {
      let itemObj = {
        header: t(columnsMap.get(item)),
        accessorKey: item,
        Cell: ({ renderedCellValue, row }) => {
          return <>{formatValue(row.original, item)}</>;
        },
      };
      return itemObj;
    });

    let selectedColumns = selectedColumnsAttributes.map((item) => {
      let itemObj = {
        header: t(columnsMap.get(item)),
        accessorKey: item,
        Cell: ({ renderedCellValue, row }) => {
          return <>{formatValue(row.original, item)}</>;
        },
      };

      return itemObj;
    });

    let columnsTemp = selectedColumnsAttributes.length
      ? selectedColumns
      : defaultColumns;
    setColumns(columnsTemp);
  }, [selectedColumnsAttributes]);

  useEffect(() => {
    let device = _.find(devices, { id: deviceId });
    if (device && !!device?.attributes?.stopsReportColumns) {
      setSelectedColumnsAttributes(
        device?.attributes?.stopsReportColumns.split(",")
      );
    } else {
      setSelectedColumnsAttributes([]);
    }
  }, [deviceId]);

  const formatValue = (item, key) => {
    switch (key) {
      case "startTime":
      case "endTime":
        return formatPosition(item[key], "fixTime");
      case "startOdometer":
        return formatDistance(item[key], distanceUnit, t);
      case "duration":
        return formatHours(item[key]);
      case "engineHours":
        return formatHours(item[key]);
      case "spentFuel":
        return formatVolume(item[key], volumeUnit, t);
      case "address":
        return (
          <AddressValue
            latitude={item.latitude}
            longitude={item.longitude}
            originalAddress={item[key]}
          />
        );
      default:
        return item[key];
    }
  };

  return location.pathname == "/reports/stop" ? (
    <div className={classes.root}>
      <MainToolbar />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={2}>
            <Paper className={classes.form}>
              <FormControl variant="filled" margin="normal" fullWidth>
                <InputLabel>Vehicle</InputLabel>
                <Select
                  value={deviceId}
                  onChange={(e) => {
                    setDeviceId(e.target.value);
                    setItems([]);
                    setnewItems([]);
                  }}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  {_.orderBy(
                    devices,
                    [(item) => item.name.toLowerCase()],
                    ["asc"]
                  ).map((device) => (
                    <MenuItem value={device.id}>
                      {convertUnicode(device.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="filled" margin="normal" fullWidth>
                <InputLabel>{t("reportPeriod")}</InputLabel>
                <Select
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  <MenuItem value="today">{t("reportToday")}</MenuItem>
                  <MenuItem value="yesterday">{t("reportYesterday")}</MenuItem>
                  <MenuItem value="thisWeek">{t("reportThisWeek")}</MenuItem>
                  <MenuItem value="previousWeek">
                    {t("reportPreviousWeek")}
                  </MenuItem>
                  <MenuItem value="thisMonth">{t("reportThisMonth")}</MenuItem>
                  <MenuItem value="previousMonth">
                    {t("reportPreviousMonth")}
                  </MenuItem>
                  <MenuItem value="custom">{t("reportCustom")}</MenuItem>
                </Select>
              </FormControl>
              {period === "custom" && (
                <ThemeProvider theme={materialTheme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDateTimePicker
                      className={classes.customDatePicker}
                      label={t("reportFrom")}
                      inputFormat="DD/MM/YYYY hh:mm a"
                      value={from}
                      onChange={(date) => {
                        setFrom(date);
                      }}
                      DialogProps={{
                        className: classes.customDatePickerDialog,
                      }}
                      renderInput={(params) => (
                        <TextField
                          margin="normal"
                          variant="filled"
                          className={classes.datePickerInput}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end">
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...params}
                        />
                      )}
                    />
                    <MobileDateTimePicker
                      className={classes.customDatePicker}
                      label={t("reportTo")}
                      inputFormat="DD/MM/YYYY hh:mm a"
                      value={to}
                      minDate={from}
                      onChange={(date) => setTo(date)}
                      DialogProps={{
                        className: classes.customDatePickerDialog,
                      }}
                      renderInput={(params) => (
                        <TextField
                          margin="normal"
                          variant="filled"
                          className={classes.datePickerInput}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end">
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              )}
              {children}
              <FormControl margin="normal" fullWidth>
                <ButtonGroup
                  color="primary"
                  orientation="vertical"
                  disabled={!deviceId}
                >
                  <Button onClick={() => handleClick(false, true)}>
                    {t("reportShow")}
                  </Button>
                  <Button onClick={() => handleClick(false, false)}>
                    {t("reportExport")}
                  </Button>
                  <Button onClick={() => handleClick(true, false)}>
                    {t("reportEmail")}
                  </Button>
                </ButtonGroup>
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <div style={{ height: height - 100 }}>
              <div style={{ height: "50%", marginTop: 2, marginBottom: 2 }}>
                <div>
                  {loader ? (
                    <LinearProgress
                      variant="buffer"
                      value={progress}
                      valueBuffer={buffer}
                    />
                  ) : (
                    ""
                  )}
                  <MaterialReactTable
                    columns={columns}
                    data={items}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enablePagination={items.length > 10}
                    enableSorting={true}
                    enableBottomToolbar={true}
                    enableTopToolbar={true}
                    enableStickyHeader
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [50],
                    }}
                    renderTopToolbarCustomActions={() => (
                      <Typography
                        variant="h6"
                        style={{
                          color: "#00ac69",
                          paddingLeft: "20px",
                          paddingRight: "5px",
                        }}
                      >
                        Stops Report
                      </Typography>
                    )}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: "#1F1D2B",
                        color: "#FFFFFF",
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: "#1F1D2B",
                        color: "#FFFFFF",
                      },
                    }}
                    muiTableHeadProps={{
                      sx: {
                        backgroundColor: "#1F1D2B",
                        color: "#FFFFFF",
                      },
                    }}
                    muiTableContainerProps={{
                      sx: {
                        color: "#57c7d4",
                        overflowY: "scroll",
                        height: height / 2 - 178,
                      },
                    }}
                    muiTableHeadRowProps={({ table, headerGroup }) => ({
                      sx: {
                        backgroundColor: "#1F1D2B",
                        color: "#FFFFFF",
                      },
                    })}
                    muiTableBodyRowProps={({ row }) => ({
                      onClick: (event) => {
                        setSelectedRow(row.original.id);
                        setIsReady(true);

                        setSetRowObject({
                          index: row.original.id,
                          lat: row.original.latitude,
                          long: row.original.longitude,
                          color:
                            row.original.averageSpeed === 0 ? "green" : "blue",
                        });
                      },
                      sx: {
                        cursor: "pointer",
                        backgroundColor:
                          selectedRow === row.original.id ||
                          selectedRow === row.id
                            ? "#0d0d0d"
                            : "",
                      },
                    })}
                  />
                </div>
              </div>
              <div style={{ height: "50%", marginTop: 2, marginBottom: 2 }}>
                {/* <div
                  id="map"
                  ref={mapContainer}
                  style={{ height: "100%", width: "100%" }}
                ></div> */}

                {setRowObject && (
                  <StopReportMapContainer
                    isReady={isReady}
                    index={setRowObject.index}
                    lat={setRowObject.lat}
                    long={setRowObject.long}
                    color={setRowObject.color}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12} className={classes.tabel}>
        {loader ? (
          <LinearProgress
            variant="buffer"
            value={progress}
            valueBuffer={buffer}
          />
        ) : (
          ""
        )}
        <MaterialReactTable
          columns={columns}
          data={items}
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={items.length > 50}
          enableSorting={true}
          enableBottomToolbar={true}
          enableTopToolbar={true}
          enableStickyHeader
          muiTablePaginationProps={{
            rowsPerPageOptions: [50],
          }}
          renderTopToolbarCustomActions={() => (
            <Typography
              variant="h6"
              style={{
                color: "#00ac69",
                paddingLeft: "20px",
                paddingRight: "5px",
              }}
            >
              Stops Report
            </Typography>
          )}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: "#1F1D2B",
              color: "#FFFFFF",
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              backgroundColor: "#1F1D2B",
              color: "#FFFFFF",
            },
          }}
          muiTableHeadProps={{
            sx: {
              backgroundColor: "#1F1D2B",
              color: "#FFFFFF",
            },
          }}
          muiTableContainerProps={{
            sx: {
              color: "#57c7d4",
              overflowY: "scroll",
              height: height / 2 - 178,
            },
          }}
          muiTableHeadRowProps={({ table, headerGroup }) => ({
            sx: {
              backgroundColor: "#1F1D2B",
              color: "#FFFFFF",
            },
          })}
          muiTableBodyRowProps={({ row }) => ({
            // onClick: (event) => {

            // },
            sx: {
              cursor: "pointer",
              backgroundColor:
                selectedRow === row.original.id || selectedRow === row.id
                  ? "#0d0d0d"
                  : "",
            },
          })}
        />
        <br />
      </Grid>
    </Grid>
  );
};

export default StopReportPage;
