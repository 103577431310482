import React, { useEffect, useRef, useState } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Checkbox,
  ListItemText,
  Chip,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Button,
  Typography,
} from "@mui/material";
import {
  formatPosition,
  formatType,
  formatSpeed,
  formatTime,
} from "common/util/formatter";
import ReportFilter from "reports/components/ReportFilter";
import ReportLayoutPage from "reports/layouts/ReportLayoutPage";
import PositionValue from "common/components/PositionValue";
import MaterialReactTable from "material-react-table";
import usePositionAttributes from "common/attributes/usePositionAttributes";
import { useAttributePreference, usePreference } from "common/util/preferences";
import { prefixString } from "common/util/stringUtils";
import { useTranslation } from "common/components/LocalizationProvider";
import { makeStyles } from "@mui/styles";
import mapboxgl from "mapbox-gl";
import { useSelector } from "react-redux";
import { useEffectAsync } from "reactHelper";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    "& MuiButtonGroup-root": {
      width: "100%",
    },
    "& MuiButtonGroup-root button": {
      width: "100%",
    },
    "& svg": {
      height: "30px",
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
    //  "& div":{
    //   overflowX: "hidden"
    // }
  },
  list: {
    height: "50%",
  },
  map: {
    height: "50%",
  },
  mapContainer: { top: 0, width: "100%", bottom: 0, height: "100%" },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& ul li:last-child ": {
      width: "100%",
    },
  },

  chip: {
    margin: 2,
    backgroundColor: "#616161",
    color: "#fff",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  btn: {
    width: "100%",
  },
}));

const SelectedKeysValues = (t) => [
  {
    key: "allEvents",
    value: t("eventAll"),
  },
  // {
  //   key: "deviceOnline",
  //   value: t("eventDeviceOnline"),
  // },
  // {
  //   key: "deviceUnknown",
  //   value: t("eventDeviceUnknown"),
  // },
  // {
  //   key: "deviceOffline",
  //   value: t("eventDeviceOffline"),
  // },
  {
    key: "deviceInactive",
    value: "Vehicle inactive",
  },
  {
    key: "deviceMoving",
    value: "Vehicle moving",
  },
  {
    key: "deviceStopped",
    value: "Vehicle stopped",
  },
  {
    key: "deviceOverspeed",
    value: t("eventDeviceOverspeed"),
  },
  // {
  //   key: "deviceFuelDrop",
  //   value: t("eventDeviceFuelDrop"),
  // },
  {
    key: "commandResult",
    value: t("eventCommandResult"),
  },
  {
    key: "geofenceEnter",
    value: t("eventGeofenceEnter"),
  },
  {
    key: "geofenceExit",
    value: t("eventGeofenceExit"),
  },
  {
    key: "alarm",
    value: t("eventAlarm"),
  },
  {
    key: "ignitionOn",
    value: t("eventIgnitionOn"),
  },
  {
    key: "ignitionOff",
    value: t("eventIgnitionOff"),
  },
  {
    key: "maintenance",
    value: t("eventMaintenance"),
  },
  // {
  //   key: "textMessage",
  //   value: t("eventTextMessage"),
  // },
  {
    key: "driverChanged",
    value: t("eventDriverChanged"),
  },
  { key: "hardBraking", value: "Hard braking" },
  { key: "hardAcceleration", value: "Hard acceleration" },
  { key: "hardCornering", value: "Hard Cornering" },
];

const columnsArray = [
  ["eventTime", "positionFixTime"],
  ["type", "sharedType"],
  ["geofenceId", "sharedGeofence"],
  ["maintenanceId", "sharedMaintenance"],
  ["attributes", "commandData"],
];
const columnsMap = new Map(columnsArray);

const Filter = ({ setItems, onDeviceChange }) => {
  const t = useTranslation();

  const [eventTypes, setEventTypes] = useState(["allEvents"]);
  const classes = useStyles();
  const [isSelectorOpen, setisSelectorOpen] = useState(false);
  const handleSubmit = async (deviceId, from, to, mail, headers) => {
    const query = new URLSearchParams({ deviceId, from, to, mail });
    eventTypes.forEach((it) => query.append("type", it));
    const response = await fetch(`/api/reports/events?${query.toString()}`, {
      headers,
    });
    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType) {
        if (contentType === "application/json") {
          let data = await response.json();
          if (data.length > 0) {
            let driverData = data.filter((d) => d.type === "driverChanged");

            // console.log(driverData);
            let unique = [...new Set(data.map(({ deviceId }) => deviceId))];

            // console.log(unique);
            unique.map(async (driver) => {
              const response = await fetch(`/api/drivers?deviceId=${driver}`);
              if (response.ok) {
                const DriverDetail = await response.json();
                data.map((d) => {
                  if (d.deviceId === driver) {
                    DriverDetail.map((driverDet) => {
                      if (
                        d.attributes.driverUniqueId ===
                        "0" + driverDet.uniqueId
                      ) {
                        d.DriverName = "Driver Changed - " + driverDet.name;
                      }
                    });
                  }
                  return d;
                });
                setItems(data);
              }
            });
          } else {
            setItems(data);
          }
        } else {
          window.location.assign(
            window.URL.createObjectURL(await response.blob())
          );
        }
      }
    }
  };

  const getChipValue = (selected) => {
    const a = [];
    SelectedKeysValues(t).map((d) => {
      const x = selected.map((e) => {
        if (e === d.key) {
          a.push(d);
        }
      });
    });
    return a;
  };
  const SelectAllEvents = () => {};

  const handleChange = (e) => {
    setEventTypes(e.target.value);
  };
  return (
    <ReportFilter handleSubmit={handleSubmit} onDeviceChange={onDeviceChange}>
      <FormControl variant="filled" margin="normal" fullWidth>
        <InputLabel>{t("reportEventTypes")}</InputLabel>
        <Select
          value={eventTypes}
          onChange={(e) => {
            handleChange(e);
          }}
          multiple
          MenuProps={{ classes: { paper: classes.dropdownStyle } }}
          renderValue={(selected) => (
            <ChipsSelectView selected={getChipValue(selected)} />
          )}
          open={isSelectorOpen}
          input={
            <Input
              onClick={() => {
                if (!isSelectorOpen) {
                  setisSelectorOpen(true);
                }
              }}
            />
          }
        >
          <MenuItem
            key="allEvents"
            value="allEvents"
            onClick={() => SelectAllEvents()}
          >
            <Checkbox checked={eventTypes.indexOf("allEvents") > -1} />
            <ListItemText primary={t("eventAll")} />
          </MenuItem>
          <MenuItem key="alarm" value="alarm">
            <Checkbox checked={eventTypes.indexOf("alarm") > -1} />
            <ListItemText primary={t("eventAlarm")} />
          </MenuItem>
          <MenuItem key="commandResult" value="commandResult">
            <Checkbox checked={eventTypes.indexOf("commandResult") > -1} />
            <ListItemText primary={t("eventCommandResult")} />
          </MenuItem>
          <MenuItem key="driverChanged" value="driverChanged">
            <Checkbox checked={eventTypes.indexOf("driverChanged") > -1} />
            <ListItemText primary={t("eventDriverChanged")} />
          </MenuItem>
          <MenuItem key="geofenceEnter" value="geofenceEnter">
            <Checkbox checked={eventTypes.indexOf("geofenceEnter") > -1} />
            <ListItemText primary={t("eventGeofenceEnter")} />
          </MenuItem>
          <MenuItem key="geofenceExit" value="geofenceExit">
            <Checkbox checked={eventTypes.indexOf("geofenceExit") > -1} />
            <ListItemText primary={t("eventGeofenceExit")} />
          </MenuItem>
          <MenuItem key="ignitionOn" value="ignitionOn">
            {" "}
            <Checkbox checked={eventTypes.indexOf("ignitionOn") > -1} />
            <ListItemText primary={t("eventIgnitionOn")} />
          </MenuItem>
          <MenuItem key="ignitionOff" value="ignitionOff">
            <Checkbox checked={eventTypes.indexOf("ignitionOff") > -1} />
            <ListItemText primary={t("eventIgnitionOff")} />
          </MenuItem>
          <MenuItem key="maintenance" value="maintenance">
            <Checkbox checked={eventTypes.indexOf("maintenance") > -1} />
            <ListItemText primary={t("eventMaintenance")} />
          </MenuItem>
          <MenuItem key="deviceOverspeed" value="deviceOverspeed">
            <Checkbox checked={eventTypes.indexOf("deviceOverspeed") > -1} />
            <ListItemText primary={t("eventDeviceOverspeed")} />
          </MenuItem>
          {/* <MenuItem key="deviceOnline" value="deviceOnline">
            <Checkbox checked={eventTypes.indexOf("deviceOnline") > -1} />
            <ListItemText primary={t("eventDeviceOnline")} />
          </MenuItem> */}
          {/* <MenuItem key="deviceUnknown" value="deviceUnknown">
            <Checkbox checked={eventTypes.indexOf("deviceUnknown") > -1} />
            <ListItemText primary={t("eventDeviceUnknown")} />
          </MenuItem> */}
          {/* <MenuItem key="deviceOffline" value="deviceOffline">
            <Checkbox checked={eventTypes.indexOf("deviceOffline") > -1} />
            <ListItemText primary={t("eventDeviceOffline")} />
          </MenuItem> */}
          <MenuItem key="deviceInactive" value="deviceInactive">
            <Checkbox checked={eventTypes.indexOf("deviceInactive") > -1} />
            <ListItemText primary="Vehicle inactive" />
          </MenuItem>
          <MenuItem key="deviceMoving" value="deviceMoving">
            <Checkbox checked={eventTypes.indexOf("deviceMoving") > -1} />
            <ListItemText primary="Vehicle moving" />
          </MenuItem>
          <MenuItem key="deviceStopped" value="deviceStopped">
            <Checkbox checked={eventTypes.indexOf("deviceStopped") > -1} />
            <ListItemText primary="Vehicle stopped" />
          </MenuItem>
          {/* <MenuItem key="deviceFuelDrop" value="deviceFuelDrop">
            <Checkbox checked={eventTypes.indexOf("deviceFuelDrop") > -1} />
            <ListItemText primary={t("eventDeviceFuelDrop")} />
          </MenuItem> */}

          {/* <MenuItem key="textMessage" value="textMessage">
            {" "}
            <Checkbox checked={eventTypes.indexOf("textMessage") > -1} />
            <ListItemText primary={t("eventTextMessage")} />
          </MenuItem> */}

          <MenuItem>
            <ButtonGroup color="primary" fullWidth>
              <Button color="primary" onClick={() => setisSelectorOpen(false)}>
                Ok
              </Button>
            </ButtonGroup>
          </MenuItem>
        </Select>
      </FormControl>
    </ReportFilter>
  );
};

const ChipsSelectView = ({ selected }) => {
  const classes = useStyles();

  return (
    <div className={classes.chips}>
      {selected.length > 0 &&
        selected.map(
          (d) =>
            d && <Chip key={d.value} label={d.value} className={classes.chip} />
        )}
    </div>
  );
};

const MapView = ({ SelectedRowData }) => {
  mapboxgl.accessToken = process.env.REACT_APP_LIVE_MAP_BOX_TOKEN;
  const mapContainer = useRef(null);
  // const map = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(17);
  const classes = useStyles();

  // const selectedDevicePositionId = useSelector(state => {
  //   if (SelectedRowData) {
  //     const position = state.positions.items[SelectedRowData.deviceId] || null;
  //     if (position) {
  //       return position.id;
  //     }
  //   }
  // });

  useEffectAsync(async () => {
    // if (map.current) return; // initialize map only once
    // alert("rerender");
    let map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/tonypeter1/ckiguc7qu5xxv19pbji4pep9w",
      center: [lng, lat],
      zoom,
    });
    // to add zoom in out control
    map.addControl(new mapboxgl.NavigationControl());

    if (SelectedRowData && SelectedRowData.positionId) {
      let response = await fetch(
        `/api/positions?id=${SelectedRowData.positionId}`
      );
      if (response.ok) {
        // alert("resp ok");
        let res = await response.json();
        if (res.length > 0) {
          let { latitude } = res[0];

          let { longitude } = res[0];
          setLng(longitude);
          setLat(latitude);
          // to add markar
          map.setCenter([longitude, latitude]);
          let marker1 = new mapboxgl.Marker({ scale: 1 })
            .setLngLat([longitude, latitude])
            .addTo(map);

          document.querySelector("#map").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
    }
  }, [SelectedRowData]);

  return (
    <div className={classes.mapContainer}>
      <div
        id="map"
        ref={mapContainer}
        className={classes.mapContainer}
        style={SelectedRowData ? { display: "block" } : { display: "none" }}
      />
    </div>
  );
};

const EventReportPage = () => {
  const t = useTranslation();
  const positionAttributes = usePositionAttributes(t);

  const [items, setItems] = useState([]);
  const classes = useStyles();
  const [height, setheight] = useState(window.innerHeight);
  const [columns, setColumns] = useState([]);
  const [selectedColumnsAttributes, setSelectedColumnsAttributes] = useState(
    []
  );
  const [selectedRow, setSelectedRow] = useState(null);
  const [deviceId, setDeviceId] = useState();
  const devices = useSelector((state) => Object.values(state.devices.items));
  const geofences = useSelector((state) => state.geofences.items);
  const speedUnit = useAttributePreference("speedUnit");
  const hours12 = usePreference("twelveHourFormat");
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const progressRef = React.useRef(() => {});

  const handelRowClick = (event, rowData) => {
    setSelectedRow(rowData);
  };

  const formatValue = (item, key) => {
    switch (key) {
      case "eventTime":
        return formatPosition(item, "eventTime");
      case "type":
        return t(prefixString("event", item[key]));
      case "geofenceId":
        if (item[key] > 0) {
          const geofence = geofences[item[key]];
          return geofence && geofence.name;
        }
        return null;
      case "maintenanceId":
        return item[key] > 0 ? item[key] > 0 : null;
      case "attributes":
        switch (item.type) {
          case "alarm":
            return t(prefixString("alarm", item.attributes.alarm));
          case "deviceOverspeed":
            return formatSpeed(item.attributes.speed, speedUnit, t);
          case "driverChanged":
            return item.attributes.driverUniqueId;
          case "media":
            return item.attributes.file;
          case "commandResult":
            return item.attributes.result;
          default:
            return "";
        }
      default:
        return item[key];
    }
  };

  useEffect(() => {
    let defaultColumns = ["eventTime", "type", "attributes"].map((item) => {
      let itemObj = {
        header: t(columnsMap.get(item)),
        accessorKey: item,
        Cell: ({ renderedCellValue, row }) => {
          return <>{formatValue(row.original, item)}</>;
        },
      };

      return itemObj;
    });

    // let defaultColumns = [
    //   {
    //     header: t("positionFixTime"),
    //     accessorKey: "eventTime",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatPosition(row.original, "eventTime"),
    //   },
    //   {
    //     header: t("sharedType"),
    //     accessorKey: "type",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatType(row.original, SelectedKeysValues(t)),
    //   },
    //   {
    //     header: t("deviceCommand"),
    //     accessorKey: "attributes.result",
    //     Cell: ({ renderedCellValue, row }) => (
    //       <>
    //         {row?.original?.attributes?.result
    //           ? row?.original?.attributes?.result.split(",")[0]
    //           : ""}
    //       </>
    //     ),
    //   },
    // ];

    let selectedColumns = selectedColumnsAttributes.map((item) => {
      let itemObj = {
        header: t(columnsMap.get(item)),
        accessorKey: item,
        Cell: ({ renderedCellValue, row }) => {
          return <>{formatValue(row.original, item)}</>;
        },
      };

      return itemObj;
    });

    let columnsTemp = selectedColumnsAttributes.length
      ? selectedColumns
      : defaultColumns;
    setColumns(columnsTemp);
  }, [selectedColumnsAttributes]);

  useEffect(() => {
    let device = _.find(devices, { id: deviceId });
    if (device && !!device?.attributes?.eventsReportColumns) {
      setSelectedColumnsAttributes(
        device?.attributes?.eventsReportColumns.split(",")
      );
    } else {
      setSelectedColumnsAttributes([]);
    }
  }, [deviceId]);

  return (
    <div className={classes.root} style={{ height }}>
      {/* {height} */}
      <ReportLayoutPage
        filter={
          <Filter
            setItems={setItems}
            onDeviceChange={(id) => {
              setDeviceId(id);
            }}
          />
        }
        Map={<MapView SelectedRowData={selectedRow} />}
        height={height - 100}
      >
        <MaterialReactTable
          columns={columns}
          data={items
            .filter((item) => {
              if (
                item.type === "deviceFuelDrop" ||
                item.type === "deviceUnknown" ||
                item.type === "deviceOffline" ||
                item.type === "textMessage" ||
                item.type === "deviceOnline"
              ) {
                return false;
              }
              return true;
            })
            .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0))}
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={items.length > 50}
          enableSorting={true}
          enableBottomToolbar={true}
          enableTopToolbar={true}
          enableStickyHeader
          muiTablePaginationProps={{
            rowsPerPageOptions: [50],
          }}
          renderTopToolbarCustomActions={() => (
            <Typography
              variant="h6"
              style={{
                color: "#00ac69",
                paddingLeft: "20px",
                paddingRight: "5px",
              }}
            >
              Events Report
            </Typography>
          )}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: "#1F1D2B",
              color: "#FFFFFF",
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              backgroundColor: "#1F1D2B",
              color: "#FFFFFF",
            },
          }}
          muiTableHeadProps={{
            sx: {
              backgroundColor: "#1F1D2B",
              color: "#FFFFFF",
            },
          }}
          muiTableContainerProps={{
            sx: {
              color: "#57c7d4",
              overflowY: "scroll",
              height: height / 2 - 178,
            },
          }}
          muiTableHeadRowProps={({ table, headerGroup }) => ({
            sx: {
              backgroundColor: "#1F1D2B",
              color: "#FFFFFF",
            },
          })}
          muiTableBodyRowProps={({ row }) => ({
            onClick: (event) => {
              handelRowClick(event, row.original);
            },
            sx: {
              cursor: "pointer",
              backgroundColor:
                selectedRow === row.original.id || selectedRow === row.id
                  ? "#0d0d0d"
                  : "",
            },
          })}
        />
      </ReportLayoutPage>
    </div>
  );
};
export default EventReportPage;
