import React, { useState, useEffect, useRef } from "react";
import { map } from "map/core/MapView";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Chip,
  Toolbar,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Popover,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Badge,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import MapIcon from "@mui/icons-material/Map";
import ViewListIcon from "@mui/icons-material/ViewList";
import { devicesActions, sessionActions, settingActions } from "store";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import TuneIcon from "@mui/icons-material/Tune";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { useTranslation } from "../common/components/LocalizationProvider";
import { useDeviceReadonly } from "../common/util/permissions";
import { usePreference } from "../common/util/preferences";
import DeviceRow from "./DeviceRow";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
  },
  filterPanel: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    width: theme.dimensions.drawerWidthTablet,
  },
  outlinedInput: {
    backgroundColor: "#1F1D2B",
    color: "#fff",
    "& .MuiInputAdornment-root > .MuiIconButton-root": {
      color: "#fff !important",
    },
  },
  chip: {
    margin: 2,
    backgroundColor: "#616161",
    color: "#fff",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
}));

const MainToolbar = ({
  filteredDevices,
  devicesOpen,
  setDevicesOpen,
  keyword,
  setKeyword,
  filter,
  setFilter,
  filterSort,
  setFilterSort,
  filterMap,
  setFilterMap,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const t = useTranslation();

  const deviceReadonly = useDeviceReadonly();
  const dispatch = useDispatch();
  const positions = useSelector((state) => state.session.positions);
  const user = useSelector((state) => Object.values(state.session.user));
  const groups = useSelector((state) => state.groups.items);
  const devices = useSelector((state) => state.devices.items);
  const latitude = usePreference("latitude");
  const longitude = usePreference("longitude");
  const [tempFilter, setTempFilter] = useState(filter);
  const [tempFilterSort, setTempFilterSort] = useState(filterSort);
  const [tempFilterMap, setTempFilterMap] = useState(filterMap);

  const deviceListOpen = useSelector((state) => state.session.deviceListOpen);
  const zoom = usePreference("zoom", 0);
  const defaultLatitude = user[9] ? user[9] : latitude;
  const defaultLongitude = user[10] ? user[10] : longitude;
  const defaultZoom = user[11] ? user[11] : zoom;
  const adminEnabled = useSelector(
    (state) => state.session.user && state.session.user.administrator
  );
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const toolbarRef = useRef();
  const inputRef = useRef();
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [devicesAnchorEl, setDevicesAnchorEl] = useState(null);
  const statuses = [
    { id: "online", name: "Online", translation: "deviceStatusOnline" },
    { id: "offline", name: "Offline", translation: "deviceStatusOffline" },
    { id: "unknown", name: "Unknown", translation: "deviceStatusUnknown" },
  ];

  const deviceStatusCount = (status) =>
    Object.values(devices).filter((d) => d.status === status).length;

  const handleViewFilter = () => {
    setFilter(tempFilter);
    setFilterSort(tempFilterSort);
    setFilterMap(tempFilterMap);
    setFilterAnchorEl(null);
  };

  const getStatusesChipValue = () => {
    const a = [];
    console.log("statuses", statuses);
    console.log("selected statuses", tempFilter.statuses);
    statuses.map((d) => {
      if (tempFilter.statuses.length > 0) {
        const x = tempFilter.statuses.map((e) => {
          if (e === d.id) {
            a.push(d);
          }
        });
      }
    });
    return a;
  };

  const getGroupsChipValue = () => {
    const a = [];
    Object.values(groups).map((d) => {
      if (tempFilter.groups.length > 0) {
        const x = tempFilter.groups.map((e) => {
          if (e === d.id) {
            a.push(d);
          }
        });
      }
    });
    return a;
  };

  // const getChipValue = (selected) => {
  //   const a = [];
  //   GroupList.map((d) => {
  //     if (selected.length > 0) {
  //       const x = selected.map((e) => {
  //         if (e === d.id) {
  //           a.push(d);
  //         }
  //       });
  //     }
  //   });
  //   return a;
  // };

  useEffect(() => {
    setFilter(filter);
    setFilterSort(filterSort);
    setFilterMap(filterMap);
  }, [filterAnchorEl]);

  return (
    <>
      <Toolbar ref={toolbarRef} className={classes.toolbar}>
        <IconButton
          edge="start"
          onClick={() => {
            setDevicesOpen(!devicesOpen);
          }}
        >
          {window.innerWidth < 768 ? (
            <>{devicesOpen ? <ViewListIcon /> : <DirectionsCarIcon />}</>
          ) : (
            <>{devicesOpen ? <DirectionsCarIcon /> : <ViewListIcon />}</>
          )}
        </IconButton>

        <OutlinedInput
          ref={inputRef}
          placeholder={t("sharedSearchDevices")}
          value={keyword}
          className={classes.outlinedInput}
          onChange={(e) => setKeyword(e.target.value)}
          onFocus={() => setDevicesAnchorEl(toolbarRef.current)}
          onBlur={() => setDevicesAnchorEl(null)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                size="small"
                edge="end"
                onClick={() => setFilterAnchorEl(inputRef.current)}
              >
                <Badge
                  color="info"
                  variant="dot"
                  invisible={!filter.statuses.length && !filter.groups.length}
                >
                  <TuneIcon fontSize="small" />
                </Badge>
              </IconButton>
            </InputAdornment>
          }
          size="small"
          fullWidth
        />
        <Popover
          open={!!devicesAnchorEl && !devicesOpen}
          anchorEl={devicesAnchorEl}
          onClose={() => setDevicesAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: Number(theme.spacing(2).slice(0, -2)),
          }}
          marginThreshold={0}
          PaperProps={{
            style: {
              width: `calc(${
                toolbarRef.current?.clientWidth
              }px - ${theme.spacing(4)})`,
            },
          }}
          elevation={1}
          disableAutoFocus
          disableEnforceFocus
        >
          {filteredDevices.slice(0, 3).map((_, index) => (
            <DeviceRow
              key={filteredDevices[index].id}
              data={filteredDevices}
              index={index}
            />
          ))}
          {filteredDevices.length > 3 && (
            <ListItemButton
              alignItems="center"
              onClick={() => setDevicesOpen(true)}
            >
              <ListItemText
                primary={t("notificationAlways")}
                style={{ textAlign: "center" }}
              />
            </ListItemButton>
          )}
        </Popover>
        <Popover
          open={!!filterAnchorEl}
          anchorEl={filterAnchorEl}
          onClose={() => setFilterAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className={classes.filterPanel}>
            <FormControl variant="filled" margin="normal" fullWidth>
              <InputLabel>{t("deviceStatus")}</InputLabel>
              <Select
                label={t("deviceStatus")}
                value={tempFilter.statuses}
                onChange={(e) => {
                  console.log(e.target.value);
                  setTempFilter({ ...tempFilter, statuses: e.target.value });
                }}
                multiple
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                renderValue={(selected) => (
                  <ChipsSelectView selected={getStatusesChipValue(selected)} />
                )}
              >
                {statuses.map((status) => (
                  <MenuItem key={status?.id} value={status?.id}>
                    <Checkbox
                      checked={tempFilter.statuses.indexOf(status?.id) > -1}
                    />
                    <ListItemText
                      primary={`${t(status?.translation)} (${deviceStatusCount(
                        status?.id
                      )})`}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="filled" margin="normal" fullWidth>
              <InputLabel>{t("settingsGroups")}</InputLabel>
              <Select
                label={t("settingsGroups")}
                value={tempFilter.groups}
                onChange={(e) =>
                  setTempFilter({ ...tempFilter, groups: e.target.value })
                }
                multiple
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                renderValue={(selected) => (
                  <ChipsSelectView selected={getGroupsChipValue(selected)} />
                )}
              >
                {Object.values(groups)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      <Checkbox
                        checked={
                          tempFilter.groups.indexOf(Number(group.id)) > -1
                        }
                      />
                      <ListItemText primary={group.name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl variant="filled" margin="normal" fullWidth>
              <InputLabel>{t("sharedSortBy")}</InputLabel>
              <Select
                label={t("sharedSortBy")}
                value={tempFilterSort}
                onChange={(e) => setTempFilterSort(e.target.value)}
                displayEmpty
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
                <MenuItem value="">{"\u00a0"}</MenuItem>
                <MenuItem value="name">{t("sharedName")}</MenuItem>
                <MenuItem value="lastUpdate">{t("deviceLastUpdate")}</MenuItem>
              </Select>
            </FormControl>

            <Grid container spacing={3}>
              <Grid item xs="auto">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tempFilterMap}
                        onChange={(e) => setTempFilterMap(e.target.checked)}
                      />
                    }
                    label={t("sharedFilterMap")}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  onClick={() => {
                    handleViewFilter();
                  }}
                >
                  View
                </Button>
              </Grid>
            </Grid>
          </div>
        </Popover>

        <IconButton
          edge="end"
          onClick={() => {
            dispatch(devicesActions.select(null));
            dispatch(sessionActions.updatePositions(Object.values(positions)));
            dispatch(sessionActions.toggleTabs(false));
            dispatch(sessionActions.SelectTab(0));
            dispatch(sessionActions.setInitialized(false));
            map.jumpTo({
              center: [defaultLongitude, defaultLatitude],
              zoom: defaultZoom,
            });
            map.resize();
            navigate("/");
          }}
          disabled={deviceReadonly}
        >
          <Tooltip title="All Vehicles" arrow>
            <AltRouteIcon />
          </Tooltip>
        </IconButton>
      </Toolbar>
    </>
  );
};

const ChipsSelectView = ({ selected }) => {
  const classes = useStyles();

  return (
    <div className={classes.chips}>
      {selected.length > 0 &&
        selected.map(
          (d) =>
            d && <Chip key={d.id} label={d.name} className={classes.chip} />
        )}
    </div>
  );
};

export default MainToolbar;
