import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
} from "@mui/material";
import { useTranslation } from "common/components/LocalizationProvider";
import { formatDateAustralian } from "common/util/formatter";
import ReportLayoutPage from "reports/layouts/ReportLayoutPage";
import moment from "moment";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  buttonGroup: {
    marginTop: "15px",
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  chip: {
    margin: 2,
    backgroundColor: "#616161",
    color: "#fff",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const Filter = ({ setItems }) => {
  const classes = useStyles();
  const [period, setPeriod] = useState("today");
  const [from, setFrom] = useState(moment().subtract(1, "hour"));
  const [to, setTo] = useState(moment());
  const t = useTranslation();

  const handleClick = async () => {
    let selectedFrom;
    let selectedTo;
    switch (period) {
      case "today":
        selectedFrom = moment().startOf("day");
        selectedTo = moment().endOf("day");
        break;
      case "yesterday":
        selectedFrom = moment().subtract(1, "day").startOf("day");
        selectedTo = moment().subtract(1, "day").endOf("day");
        break;
      case "thisWeek":
        selectedFrom = moment().startOf("week");
        selectedTo = moment().endOf("week");
        break;
      case "previousWeek":
        selectedFrom = moment().subtract(1, "week").startOf("week");
        selectedTo = moment().subtract(1, "week").endOf("week");
        break;
      case "thisMonth":
        selectedFrom = moment().startOf("month");
        selectedTo = moment().endOf("month");
        break;
      case "previousMonth":
        selectedFrom = moment().subtract(1, "month").startOf("month");
        selectedTo = moment().subtract(1, "month").endOf("month");
        break;
      default:
        selectedFrom = from;
        selectedTo = to;
        break;
    }

    const query = new URLSearchParams({
      from: selectedFrom.toISOString(),
      to: selectedTo.toISOString(),
    });
    const response = await fetch(`/api/statistics?${query.toString()}`, {
      Accept: "application/json",
    });
    if (response.ok) {
      setItems(await response.json());
    }
  };

  return (
    <>
      <FormControl variant="filled" margin="normal" fullWidth>
        <InputLabel>{t("reportPeriod")}</InputLabel>
        <Select
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          MenuProps={{
            classes: { paper: classes.dropdownStyle },
          }}
        >
          <MenuItem value="today">{t("reportToday")}</MenuItem>
          <MenuItem value="yesterday">{t("reportYesterday")}</MenuItem>
          <MenuItem value="thisWeek">{t("reportThisWeek")}</MenuItem>
          <MenuItem value="previousWeek">{t("reportPreviousWeek")}</MenuItem>
          <MenuItem value="thisMonth">{t("reportThisMonth")}</MenuItem>
          <MenuItem value="previousMonth">{t("reportPreviousMonth")}</MenuItem>
          <MenuItem value="custom">{t("reportCustom")}</MenuItem>
        </Select>
      </FormControl>
      {period === "custom" && (
        <TextField
          margin="normal"
          variant="filled"
          label={t("reportFrom")}
          type="datetime-local"
          value={from.format(moment.HTML5_FMT.DATETIME_LOCAL)}
          onChange={(e) =>
            setFrom(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL))
          }
          fullWidth
        />
      )}
      {period === "custom" && (
        <TextField
          margin="normal"
          variant="filled"
          label={t("reportTo")}
          type="datetime-local"
          value={to.format(moment.HTML5_FMT.DATETIME_LOCAL)}
          onChange={(e) =>
            setTo(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL))
          }
          fullWidth
        />
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        fullWidth
      >
        {t("reportShow")}
      </Button>
    </>
  );
};

const StatisticsPage = () => {
  const [items, setItems] = useState([]);
  const t = useTranslation();

  return (
    <ReportLayoutPage filter={<Filter setItems={setItems} />}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("statisticsCaptureTime")}</TableCell>
              <TableCell>{t("statisticsActiveUsers")}</TableCell>
              <TableCell>{t("statisticsActiveDevices")}</TableCell>
              <TableCell>{t("statisticsRequests")}</TableCell>
              <TableCell>{t("statisticsMessagesReceived")}</TableCell>
              <TableCell>{t("statisticsMessagesStored")}</TableCell>
              <TableCell>{t("notificatorMail")}</TableCell>
              <TableCell>{t("notificatorSms")}</TableCell>
              <TableCell>{t("statisticsGeocoder")}</TableCell>
              <TableCell>{t("statisticsGeolocation")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{formatDateAustralian(item.captureTime)}</TableCell>
                <TableCell>{item.activeUsers}</TableCell>
                <TableCell>{item.activeDevices}</TableCell>
                <TableCell>{item.requests}</TableCell>
                <TableCell>{item.messagesReceived}</TableCell>
                <TableCell>{item.messagesStored}</TableCell>
                <TableCell>{item.mailSent}</TableCell>
                <TableCell>{item.smsSent}</TableCell>
                <TableCell>{item.geocoderRequests}</TableCell>
                <TableCell>{item.geolocationRequests}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ReportLayoutPage>
  );
};

export default StatisticsPage;
