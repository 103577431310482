import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePreference } from "common/util/preferences";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { sessionActions, devicesActions } from "store/";
import AppBar from "@mui/material/AppBar";
import AlarmAddOutlinedIcon from "@mui/icons-material/AlarmAddOutlined";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import ListSubheader from "@mui/material/ListSubheader";
import Divider from "@mui/material/Divider";
import LanguageIcon from "@mui/icons-material/Language";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MapIcon from "@mui/icons-material/Map";
import BarChartIcon from "@mui/icons-material/BarChart";
import PeopleIcon from "@mui/icons-material/People";
import StorageIcon from "@mui/icons-material/Storage";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import TimelineIcon from "@mui/icons-material/Timeline";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import FolderIcon from "@mui/icons-material/Folder";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import ReplayIcon from "@mui/icons-material/Replay";
import VpnLockOutlinedIcon from "@mui/icons-material/VpnLockOutlined";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import BuildIcon from "@mui/icons-material/Build";
import { useTranslation } from "common/components/LocalizationProvider";
// Importing the color of your choice from Material-UI
import cyan from "@mui/material/colors/cyan";
// import { ApiUrl } from './const';
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { nativePostMessage } from "common/components/NativeInterface";
import { CalendarMonth } from "@mui/icons-material";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1,
    color: "#FFFFFF",
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
  },
  list: {
    width: 250,
  },
  subHeader: {
    background: "transparent",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    color: "#FFFFFF",
  },
  paper: {
    backgroundColor: "#1F1D2B",
  },
}));

const MainToolbar = () => {
  const navigate = useNavigate();
  const t = useTranslation();
  const Toggle = useSelector((state) => state.session.isShow);
  const adminGlobalSettings = useSelector(
    (state) => state.session.user?.attributes?.adminGlobalSettings
  );
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const adminEnabled = useSelector(
    (state) => state.session.user && state.session.user.administrator
  );
  const userId = useSelector(
    (state) => state.session.user && state.session.user.id
  );
  const isDriverVisible = useSelector((state) => state.setting.isDriverVisible);
  const isMaintenanceVisible = useSelector(
    (state) => state.setting.isMaintenanceVisible
  );
  const isDriverBehaviourVisible = useSelector(
    (state) => state.setting.isDriverBehaviourVisible
  );
  const isGroupsVisible = useSelector((state) => state.setting.isGroupsVisible);

  const isCalendarVisible = useSelector(
    (state) => state.setting.isCalendarVisible
  );

  const isAssignedGroupVisible = useSelector(
    (state) => state.setting.isAssignedGroupVisible
  );

  const isNotificationsVisible = useSelector(
    (state) => state.setting.isNotificationsVisible
  );

  const isPreferenceVisible = useSelector(
    (state) => state.setting.isPreferenceVisible
  );

  const IsSleepModeVisible = useSelector(
    (state) => state.setting.IsSleepModeVisible
  );
  const isSetIgnitionVisible = useSelector(
    (state) => state.setting.isSetIgnitionVisible
  );

  const IsSpeedingAlarmVisible = useSelector(
    (state) => state.setting.IsSpeedingAlarmVisible
  );

  const user = useSelector((state) => state.session.user);

  const openDrawer = () => {
    setDrawer(true);
  };
  const closeDrawer = () => {
    setDrawer(false);
  };

  const icon_color = cyan.A400; // #00e5ff

  const handleLogout = async () => {
    setAnchorEl(null);
    window.localStorage.removeItem("trafficEnabled");

    const notificationToken = window.localStorage.getItem("notificationToken");
    if (notificationToken) {
      window.localStorage.removeItem("notificationToken");
      const tokens = user.attributes.notificationTokens?.split(",") || [];
      if (tokens.includes(notificationToken)) {
        const updatedUser = {
          ...user,
          attributes: {
            ...user.attributes,
            notificationTokens:
              tokens.length > 1
                ? tokens.filter((it) => it !== notificationToken).join(",")
                : undefined,
          },
        };
        await fetch(`/api/users/${user.id}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(updatedUser),
        });
      }
    }

    await fetch("/api/session", { method: "DELETE" });
    nativePostMessage("logout");
    navigate("/login");
    dispatch(sessionActions.updateUser(null));
    window.location.reload();
  };

  return (
    <>
      <AppBar
        position="static"
        className={classes.appBar}
        style={{ backgroundColor: "#1F1D2B" }}
      >
        <Toolbar>
          <IconButton className={classes.menuButton} onClick={openDrawer}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.flex}>
            ICU Protection
          </Typography>
          <Button style={{ color: "#FFFFFF" }} onClick={handleLogout}>
            {t("loginLogout")}
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        open={drawer}
        onClose={closeDrawer}
        classes={{ paper: classes.paper }}
      >
        <div
          tabIndex={0}
          className={classes.list}
          role="button"
          onClick={closeDrawer}
          onKeyDown={closeDrawer}
          style={{ backgroundColor: "#1F1D2B" }}
        >
          <List>
            <ListItemButton
              onClick={() => {
                navigate("/");
                // dispatch(devicesActions.select(null));
                dispatch(sessionActions.toggleTabs(false));
                dispatch(sessionActions.SelectTab(0));
              }}
            >
              <ListItemIcon>
                <MapIcon style={{ color: icon_color }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" style={{ color: "#57c7d4" }}>
                    {t("mapTitle")}
                  </Typography>
                }
              />
            </ListItemButton>
            {/* <ListItemButton onClick={() => navigate('/replay')}>
              <ListItemIcon>
                <ReplayIcon style={{ color: icon_color }} />
              </ListItemIcon>
              <ListItemText disableTypography
                  primary={<Typography type="body2" style={{ color: '#57c7d4' }}>{t('reportReplay')}</Typography>} />
            </ListItem> */}
          </List>
          <Divider />
          <List
            subheader={
              <ListSubheader className={classes.subHeader}>
                {t("reportTitle")}
              </ListSubheader>
            }
          >
            <ListItemButton onClick={() => navigate("/reports/chart")}>
              <ListItemIcon>
                <TrendingUpIcon style={{ color: icon_color }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" style={{ color: "#57c7d4" }}>
                    {t("reportChart")}
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/reports/event")}>
              <ListItemIcon>
                <NotificationsActiveIcon style={{ color: icon_color }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" style={{ color: "#57c7d4" }}>
                    {t("reportEvents")}
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/reports/route")}>
              <ListItemIcon>
                <TimelineIcon style={{ color: icon_color }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" style={{ color: "#57c7d4" }}>
                    {t("reportRoute")}
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/reports/stop")}>
              <ListItemIcon>
                <PauseCircleFilledIcon style={{ color: icon_color }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" style={{ color: "#57c7d4" }}>
                    {t("reportStops")}
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/reports/summary")}>
              <ListItemIcon>
                <FormatListBulletedIcon style={{ color: icon_color }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" style={{ color: "#57c7d4" }}>
                    {t("reportSummary")}
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/reports/trip")}>
              <ListItemIcon>
                <PlayCircleFilledIcon style={{ color: icon_color }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" style={{ color: "#57c7d4" }}>
                    {t("reportTrips")}
                  </Typography>
                }
              />
            </ListItemButton>
          </List>
          <Divider />
          <List
            subheader={
              <ListSubheader className={classes.subHeader}>
                {t("settingsTitle")}
              </ListSubheader>
            }
          >
            <ListItemButton
              disabled={!userId}
              onClick={() => navigate(`/user/${userId}`)}
            >
              <ListItemIcon>
                <PersonIcon style={{ color: icon_color }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" style={{ color: "#57c7d4" }}>
                    {t("settingsUser")}
                  </Typography>
                }
              />
            </ListItemButton>

            {isGroupsVisible && isAssignedGroupVisible && (
              <ListItemButton
                onClick={() => navigate("/settings/vehicleAssignedGroup")}
              >
                <ListItemIcon>
                  <DriveFileMoveIcon style={{ color: icon_color }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type="body2" style={{ color: "#57c7d4" }}>
                      Assigned Groups
                    </Typography>
                  }
                />
              </ListItemButton>
            )}
            {/* <ListItemButton onClick={() => navigate('/settings/attributes')}>
              <ListItemIcon>
                <StorageIcon style={{ color: icon_color }} />
              </ListItemIcon>
              <ListItemText
              disableTypography
              primary={<Typography type="body2" style={{ color: '#57c7d4' }}>{t('sharedComputedAttributes')}</Typography>}
               />
            </ListItem> */}

            {isCalendarVisible && (
              <ListItemButton onClick={() => navigate("/settings/calendars")}>
                <ListItemIcon>
                  <CalendarMonth style={{ color: icon_color }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type="body2" style={{ color: "#57c7d4" }}>
                      Calendars
                    </Typography>
                  }
                />
              </ListItemButton>
            )}

            {isDriverVisible && (
              <ListItemButton onClick={() => navigate("/settings/drivers")}>
                <ListItemIcon>
                  <PersonIcon style={{ color: icon_color }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type="body2" style={{ color: "#57c7d4" }}>
                      {t("sharedDrivers")}
                    </Typography>
                  }
                />
              </ListItemButton>
            )}

            <ListItemButton onClick={() => navigate("/settings/Geofences")}>
              <ListItemIcon>
                <VpnLockOutlinedIcon style={{ color: icon_color }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" style={{ color: "#57c7d4" }}>
                    Geofences
                  </Typography>
                }
              />
            </ListItemButton>

            {(isPreferenceVisible || adminGlobalSettings == "enabled") && (
              <ListItemButton onClick={() => navigate("/settings/preferences")}>
                <ListItemIcon>
                  <LanguageIcon style={{ color: icon_color }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type="body2" style={{ color: "#57c7d4" }}>
                      Global Preference
                    </Typography>
                  }
                />
              </ListItemButton>
            )}

            {isGroupsVisible && (
              <ListItemButton onClick={() => navigate("/settings/groups")}>
                <ListItemIcon>
                  <FolderIcon style={{ color: icon_color }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type="body2" style={{ color: "#57c7d4" }}>
                      {t("settingsGroups")}
                    </Typography>
                  }
                />
              </ListItemButton>
            )}

            {isSetIgnitionVisible && (
              <ListItemButton
                disabled={!userId}
                onClick={() => navigate("/settings/Ignition")}
              >
                <ListItemIcon>
                  <VpnKeyOutlinedIcon style={{ color: icon_color }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type="body2" style={{ color: "#57c7d4" }}>
                      Ignition
                    </Typography>
                  }
                />
              </ListItemButton>
            )}

            {isMaintenanceVisible && (
              <ListItemButton
                onClick={() => navigate("/settings/maintenances")}
              >
                <ListItemIcon>
                  <BuildIcon style={{ color: icon_color }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type="body2" style={{ color: "#57c7d4" }}>
                      {t("sharedMaintenance")}
                    </Typography>
                  }
                />
              </ListItemButton>
            )}

            {isNotificationsVisible && (
              <ListItemButton
                onClick={() => navigate("/settings/notifications")}
              >
                <ListItemIcon>
                  <NotificationsIcon style={{ color: icon_color }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type="body2" style={{ color: "#57c7d4" }}>
                      {t("sharedNotifications")}
                    </Typography>
                  }
                />
              </ListItemButton>
            )}

            {IsSleepModeVisible && (
              <ListItemButton onClick={() => navigate("/settings/SleepMode")}>
                <ListItemIcon>
                  <HotelOutlinedIcon style={{ color: icon_color }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type="body2" style={{ color: "#57c7d4" }}>
                      Sleep mode
                    </Typography>
                  }
                />
              </ListItemButton>
            )}

            {IsSpeedingAlarmVisible && (
              <ListItemButton onClick={() => navigate("/settings/speedalarm")}>
                <ListItemIcon>
                  <AlarmAddOutlinedIcon style={{ color: icon_color }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type="body2" style={{ color: "#57c7d4" }}>
                      Speed Alarm
                    </Typography>
                  }
                />
              </ListItemButton>
            )}

            <ListItemButton
              disabled={!userId}
              onClick={() => navigate("/details")}
            >
              <ListItemIcon>
                <TextFieldsIcon style={{ color: "#57c7d4" }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" style={{ color: "#57c7d4" }}>
                    Vehicle Details
                  </Typography>
                }
              />
            </ListItemButton>
          </List>
          {adminEnabled && (
            <>
              <Divider />
              <List
                subheader={
                  <ListSubheader className={classes.subHeader}>
                    {t("userAdmin")}
                  </ListSubheader>
                }
              >
                <ListItemButton
                  onClick={() => navigate("/admin/position-delete")}
                >
                  <ListItemIcon>
                    <TextFieldsIcon style={{ color: "#57c7d4" }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography type="body2" style={{ color: "#57c7d4" }}>
                        Positions Delete
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/admin/server")}>
                  <ListItemIcon>
                    <StorageIcon style={{ color: icon_color }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography type="body2" style={{ color: "#57c7d4" }}>
                        {t("settingsServer")}
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/admin/statistics")}>
                  <ListItemIcon>
                    <BarChartIcon style={{ color: icon_color }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography type="body2" style={{ color: "#57c7d4" }}>
                        {t("statisticsTitle")}
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/admin/users")}>
                  <ListItemIcon>
                    <PeopleIcon style={{ color: icon_color }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography type="body2" style={{ color: "#57c7d4" }}>
                        {t("settingsUsers")}
                      </Typography>
                    }
                  />
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/admin/devices")}>
                  <ListItemIcon>
                    <TextFieldsIcon style={{ color: "#57c7d4" }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography type="body2" style={{ color: "#57c7d4" }}>
                        Vehicles
                      </Typography>
                    }
                  />
                </ListItemButton>
              </List>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default MainToolbar;
