import moment from "moment";
import { useTranslation as t } from "common/components/LocalizationProvider";
import axios from "axios";
import momentDurationFormat from "moment-duration-format";
import { VolumeDown } from "@mui/icons-material";

export const formatPosition = (value, key) => {
  if (value != null && typeof value === "object") {
    value = value[key];
  }
  switch (key) {
    case "fixTime":
    case "deviceTime":
    case "serverTime":
    case "eventTime":
      return moment(value).format("LLL");
    case "latitude":
    case "longitude":
      return value.toFixed(5);
    case "speed":
    case "course":
      return (value * 1.852).toFixed(1);
    case "batteryLevel":
      return `${value}%`;
    case "totalDistance":
    case "odometer":
      return formatDistance(value, "km");
    case "hours":
      return formatHours(value);
    default:
      if (typeof value === "number") {
        return formatNumber(value);
      }
      if (typeof value === "boolean") {
        return formatBoolean(value);
      }
      return value;
  }
};

export const formatCapAttributes = (key) => {
  switch (key) {
    case "id":
      return "ID";
    case "deviceId":
      return "DeviceID";
    case "totalDistance":
      return "Total Distance";
    case "latitude":
      return "Latitude";
    case "longitude":
      return "Longitude";
    case "batteryLevel":
      return "Battery Level";
    case "protocol":
      return "Type";
    case "fixTime":
      return "Time";
    default:
      return key.charAt(0).toUpperCase() + key.slice(1);
  }
};

export const formatType = (rowData, keyData) => {
  const result = keyData.map((d) => {
    if (rowData.type === "alarm") {
      if (d.key === rowData.attributes.alarm) {
        return d.value
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
      }
    } else if (rowData.type === "driverChanged") {
      if (d.key === rowData.type) {
        if (rowData.DriverName) {
          return rowData.DriverName.toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ");
        }
      }
    } else if (d.key === rowData.type) {
      return d.value
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    }
  });

  return result;
};

export const formatBoolean = (value) =>
  value ? t("sharedYes") : t("sharedNo");

export const formatNumber = (value, precision = 1) =>
  Number(value.toFixed(precision));

export const formatDate = (value, format = "YYYY-MM-DD HH:mm") =>
  moment(value).format(format);

export const formatDateAustralian = (value, format = "DD-MM-YYYY HH:mm") =>
  moment(value).format(format);
export const numberDistanceToStringCommaSeprated = (value, unit) => {
  switch (unit) {
    case "mi":
      return ` ${(value * 0.000621371)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${t("sharedMi")}`;
    case "nmi":
      return ` ${(value * 0.000539957)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${t("sharedNmi")}`;
    case "km":
      return ` ${(value * 0.001)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${t("sharedKm")}`;
    default:
      return ` ${(value * 0.001)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${t("sharedKm")}`;
  }
};

export const formatDistance = (value, unit) => {
  switch (unit) {
    case "mi":
      return `${(value * 0.000621371).toFixed(2)} ${t("sharedMi")}`;
    case "nmi":
      return `${(value * 0.000539957).toFixed(2)} ${t("sharedNmi")}`;
    case "km":
      return `${(value * 0.001).toFixed(2)} ${t("sharedKm")}`;
    default:
      return `${(value * 0.001).toFixed(2)} ${t("sharedKm")}`;
  }
};

export const formatSpeed = (value, unit) => {
  switch (unit) {
    case "kmh":
      return `${(value * 1.852).toFixed(2)} ${t("sharedKmh")}`;
    case "mph":
      return `${(value * 1.15078).toFixed(2)} ${t("sharedMph")}`;
    case "kn":
    default:
      return `${(value * 1).toFixed(2)} ${t("sharedKn")}`;
  }
};

export const formatVolume = (value, unit) => {
  switch (unit) {
    case "impGal":
      return `${(value / 4.546).toFixed(2)} ${t("sharedGallonAbbreviation")}`;
    case "usGal":
      return `${(value / 3.785).toFixed(2)} ${t("sharedGallonAbbreviation")}`;
    case "ltr":
    default:
      return `${(value / 1).toFixed(2)} ${t("sharedLiterAbbreviation")}`;
  }
};

export const formatHours = (value) =>
  moment.duration(value, "milliseconds").format("h [hrs] m [min]");
// return moment.duration(value).humanize()

export const formatHoursDashboard = (value) =>
  moment.duration(value).humanize();

export const fetchAddress = (latitude, longitude) => {
  fetch(
    `https://icu-protection.com/reverse/api/v1.0/reverse?lat=${latitude}&long=${longitude}`
  )
    .then((res) => res.json())
    .then(
      (result) => result.data,
      (error) => ""
    );
};
