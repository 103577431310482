import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "session",
  initialState: {
    server: null,
    user: null,
    socket: null,
    positions: {},
    history: {},
    isShow: false,
    SelectedTab: 0,
    initialized: false,
  },
  reducers: {
    setInitialized(state, action) {
      state.initialized = action.payload;
    },
    toggleTabs(state, action) {
      state.isShow = action.payload;
    },
    SelectTab(state, action) {
      state.SelectedTab = action.payload;
    },
    updateServer(state, action) {
      state.server = action.payload;
    },
    updateUser(state, action) {
      state.user = action.payload;
    },
    updateSocket(state, action) {
      state.socket = action.payload;
    },
    updatePositions(state, action) {
      const liveRoutes =
        state.user.attributes.mapLiveRoutes ||
        state.server.attributes.mapLiveRoutes ||
        "none";
      const liveRoutesLimit =
        state.user.attributes["web.liveRouteLength"] ||
        state.user.attributes["web.liveRouteLength"] ||
        10;
      action.payload.forEach((position) => {
        state.positions[position.deviceId] = position;
        if (liveRoutes !== "none") {
          const route = state.history[position.deviceId] || [];
          const last = route.at(-1);
          if (
            !last ||
            (last[0] !== position.longitude && last[1] !== position.latitude)
          ) {
            state.history[position.deviceId] = [
              ...route.slice(1 - liveRoutesLimit),
              [position.longitude, position.latitude],
            ];
          }
        } else {
          state.history = {};
        }
      });
    },
  },
});

export { actions as sessionActions };
export { reducer as sessionReducer };
