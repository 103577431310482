import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  formatDistance,
  formatHours,
  formatDate,
  formatSpeed,
  formatVolume,
  formatTime,
  formatPosition,
} from "common/util/formatter";
import ReportFilter from "reports/components/ReportFilter";
import ReportLayoutPage from "reports/layouts/ReportLayoutPage";
import { useAttributePreference, usePreference } from "common/util/preferences";
import { useTranslation } from "common/components/LocalizationProvider";
import MaterialTable from "material-table";
import MaterialReactTable from "material-react-table";
import { useSelector } from "react-redux";
import _ from "lodash";

const columnsArray = [
  ["startTime", "reportStartDate"],
  ["distance", "sharedDistance"],
  ["startOdometer", "reportStartOdometer"],
  ["endOdometer", "reportEndOdometer"],
  ["averageSpeed", "reportAverageSpeed"],
  ["maxSpeed", "reportMaximumSpeed"],
  ["engineHours", "reportEngineHours"],
  ["spentFuel", "reportSpentFuel"],
];
const columnsMap = new Map(columnsArray);

const Filter = ({ setItems, onDeviceChange }) => {
  const t = useTranslation();
  const [daily, setDaily] = useState(false);

  const handleSubmit = async (deviceId, from, to, mail, headers) => {
    const query = new URLSearchParams({ deviceId, from, to, daily, mail });
    const response = await fetch(`/api/reports/summary?${query.toString()}`, {
      headers,
    });
    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType) {
        if (contentType === "application/json") {
          setItems(await response.json());
        } else {
          window.location.assign(
            window.URL.createObjectURL(await response.blob())
          );
        }
      }
    }
  };

  return (
    <ReportFilter handleSubmit={handleSubmit} onDeviceChange={onDeviceChange}>
      <FormControlLabel
        control={
          <Checkbox
            checked={daily}
            onChange={(e) => setDaily(e.target.checked)}
          />
        }
        label={t("reportDaily")}
      />
    </ReportFilter>
  );
};

const SummaryReportPage = () => {
  const t = useTranslation();
  const distanceUnit = useAttributePreference("distanceUnit");
  const speedUnit = useAttributePreference("speedUnit");
  const volumeUnit = useAttributePreference("volumeUnit");
  const hours12 = usePreference("twelveHourFormat");
  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState([]);
  const [deviceId, setDeviceId] = useState(null);
  const [selectedColumnsAttributes, setSelectedColumnsAttributes] = useState(
    []
  );
  const devices = useSelector((state) => Object.values(state.devices.items));

  useEffect(() => {
    // let defaultColumns = [
    //   {
    //     header: t("reportStartDate"),
    //     accessorKey: "startTime",
    //     Cell: ({ renderedCellValue, row }) => (
    //       <>
    //         {row.original.startTime
    //           ? formatPosition(row.original.startTime, "fixTime")
    //           : formatPosition(Date.now(), "fixTime")}
    //       </>
    //     ),
    //   },
    //   {
    //     header: t("sharedDistance"),
    //     accessorKey: "distance",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatDistance(row.original.distance, distanceUnit, t),
    //   },
    //   {
    //     header: t("reportStartOdometer"),
    //     accessorKey: "startOdometer",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatDistance(row.original.startOdometer, distanceUnit, t),
    //   },
    //   {
    //     header: t("reportEndOdometer"),
    //     accessorKey: "endOdometer",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatDistance(row.original.endOdometer, distanceUnit, t),
    //   },
    //   {
    //     header: t("reportAverageSpeed"),
    //     accessorKey: "averageSpeed",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatSpeed(row.original.averageSpeed, speedUnit, t),
    //   },
    //   {
    //     header: t("reportMaximumSpeed"),
    //     accessorKey: "maxSpeed",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatSpeed(row.original.maxSpeed, speedUnit, t),
    //   },
    //   {
    //     header: t("reportEngineHours"),
    //     accessorKey: "engineHours",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatHours(row.original.engineHours),
    //   },
    //   // {
    //   //   title: t('reportSpentFuel'),
    //   //   field: "spentFuel",
    //   //   render: rowData => formatVolume(rowData.spentFuel)
    //   // }
    // ];
    let defaultColumns = [
      "startTime",
      "distance",
      "startOdometer",
      "endOdometer",
      "averageSpeed",
      "maxSpeed",
    ].map((item) => {
      let itemObj = {
        header: t(columnsMap.get(item)),
        accessorKey: item,
        Cell: ({ renderedCellValue, row }) => {
          return <>{formatValue(row.original, item)}</>;
        },
      };

      return itemObj;
    });
    let selectedColumns = selectedColumnsAttributes.map((item) => {
      let itemObj = {
        header: t(columnsMap.get(item)),
        accessorKey: item,
        Cell: ({ renderedCellValue, row }) => {
          return <>{formatValue(row.original, item)}</>;
        },
      };

      return itemObj;
    });

    let columnsTemp = selectedColumnsAttributes.length
      ? selectedColumns
      : defaultColumns;
    setColumns(columnsTemp);
  }, [selectedColumnsAttributes]);

  useEffect(() => {
    let device = _.find(devices, { id: deviceId });
    if (device && !!device?.attributes?.summaryReportColumns) {
      setSelectedColumnsAttributes(
        device?.attributes?.summaryReportColumns.split(",")
      );
    } else {
      setSelectedColumnsAttributes([]);
    }
  }, [deviceId]);

  const formatValue = (item, key) => {
    switch (key) {
      case "deviceId":
        return devices[item[key]].name;
      case "startTime":
        return formatPosition(item[key], "fixTime");
      case "startOdometer":
      case "endOdometer":
      case "distance":
        return formatDistance(item[key], distanceUnit, t);
      case "averageSpeed":
      case "maxSpeed":
        return formatSpeed(item[key], speedUnit, t);
      case "engineHours":
        return formatHours(item[key]);
      case "spentFuel":
        return formatVolume(item[key], volumeUnit, t);
      default:
        return item[key];
    }
  };

  return (
    <ReportLayoutPage
      filter={
        <Filter
          setItems={setItems}
          onDeviceChange={(id) => {
            setDeviceId(id);
          }}
        />
      }
    >
      <MaterialReactTable
        columns={columns}
        data={items.sort((a, b) =>
          a.deviceId < b.deviceId ? 1 : b.deviceId < a.deviceId ? -1 : 0
        )}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={items.length > 50}
        enableSorting={true}
        enableBottomToolbar={true}
        enableTopToolbar={true}
        enableStickyHeader
        muiTablePaginationProps={{
          rowsPerPageOptions: [50],
        }}
        renderTopToolbarCustomActions={() => (
          <Typography
            variant="h6"
            style={{
              color: "#00ac69",
              paddingLeft: "20px",
              paddingRight: "5px",
            }}
          >
            Summary Report
          </Typography>
        )}
        muiTopToolbarProps={{
          sx: {
            backgroundColor: "#1F1D2B",
            color: "#FFFFFF",
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            backgroundColor: "#1F1D2B",
            color: "#FFFFFF",
          },
        }}
        muiTableHeadProps={{
          sx: {
            backgroundColor: "#1F1D2B",
            color: "#FFFFFF",
          },
        }}
        muiTableContainerProps={{
          sx: {
            color: "#57c7d4",
            overflowY: "scroll",
          },
        }}
        muiTableHeadRowProps={({ table, headerGroup }) => ({
          sx: {
            backgroundColor: "#1F1D2B",
            color: "#FFFFFF",
          },
        })}
        muiTableBodyRowProps={({ row }) => ({
          sx: {
            cursor: "pointer",
          },
        })}
      />
      {/* <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('reportStartDate')}</TableCell>
              <TableCell>{t('sharedDistance')}</TableCell>
              <TableCell>{t('reportStartOdometer')}</TableCell>
              <TableCell>{t('reportEndOdometer')}</TableCell>
              <TableCell>{t('reportAverageSpeed')}</TableCell>
              <TableCell>{t('reportMaximumSpeed')}</TableCell>
              <TableCell>{t('reportEngineHours')}</TableCell>
              <TableCell>{t('reportSpentFuel')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{formatDate(item.startTime, 'YYYY-MM-DD')}</TableCell>
                <TableCell>{formatDistance(item.distance, distanceUnit)}</TableCell>
                <TableCell>{formatDistance(item.startOdometer, distanceUnit)}</TableCell>
                <TableCell>{formatDistance(item.endOdometer, distanceUnit)}</TableCell>
                <TableCell>{formatSpeed(item.averageSpeed, speedUnit)}</TableCell>
                <TableCell>{formatSpeed(item.maxSpeed, speedUnit)}</TableCell>
                <TableCell>{formatHours(item.engineHours)}</TableCell>
                <TableCell>{formatVolume(item.spentFuel)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </ReportLayoutPage>
  );
};

export default SummaryReportPage;
