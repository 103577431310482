import React from "react";
import { makeStyles } from "@mui/styles";
import Logo from "../resources/images/logo.png";

const useStyles = makeStyles((theme) => ({
  image: {
    alignSelf: "center",
    maxWidth: "240px",
    maxHeight: "120px",
    width: "auto",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      maxWidth: "135px",
      width: "100%",
      objectFit: "contain",
      marginBottom: "25px",
    },
  },
}));

const LogoImage = ({ color }) => {
  const classes = useStyles();

  return <img src={Logo} className={classes.image} style={{ color }} />;
};

export default LogoImage;
