import React, { useState, useEffect, useRef } from "react";
import { SwitcherControl } from "map/switcher/switcher";
import { useAttributePreference } from "common/util/preferences";
import { styleMapbox1, styleMapbox } from "common/util/mapStyle";
import mapboxgl from "mapbox-gl";
import { useTranslation } from "common/components/LocalizationProvider";
import { formatDateAustralian, formatSpeed } from "common/util/formatter";
import { IconButton, Paper, Slider, Toolbar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TuneIcon from "@mui/icons-material/Tune";
import DownloadIcon from "@mui/icons-material/Download";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import { usePreference } from "common/util/preferences";
import { formatTime } from "common/util/formatter";
import { useInterval } from "@uidotdev/usehooks";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    zIndex: 3,
    left: 0,
    top: 0,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: 0,
    },
  },
  title: {
    flexGrow: 1,
  },
  slider: {
    width: "100%",
  },
  controls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formControlLabel: {
    height: "100%",
    width: "100%",
    paddingRight: theme.spacing(1),
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    maxWidth: "500px",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(1),
    },
  },
}));

const TripReportsMapContainer = ({ CoordinatesData, isReady, deviceId }) => {
  const t = useTranslation();
  const map = useRef(null);
  const marker = useRef(null);
  const classes = useStyles();
  const mapContainer = useRef(null);
  const [CurrentPlay, setCurrentPlay] = useState();
  const [index, setIndex] = useState(0);
  const [playing, setPlaying] = useState(false);
  const hours12 = usePreference("twelveHourFormat");
  const mapboxAccessToken = useAttributePreference("mapboxAccessToken");
  const timerRef = useRef();
  let timer;

  const data = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: null,
    },
  };

  const control = new SwitcherControl(
    [
      { title: "Dark Mode", uri: styleMapbox("dark-v10") },
      {
        title: "Light Mode",
        uri: "mapbox://styles/tonypeter1/ckiguc7qu5xxv19pbji4pep9w",
      },
      { title: "Satellite View", uri: styleMapbox("satellite-v9") },
    ],
    t("mapOsm"),
    () => null,
    () => null
  );

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_LIVE_MAP_BOX_TOKEN;

    if (map.current) return; // wait for map to initialize
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: styleMapbox1("tonypeter1/ckiguc7qu5xxv19pbji4pep9w"),
      zoom: 14,
    });

    if (!marker.current) {
      marker.current = new mapboxgl.Marker({
        color: "#F84C4C", // color it red
      })
        .setLngLat([0, 0])
        .addTo(map.current);
    }

    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.setCenter([0, 0]);
    map.current.on("load", () => {
      map.current.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: [],
          },
        },
      });
      map.current.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#1F1D2B",
          "line-width": 4,
        },
      });
    });
    return () => {
      // console.log("cleaned");
      // alert("Map destroyed");
      //  map.current = null;
    };
  }, [deviceId]);

  // useEffect(() => {
  //   if(mapboxAccessToken && mapboxAccessToken.length < 0){
  //      mapboxgl.accessToken = window.atob(mapboxAccessToken);
  //   } else {
  //     mapboxgl.accessToken = '12345';
  //   }
  //
  // }, [mapboxAccessToken])

  const Arr = [];
  const Coordinates = !!CoordinatesData
    ? CoordinatesData.map(({ longitude, latitude }) => [longitude, latitude])
    : [];

  useEffect(() => {
    // wait for map to initialize

    if (!map.current) return; // wait for map to initialize

    if (map.current.getSource("route") && CoordinatesData) {
      // let Coordinates = [];
      // debugger
      // CoordinatesData.map((ac) => {
      //   Coordinates.push([ac.longitude, ac.latitude]);
      // });
      document
        .querySelector("#map")
        .scrollIntoView({ behavior: "smooth", block: "start" });
      const Coordinates = CoordinatesData.map(({ longitude, latitude }) => [
        longitude,
        latitude,
      ]);

      const d = map.current.getStyle();
      // if(d.name === "Mapbox Satellite")
      // {
      //   map.current.setZoom('14');
      // }
      map.current.removeControl(control);
      // console.log("re rendered");

      map.current.easeTo({ center: Coordinates[index], zoom: 14 });
      map.current.setCenter(Coordinates[index]);
      map.current.setPitch(30);

      if (Coordinates.length) {
        marker.current.setLngLat(Coordinates[index]);
        Arr.push(...Coordinates);
        data.geometry.coordinates = Arr;
        if (!map.current.getSource("route")) {
          map.current.addSource("route", {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: [],
              },
            },
          });
        }
        map.current.getSource("route").setData(data);
      } else {
        map.current.addControl(control);
      }
    }

    if (playing && !!CoordinatesData && CoordinatesData.length > 0) {
      timer = setInterval(() => {
        setIndex((index) => index + 1);
      }, 500);
    } else {
      clearInterval(timer);
    }

    if (!map.current.getSource("route") && CoordinatesData) {
      map.current.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: [],
          },
        },
      });
      map.current.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#1F1D2B",
          "line-width": 4,
        },
      });
    }

    return () => {
      // console.log("cleaned");
      // Arr = [];
      // data.geometry.coordinates = Arr;
      // if(map.current && map.current.getSource("route"))
      // {
      //   map.current.getSource("route").setData(data);
      // }
      map.current.removeControl(control);

      clearInterval(timer);
    };
  }, [CoordinatesData, deviceId, playing]);

  useEffect(() => {
    if (index < Coordinates.length) {
      setCurrentPlay(CoordinatesData[index]);
      marker.current.setLngLat(Coordinates[index]);
      map.current.panTo(Coordinates[index]);
    } else {
      map.current.addControl(control);
      setCurrentPlay(null);
      clearInterval(timer);
    }
  }, [index]);

  useEffect(() => {
    if (!!CoordinatesData) {
      if (index >= CoordinatesData.length - 1) {
        clearInterval(timer);
        setPlaying(false);
      }
    }
  }, [index]);

  useEffect(() => {
    setIndex(0);
    setPlaying(false);
  }, [CoordinatesData]);

  // useEffect(() => {
  //   if (playing && positions.length > 0) {
  //     timerRef.current = setInterval(() => {
  //       setIndex((index) => index + 1);
  //     }, 500);
  //   } else {
  //     clearInterval(timerRef.current);
  //   }

  //   return () => clearInterval(timerRef.current);
  // }, [playing, positions]);

  // useEffect(() => {
  //   if (index >= positions.length - 1) {
  //     clearInterval(timerRef.current);
  //     setPlaying(false);
  //   }
  // }, [index, positions]);

  return (
    <>
      <OverlayMeter isReady={isReady} CurrentPlay={CurrentPlay} />
      <div
        id="map"
        ref={mapContainer}
        style={{
          height: "100%",
          width: "100%",
          visibility: isReady ? "visible" : "hidden",
        }}
      />

      {!!CoordinatesData && CoordinatesData.length ? (
        <>
          <Paper className={classes.content} square>
            <Slider
              className={classes.slider}
              max={CoordinatesData.length - 1}
              step={null}
              marks={CoordinatesData.map((_, index) => ({ value: index }))}
              value={index}
              onChange={(_, index) => setIndex(index)}
            />
            <div className={classes.controls}>
              {`${index + 1}/${CoordinatesData.length}`}
              <IconButton
                onClick={() => setIndex((index) => index - 1)}
                disabled={playing || index <= 0}
              >
                <FastRewindIcon />
              </IconButton>

              <IconButton
                onClick={() => setPlaying(!playing)}
                disabled={index >= CoordinatesData.length - 1}
              >
                {playing ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>
              <IconButton
                onClick={() => setIndex((index) => index + 1)}
                disabled={playing || index >= CoordinatesData.length - 1}
              >
                <FastForwardIcon />
              </IconButton>
              {formatDateAustralian(
                CoordinatesData[index].fixTime,
                "DD-MM-YYYY HH:mm:ss"
              )}
            </div>
          </Paper>
        </>
      ) : null}
    </>
  );
};

const GetDirection = (Course) => {
  let Dir;
  // debugger;
  if (Course > 0 && Course <= 45) {
    Dir = Course - 0 > 45 - Course ? "NE" : "N";
  } else if (Course > 45 && Course <= 90) {
    Dir = Course - 45 > 90 - Course ? "E" : "NE";
  } else if (Course > 90 && Course <= 135) {
    Dir = Course - 90 > 135 - Course ? "SE" : "E";
  } else if (Course > 135 && Course <= 180) {
    Dir = Course - 135 > 180 - Course ? "S" : "SE";
  } else if (Course > 180 && Course <= 225) {
    Dir = Course - 180 > 225 - Course ? "SW" : "S";
  } else if (Course > 225 && Course <= 270) {
    Dir = Course - 225 > 270 - Course ? "W" : "SW";
  } else if (Course > 270 && Course <= 315) {
    Dir = Course - 270 > 315 - Course ? "NW" : "W";
  } else if (Course > 315 && Course <= 360) {
    Dir = Course - 315 > 360 - Course ? "N" : "NW";
  } else if (Course > 360 && Course <= 0) {
    Dir = Course - 360 > 0 - Course ? "NE" : "N";
  } else {
    Dir = "Invalid";
  }
  return Dir;
};

const OverlayMeter = ({ isReady, CurrentPlay }) => {
  const t = useTranslation();
  return (
    isReady && (
      <>
        {CurrentPlay && (
          <div className="speed-meter-wrap">
            <div className="speed-meter-svg">
              <img src="/speed-img.svg" alt="Speed Meter" />
            </div>
            <div className="speed-info">
              <div className="speed-info-bx">
                <h3>{formatSpeed(CurrentPlay.speed, "kmh", t)}</h3>
                <label>Speed</label>
              </div>
              <div className="speed-info-bx">
                <h6>{formatDateAustralian(CurrentPlay.deviceTime)}</h6>
                <label>Time</label>
              </div>
              <div className="speed-info-bx">
                <h6>{GetDirection(Number(CurrentPlay.course))}</h6>
                <label>Course</label>
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
};

export default TripReportsMapContainer;
