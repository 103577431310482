import React, { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Autocomplete,
  TextField,
  createFilterOptions,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CachedIcon from "@mui/icons-material/Cached";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MainToolbar from "components/MainToolbar";
import {
  useTranslation,
  useTranslationKeys,
} from "../common/components/LocalizationProvider";
import usePositionAttributes from "../common/attributes/usePositionAttributes";
import { prefixString, unprefixString } from "../common/util/stringUtils";
import SelectField from "../common/components/SelectField";
import useMapStyles from "../map/core/useMapStyles";
import useMapOverlays from "../map/overlay/useMapOverlays";
import { useCatch } from "../reactHelper";
import { sessionActions } from "../store";
import { useRestriction } from "../common/util/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#000",
  },
  autocomplete: {},
  chevron: {
    color: "#fff",
  },
  dialog: {},
  container: {
    marginTop: theme.spacing(2),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  summary: {
    background: "rgb(31, 29, 43)",
  },
  token: {
    background: "rgb(31, 29, 43)",
    color: "#FFFFFF",
  },
  buttons: {
    marginTop: "30px",
    marginBottom: "30px",
    display: "flex",
    justifyContent: "space-evenly",
    "& > *": {
      flexBasis: "33%",
    },
  },
  details: {
    flexDirection: "column",
    background: "rgb(31, 29, 43)",
  },
}));

const PreferencesPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation();

  const deviceFields = [
    { id: "name", name: "sharedName", title: t("sharedName") },
    { id: "uniqueId", name: "deviceIdentifier", title: t("deviceIdentifier") },
    { id: "phone", name: "sharedPhone", title: t("sharedPhone") },
    { id: "model", name: "deviceModel", title: t("deviceModel") },
    { id: "contact", name: "deviceContact", title: t("deviceContact") },
  ];

  const deviceSecondaryFields = [
    { id: "name", name: "sharedName", title: t("sharedName") },
    { id: "uniqueId", name: "deviceIdentifier", title: t("deviceIdentifier") },
    { id: "driverName", name: "driverName", title: "Driver Name" },
    { id: "phone", name: "sharedPhone", title: t("sharedPhone") },
    { id: "model", name: "deviceModel", title: t("deviceModel") },
    { id: "contact", name: "deviceContact", title: t("deviceContact") },
  ];

  const readonly = useRestriction("readonly");

  const user = useSelector((state) => state.session.user);
  const [zoom, setZoom] = useState(user.zoom);
  const [attributes, setAttributes] = useState(user.attributes);

  const versionApp = process.env.REACT_APP_VERSION.slice(0, -2);
  const versionServer = useSelector((state) => state.session.server.version);
  const socket = useSelector((state) => state.session.socket);

  const [token, setToken] = useState(null);
  const [tokenExpiration, setTokenExpiration] = useState(
    moment().add(1, "week").locale("en").format(moment.HTML5_FMT.DATE)
  );

  const mapStyles = useMapStyles();
  const mapOverlays = useMapOverlays();

  const positionAttributes = usePositionAttributes(t);

  const filter = createFilterOptions();

  const generateToken = useCatch(async () => {
    const expiration = moment(
      tokenExpiration,
      moment.HTML5_FMT.DATE
    ).toISOString();
    const response = await fetch("/api/session/token", {
      method: "POST",
      body: new URLSearchParams(`expiration=${expiration}`),
    });
    if (response.ok) {
      setToken(await response.text());
    } else {
      throw Error(await response.text());
    }
  });

  const alarms = useTranslationKeys((it) => it.startsWith("alarm")).map(
    (it) => ({
      key: unprefixString("alarm", it),
      name: t(it),
    })
  );

  const handleSave = useCatch(async () => {
    const response = await fetch(`/api/users/${user.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...user, attributes, zoom }),
    });
    if (response.ok) {
      dispatch(sessionActions.updateUser(await response.json()));
      navigate(-1);
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <>
      <MainToolbar />
      <Container maxWidth="xs" className={classes.container}>
        <Accordion defaultExpanded>
          <AccordionSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="subtitle1">{t("userToken")}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <TextField
              label={t("userExpirationTime")}
              type="date"
              value={tokenExpiration}
              margin="normal"
              variant="filled"
              fullWidth
              onChange={(e) => {
                setTokenExpiration(e.target.value);
                setToken(null);
              }}
            />
            <FormControl fullWidth>
              <OutlinedInput
                multiline
                rows={6}
                readOnly
                type="text"
                margin="normal"
                variant="filled"
                fullWidth
                className={classes.token}
                value={token || ""}
                endAdornment={
                  <InputAdornment position="end">
                    <div className={classes.tokenActions}>
                      <IconButton
                        size="small"
                        edge="end"
                        onClick={generateToken}
                        disabled={!!token}
                      >
                        <CachedIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        edge="end"
                        onClick={() => navigator.clipboard.writeText(token)}
                        disabled={!token}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </InputAdornment>
                }
              />
            </FormControl>
          </AccordionDetails>
        </Accordion>
        {!readonly && (
          <>
            <Accordion>
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1">{t("mapTitle")}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel>{t("mapActive")}</InputLabel>
                  <Select
                    label={t("mapActive")}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    value={
                      attributes.activeMapStyles?.split(",") || [
                        "locationIqStreets",
                        "osm",
                        "carto",
                      ]
                    }
                    onChange={(e, child) => {
                      const clicked = mapStyles.find(
                        (s) => s.id === child.props.value
                      );
                      if (clicked.available) {
                        setAttributes({
                          ...attributes,
                          activeMapStyles: e.target.value.join(","),
                        });
                      } else if (clicked.id !== "custom") {
                        const query = new URLSearchParams({
                          attribute: clicked.attribute,
                        });
                        navigate(
                          `/settings/user/${user.id}?${query.toString()}`
                        );
                      }
                    }}
                    multiple
                  >
                    {mapStyles.map((style) => (
                      <MenuItem key={style.id} value={style.id}>
                        <Typography
                          component="span"
                          color={style.available ? "textPrimary" : "error"}
                        >
                          {style.title}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel>{t("mapOverlay")}</InputLabel>
                  <Select
                    label={t("mapOverlay")}
                    value={attributes.selectedMapOverlay || ""}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    onChange={(e) => {
                      const clicked = mapOverlays.find(
                        (o) => o.id === e.target.value
                      );
                      if (!clicked || clicked.available) {
                        setAttributes({
                          ...attributes,
                          selectedMapOverlay: e.target.value,
                        });
                      } else if (clicked.id !== "custom") {
                        const query = new URLSearchParams({
                          attribute: clicked.attribute,
                        });
                        navigate(
                          `/settings/user/${user.id}?${query.toString()}`
                        );
                      }
                    }}
                  >
                    <MenuItem value="">{"\u00a0"}</MenuItem>
                    {mapOverlays.map((overlay) => (
                      <MenuItem key={overlay.id} value={overlay.id}>
                        <Typography
                          component="span"
                          color={overlay.available ? "textPrimary" : "error"}
                        >
                          {overlay.title}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  margin="normal"
                  value={zoom || ""}
                  onChange={(event) => setZoom(event.target.value)}
                  label="Zoom"
                  variant="filled"
                  fullWidth
                />
                <TextField
                  margin="normal"
                  value={attributes.deviceZoom || ""}
                  onChange={(event) =>
                    setAttributes({
                      ...attributes,
                      deviceZoom: event.target.value,
                    })
                  }
                  label="Device Zoom"
                  variant="filled"
                  fullWidth
                />
                <Autocomplete
                  multiple
                  freeSolo
                  className={classes.autocomplete}
                  options={Object.keys(positionAttributes)}
                  getOptionLabel={(option) =>
                    positionAttributes.hasOwnProperty(option)
                      ? positionAttributes[option].name
                      : option
                  }
                  value={
                    attributes.positionItems?.split(",") || [
                      "speed",
                      "address",
                      "totalDistance",
                      "course",
                    ]
                  }
                  onChange={(_, option) => {
                    setAttributes({
                      ...attributes,
                      positionItems: option.join(","),
                    });
                  }}
                  ListboxProps={{
                    sx: {
                      border: "1px solid black",
                      backgroundColor: "#1F1D2B",
                      color: "#57c7d4",
                      "& li:hover": {
                        backgroundColor: "#12151e",
                        color: "#FFFFFF",
                      },
                    },
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (
                      params.inputValue &&
                      !filtered.includes(params.inputValue)
                    ) {
                      filtered.push(params.inputValue);
                    }
                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label={t("attributePopupInfo")}
                    />
                  )}
                />
                <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel>{t("mapLiveRoutes")}</InputLabel>
                  <Select
                    label={t("mapLiveRoutes")}
                    value={attributes.mapLiveRoutes || "none"}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    onChange={(e) =>
                      setAttributes({
                        ...attributes,
                        mapLiveRoutes: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="none">{t("sharedDisabled")}</MenuItem>
                    <MenuItem value="selected">{t("deviceSelected")}</MenuItem>
                    <MenuItem value="all">{t("notificationAlways")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel>{t("mapDirection")}</InputLabel>
                  <Select
                    label={t("mapDirection")}
                    value={attributes.mapDirection || "selected"}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    onChange={(e) =>
                      setAttributes({
                        ...attributes,
                        mapDirection: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="none">{t("sharedDisabled")}</MenuItem>
                    <MenuItem value="selected">{t("deviceSelected")}</MenuItem>
                    <MenuItem value="all">{t("notificationAlways")}</MenuItem>
                  </Select>
                </FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          attributes.hasOwnProperty("mapGeofences")
                            ? attributes.mapGeofences
                            : true
                        }
                        onChange={(e) =>
                          setAttributes({
                            ...attributes,
                            mapGeofences: e.target.checked,
                          })
                        }
                      />
                    }
                    label={t("attributeShowGeofences")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          attributes.hasOwnProperty("mapFollow")
                            ? attributes.mapFollow
                            : true
                        }
                        onChange={(e) =>
                          setAttributes({
                            ...attributes,
                            mapFollow: e.target.checked,
                          })
                        }
                      />
                    }
                    label={t("deviceFollow")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          attributes.hasOwnProperty("mapCluster")
                            ? attributes.mapCluster
                            : true
                        }
                        onChange={(e) =>
                          setAttributes({
                            ...attributes,
                            mapCluster: e.target.checked,
                          })
                        }
                      />
                    }
                    label={t("mapClustering")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          attributes.hasOwnProperty("mapOnSelect")
                            ? attributes.mapOnSelect
                            : true
                        }
                        onChange={(e) =>
                          setAttributes({
                            ...attributes,
                            mapOnSelect: e.target.checked,
                          })
                        }
                      />
                    }
                    label={t("mapOnSelect")}
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1">{t("deviceTitle")}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <SelectField
                  variant="filled"
                  margin="normal"
                  fullWidth
                  emptyValue={null}
                  value={attributes.devicePrimary || "name"}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                  onChange={(e) =>
                    setAttributes({
                      ...attributes,
                      devicePrimary: e.target.value,
                    })
                  }
                  data={deviceFields}
                  titleGetter={(it) => (!!t(it.name) ? t(it.name) : it.title)}
                  label={t("devicePrimaryInfo")}
                />
                <SelectField
                  emptyValue=""
                  variant="filled"
                  margin="normal"
                  fullWidth
                  value={attributes.deviceSecondary || ""}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                  onChange={(e) =>
                    setAttributes({
                      ...attributes,
                      deviceSecondary: e.target.value,
                    })
                  }
                  data={deviceSecondaryFields}
                  titleGetter={(it) => (!!t(it.name) ? t(it.name) : it.title)}
                  label={t("deviceSecondaryInfo")}
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          attributes.hasOwnProperty("calendar")
                            ? attributes.calendar
                            : true
                        }
                        onChange={(e) =>
                          setAttributes({
                            ...attributes,
                            calendar: e.target.checked,
                          })
                        }
                      />
                    }
                    label={"Show Calendar"}
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1">{t("sharedSound")}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <SelectField
                  multiple
                  variant="filled"
                  margin="normal"
                  fullWidth
                  value={attributes.soundEvents?.split(",") || []}
                  onChange={(e) =>
                    setAttributes({
                      ...attributes,
                      soundEvents: e.target.value.join(","),
                    })
                  }
                  endpoint="/api/notifications/types"
                  keyGetter={(it) => it.type}
                  titleGetter={(it) => t(prefixString("event", it.type))}
                  label={t("eventsSoundEvents")}
                />
                <SelectField
                  multiple
                  variant="filled"
                  margin="normal"
                  fullWidth
                  value={attributes.soundAlarms?.split(",") || ["sos"]}
                  onChange={(e) =>
                    setAttributes({
                      ...attributes,
                      soundAlarms: e.target.value.join(","),
                    })
                  }
                  data={alarms}
                  keyGetter={(it) => it.key}
                  label={t("eventsSoundAlarms")}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1">
                  {t("sharedInfoTitle")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TextField
                  value={versionApp}
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label={t("settingsAppVersion")}
                  readOnly
                />
                <TextField
                  value={versionServer || "-"}
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label={t("settingsServerVersion")}
                  readOnly
                />
                <TextField
                  value={
                    socket ? t("deviceStatusOnline") : t("deviceStatusOffline")
                  }
                  margin="normal"
                  variant="filled"
                  fullWidth
                  label={t("settingsConnection")}
                  readOnly
                />
              </AccordionDetails>
            </Accordion>
            <div className={classes.buttons}>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {t("sharedCancel")}
              </Button>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={handleSave}
              >
                {t("sharedSave")}
              </Button>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default PreferencesPage;
