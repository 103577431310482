import { grey } from "@mui/material/colors";

export default {
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: "#fff",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: grey[50],
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      sizeMedium: {
        height: "40px",
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      size: "small",
    },
  },
  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    },
  },
  MuiContainer: {
    defaultProps: {
      root: {
        fontSize: "14px",
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        fontSize: "14px",
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        // color: "red",
        // background: "black",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: "1px solid #3f403f !important",
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      expandIconWrapper: {
        color: "#ffffff",
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: "#ffffff",
        "&:disabled": {
          color: "#686868",
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        background: "#1F1D2B",
      },
    },
  },
};
