import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Grid,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  ButtonGroup,
  createTheme,
  Typography,
} from "@mui/material";
import { useTranslation } from "common/components/LocalizationProvider";
import { formatPosition } from "common/util/formatter";
import { useAttributePreference } from "common/util/preferences";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import usePositionAttributes from "../common/attributes/usePositionAttributes";
import _ from "lodash";
import PositionValue from "common/components/PositionValue";
import MaterialReactTable from "material-react-table";
import { useSelector } from "react-redux";
import EventIcon from "@mui/icons-material/Event";
import DateFnsUtils from "@date-io/date-fns";

import MainToolbar from "components/MainToolbar";
import moment from "moment";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles, ThemeProvider } from "@mui/styles";

// FOR DIALOG BOX

import MaterialTable from "material-table";

import { convertUnicode } from "reactHelper";

const materialTheme = createTheme({
  overrides: {
    // MuiPickersToolbar: {
    //   toolbar: {
    //     backgroundColor: "#3f51b5",
    //   },
    // },
    // MuiPickersCalendarHeader: {
    //   switchHeader: {
    //     // backgroundColor: lightBlue.A200,
    //     // color: "white",
    //   },
    // },
    // MuiPickersDay: {
    //   day: {
    //     color: "#3f51b5",
    //   },
    //   daySelected: {
    //     backgroundColor: "#3f51b5",
    //   },
    //   dayDisabled: {
    //     color: "#3f51b5",
    //   },
    //   current: {
    //     color: "#3f51b5",
    //   },
    // },
    MuiInputLabel: {
      root: {
        color: "#fff",
      },
    },
    MuiIconButton: {
      root: {
        color: "#2436ac",
      },
    },
    MuiInputBase: {
      input: {
        color: "#fff",
      },
      root: {
        color: "#fff",
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: "#000",
      },
    },
    // MuiPickersModal: {
    //   dialogAction: {
    //     color: "#3f51b5",
    //   },
    // },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& svg": {
      height: 30,
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  customDatePickerDialog: {
    "& .MuiDialogContent-root": {
      background: "#0f0e15 !important",
      color: "red",
    },
    "& .MuiDialog-paperScrollPaper": {
      background: "#1F1D2B",
      color: "#fff",
    },
    "& .MuiDayPicker-weekDayLabel": {
      color: "#fff",
    },
    "& .MuiPickersCalendarHeader-switchViewButton": {
      color: "#fff",
    },
    "& .MuiPickersArrowSwitcher-button": {
      color: "#fff",
    },
    "& .MuiInputLabel-formControl": {
      color: "#fff",
    },
    "& .MuiPickersToolbar-root": {
      background: "#3f51b5",
      color: "#fff",
    },
    "& .MuiPickersToolbar-root span": {
      color: "#fff",
    },
    "& .PrivatePickersToolbarText-root": {
      color: "#fff",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#f50057",
    },
    "& .MuiTabs-flexContainer": {
      background: "#3f51b5",
      color: "#fff",
    },
    "& .MuiTabs-flexContainer button": {
      color: "#fff",
    },
    "& .MuiDialogActions-root button": {
      color: "#3f51b5",
    },
    "& .MuiPickersDay-root": {
      color: "#fff",
      background: "none",
    },
    "& .Mui-selected.MuiPickersDay-root": {
      color: "#fff",
      background: "#3f51b5",
    },
    "& .MuiClock-amButton": {
      color: "#fff",
    },
    "& .MuiClock-pmButton": {
      color: "#fff",
    },
    "& .MuiClockPicker-root": {
      background: "#0f0e15",
    },
    "& .MuiClockNumber-root": {
      color: "#fff",
    },
    "& .MuiClockNumber-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiCalendarOrClockPicker-mobileKeyboardInputView .MuiInputBase-root": {
      color: "#fff",
    },
    "& .MuiCalendarOrClockPicker-mobileKeyboardInputView label": {
      color: "#fff",
    },
  },
}));

mapboxgl.accessToken = process.env.REACT_APP_LIVE_MAP_BOX_TOKEN;

const RouteReportPage = () => {
  const t = useTranslation();
  const positionAttributes = usePositionAttributes(t);
  // const [map, setMap] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const mapContainer = useRef(null);
  const user = useSelector((state) => Object.values(state.session.user));
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [address, setAddress] = useState();
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const progressRef = React.useRef(() => {});

  const devices = useSelector((state) => Object.values(state.devices.items));
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const [deviceId, setDeviceId] = useState(selectedDeviceId);
  const [period, setPeriod] = useState("today");
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [selectedColumnsAttributes, setSelectedColumnsAttributes] = useState(
    []
  );

  const [open, setOpen] = React.useState([]);
  const [height, setheight] = useState(window.innerHeight);
  const map = useRef(null);

  const marker = useRef(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [forIndex, setForIndex] = useState();
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const mapboxAccessToken = useAttributePreference("mapboxAccessToken");

  const handleSubmit = async (deviceId, from, to, mail, headers, ext) => {
    setLoader(true);
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
    const query = new URLSearchParams({ deviceId, from, to, mail });
    const response = await fetch(`/api/reports/route?${query.toString()}`, {
      headers,
    });
    if (response.ok) {
      setLoader(false);
      const contentType = response.headers.get("content-type");
      if (contentType) {
        if (contentType === "application/json") {
          setLoading(true);
          let res = await response.json();
          // debugger
          if (ext === "OnlyMoving") {
            let filtered = res.filter((x) => x.speed >= 1);
            setItems(filtered);
          } else {
            setItems(res);
          }
          setLoading(false);
        } else {
          const device = _.find(devices, { id: deviceId });
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          const url = window.URL.createObjectURL(await response.blob());
          a.href = url;
          a.download = `${device?.name} - ${to}.xlsx`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
      }
    }
  };

  const handleClick = (mail, json, ext) => {
    let selectedFrom;
    let selectedTo;
    switch (period) {
      case "today":
        selectedFrom = moment().startOf("day");
        selectedTo = moment().endOf("day");
        break;
      case "yesterday":
        selectedFrom = moment().subtract(1, "day").startOf("day");
        selectedTo = moment().subtract(1, "day").endOf("day");
        break;
      case "thisWeek":
        selectedFrom = moment().startOf("week");
        selectedTo = moment().endOf("week");
        break;
      case "previousWeek":
        selectedFrom = moment().subtract(1, "week").startOf("week");
        selectedTo = moment().subtract(1, "week").endOf("week");
        break;
      case "thisMonth":
        selectedFrom = moment().startOf("month");
        selectedTo = moment().endOf("month");
        break;
      case "previousMonth":
        selectedFrom = moment().subtract(1, "month").startOf("month");
        selectedTo = moment().subtract(1, "month").endOf("month");
        break;
      default:
        // from.setHours(0, 0, 0, 0);
        selectedFrom = from;
        // to.setHours(23, 59, 59, 999);
        selectedTo = to;
        break;
    }

    const accept = json
      ? "application/json"
      : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    handleSubmit(
      deviceId,
      selectedFrom.toISOString(),
      selectedTo.toISOString(),
      mail,
      { Accept: accept },
      ext
    );
  };

  // const fetchAddress = (latitude, longitude, index) => {
  //   fetch(`https://icu-protection.com/reverse/api/v1.0/reverse?lat=${latitude}&long=${longitude}`)
  //   .then(res => res.json())
  //   .then(
  //   (result) => {
  //     const copied_address = [...address];
  //     copied_address.push({'key' : index, 'address': result.data});
  //     console.log('copied_address', copied_address);
  //     setAddress(copied_address);
  //   },
  //   (error) => {
  //     const copied_address = [...address];
  //     copied_address.push({'key': index, 'address':''});
  //     setAddress(copied_address);
  //   }
  // )
  // }

  const handleClickOpen = (index, lat, long, color) => {
    map.current.setCenter([long, lat]);
    if (marker.current) {
      marker.current.remove();
    }
    marker.current = new mapboxgl.Marker({ color })
      .setLngLat([long, lat])
      .addTo(map.current);
  };

  const handleShowAddress = (e, index) => {
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_LIVE_MAP_BOX_TOKEN}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setAddress(result.features[0].place_name);
          setForIndex(index);
        },
        (error) => {
          // console.log(error);
        }
      );
    if (e) {
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (map.current) return; // wait for map to initialize
    map.current = new mapboxgl.Map({
      container: "map", // container id
      // style: "mapbox://styles/mapbox/light-v9", // stylesheet localization
      style: "mapbox://styles/tonypeter1/ckiguc7qu5xxv19pbji4pep9w",
      center: [0, 0], // [13.392, 52.523], // starting position [lng, lat]
      zoom: user[11], // starting zoom
    });

    map.current.addControl(new mapboxgl.NavigationControl());

    /* MAP */
    return () => {};
  }, [null]);
  const handleClose = (index) => {
    setOpen([]);
  };

  useEffect(() => {
    // let defaultColumns = [
    //   {
    //     header: "Driver Id",
    //     accessorKey: "attributes.driverUniqueId",
    //     Cell: ({ renderedCellValue, row }) => (
    //       <>{row?.original?.attributes?.driverUniqueId}</>
    //     ),
    //   },
    //   {
    //     header: "Time/Date",
    //     accessorKey: "fixTime",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatPosition(row?.original, "fixTime"),
    //   },
    //   {
    //     header: t("positionLatitude"),
    //     accessorKey: "latitude",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatPosition(row?.original, "latitude"),
    //   },
    //   {
    //     header: t("positionLongitude"),
    //     accessorKey: "longitude",
    //     Cell: ({ renderedCellValue, row }) =>
    //       formatPosition(row?.original, "longitude"),
    //   },
    //   {
    //     header: t("positionAltitude"),
    //     accessorKey: "altitude",
    //     Cell: ({ renderedCellValue, row }) => <>{row?.original?.altitude}</>,
    //   },
    //   {
    //     header: t("positionSpeed"),
    //     accessorKey: "speed",
    //     Cell: ({ renderedCellValue, row }) =>
    //       row.speed > 1
    //         ? `${formatPosition(row, "speed")} km/h`
    //         : "0" + " km/h",
    //   },
    //   {
    //     header: "Temperature",
    //     accessorKey: "attributes.temp1",
    //     Cell: ({ renderedCellValue, row }) =>
    //       row.attributes.temp1 > 1
    //         ? `${row.attributes.temp1.toFixed(1)}C`
    //         : "0 C",
    //   },
    //   {
    //     header: t("positionAddress"),
    //     accessorKey: "serverTime",

    //     Cell: ({ renderedCellValue, row }) => {
    //       const index = row.original.id;
    //       // const latitude = rowData.latitude;
    //       // const longitude = rowData.longitude;

    //       return (
    //         <Address
    //           rowData={row}
    //           selectedRow={selectedRow}
    //           address={forIndex === index ? address : null}
    //           cb={(e) => handleShowAddress(e, index)}
    //         />
    //       );
    //     },
    //   },
    // ];

    let defaultColumns = ["latitude", "longitude", "speed", "id"].map(
      (item) => {
        let itemObj = {
          header: positionAttributes[item]?.name || item,
          accessorKey: item,
          Cell: ({ renderedCellValue, row }) => {
            return (
              <PositionValue
                position={row.original}
                property={row.original.hasOwnProperty(item) ? item : null}
                attribute={row.original.hasOwnProperty(item) ? null : item}
              />
            );
          },
        };

        return itemObj;
      }
    );

    let selectedColumns = selectedColumnsAttributes.map((item) => {
      let itemObj = {
        header: positionAttributes[item]?.name || item,
        accessorKey: item,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <PositionValue
              position={row.original}
              property={row.original.hasOwnProperty(item) ? item : null}
              attribute={row.original.hasOwnProperty(item) ? null : item}
            />
          );
        },
      };

      return itemObj;
    });

    let columnsTemp = selectedColumnsAttributes.length
      ? selectedColumns
      : defaultColumns;
    setColumns(columnsTemp);
  }, [selectedColumnsAttributes]);

  useEffect(() => {
    let device = _.find(devices, { id: deviceId });
    if (device && !!device?.attributes?.routeReportColumns) {
      let routeReportCoulmnsTemp = _.filter(
        device?.attributes?.routeReportColumns.split(","),
        (item) => item != "deviceTime"
      );
      setSelectedColumnsAttributes(["deviceTime", ...routeReportCoulmnsTemp]);
    } else {
      setSelectedColumnsAttributes([]);
    }
  }, [deviceId]);

  return (
    <div className={classes.root}>
      <MainToolbar />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={2}>
            <Paper className={classes.form}>
              <FormControl variant="filled" margin="normal" fullWidth>
                <InputLabel>Vehicle</InputLabel>
                <Select
                  value={deviceId}
                  onChange={(e) => {
                    setDeviceId(e.target.value);
                    setItems([]);
                  }}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  {_.orderBy(
                    devices,
                    [(item) => item.name.toLowerCase()],
                    ["asc"]
                  ).map((device) => (
                    <MenuItem value={device.id}>
                      {convertUnicode(device.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="filled" margin="normal" fullWidth>
                <InputLabel>{t("reportPeriod")}</InputLabel>
                <Select
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  <MenuItem value="today">{t("reportToday")}</MenuItem>
                  <MenuItem value="yesterday">{t("reportYesterday")}</MenuItem>
                  <MenuItem value="thisWeek">{t("reportThisWeek")}</MenuItem>
                  <MenuItem value="previousWeek">
                    {t("reportPreviousWeek")}
                  </MenuItem>
                  <MenuItem value="thisMonth">{t("reportThisMonth")}</MenuItem>
                  <MenuItem value="previousMonth">
                    {t("reportPreviousMonth")}
                  </MenuItem>
                  <MenuItem value="custom">{t("reportCustom")}</MenuItem>
                </Select>
              </FormControl>
              {period === "custom" && (
                <ThemeProvider theme={materialTheme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDateTimePicker
                      className={classes.customDatePicker}
                      label={t("reportFrom")}
                      inputFormat="DD/MM/YYYY hh:mm a"
                      value={from}
                      onChange={(date) => {
                        setFrom(date);
                      }}
                      DialogProps={{
                        className: classes.customDatePickerDialog,
                      }}
                      renderInput={(params) => (
                        <TextField
                          margin="normal"
                          variant="filled"
                          className={classes.datePickerInput}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end">
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...params}
                        />
                      )}
                    />
                    <MobileDateTimePicker
                      className={classes.customDatePicker}
                      label={t("reportTo")}
                      inputFormat="DD/MM/YYYY hh:mm a"
                      value={to}
                      minDate={from}
                      onChange={(date) => setTo(date)}
                      DialogProps={{
                        className: classes.customDatePickerDialog,
                      }}
                      renderInput={(params) => (
                        <TextField
                          margin="normal"
                          variant="filled"
                          className={classes.datePickerInput}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end">
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              )}

              <FormControl margin="normal" fullWidth>
                <ButtonGroup
                  color="primary"
                  orientation="vertical"
                  disabled={!deviceId}
                >
                  <Button onClick={() => handleClick(false, true)}>
                    Show All
                  </Button>
                  <Button
                    onClick={() => handleClick(false, true, "OnlyMoving")}
                  >
                    Show Moving
                  </Button>
                  <Button onClick={() => handleClick(false, false)}>
                    {t("reportExport")}
                  </Button>
                  <Button onClick={() => handleClick(true, false)}>
                    {t("reportEmail")}
                  </Button>
                </ButtonGroup>
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <div style={{ height: height - 100 }}>
              <div style={{ height: "50%", marginTop: 2, marginBottom: 2 }}>
                <div>
                  {loader ? (
                    <LinearProgress
                      variant="buffer"
                      value={progress}
                      valueBuffer={buffer}
                    />
                  ) : (
                    ""
                  )}
                  <MaterialReactTable
                    columns={columns}
                    data={items
                      .slice(0, 4000)
                      .sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0))}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enablePagination={items.length > 50}
                    enableSorting={true}
                    enableBottomToolbar={true}
                    enableTopToolbar={true}
                    enableStickyHeader
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [50],
                    }}
                    renderTopToolbarCustomActions={() => (
                      <Typography
                        variant="h6"
                        style={{
                          color: "#00ac69",
                          paddingLeft: "20px",
                          paddingRight: "5px",
                        }}
                      >
                        Route Report
                      </Typography>
                    )}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: "#1F1D2B",
                        color: "#FFFFFF",
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: "#1F1D2B",
                        color: "#FFFFFF",
                      },
                    }}
                    muiTableHeadProps={{
                      sx: {
                        backgroundColor: "#1F1D2B",
                        color: "#FFFFFF",
                      },
                    }}
                    muiTableContainerProps={{
                      sx: {
                        color: "#57c7d4",
                        overflowY: "scroll",
                        height: height / 2 - 178,
                      },
                    }}
                    muiTableHeadRowProps={({ table, headerGroup }) => ({
                      sx: {
                        backgroundColor: "#1F1D2B",
                        color: "#FFFFFF",
                      },
                    })}
                    muiTableBodyRowProps={({ row }) => ({
                      onClick: (event) => {
                        document.querySelector("#map").scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                        setAddress(null);
                        setSelectedRow(row.original.id);
                        setLongitude(row.original.longitude);
                        setLatitude(row.original.latitude);
                        setIsReady(true);

                        handleClickOpen(
                          row.original.id,
                          row.original.latitude,
                          row.original.longitude,
                          row.original.speed === 0 ? "green" : "blue"
                        );
                      },
                      sx: {
                        cursor: "pointer",
                        backgroundColor:
                          selectedRow === row.original.id ||
                          selectedRow === row.id
                            ? "#0d0d0d"
                            : "",
                      },
                    })}
                  />
                </div>
              </div>

              <div
                style={{
                  height: "50%",
                  marginTop: 2,
                  marginBottom: 2,
                  position: "relative",
                }}
              >
                {/* <div
                  id="map"
                  ref={mapContainer}
                  style={{ height: "100%", width: "100%" }}
                ></div> */}
                <DetailPenal
                  address={address}
                  cb={(e) => {
                    handleShowAddress(e, selectedRow);
                  }}
                  mapContainer={mapContainer}
                  isReady={isReady}
                />
              </div>
            </div>
            {/* <div ref={el => (mapContainer.current = el)} style={styles} /> */}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const DetailPenal = ({ address, mapContainer, isReady, cb }) => (
  <React.Fragment>
    <>
      {isReady ? (
        <>
          {address ? (
            <p
              style={{
                color: "white",
                marginLeft: 20,
                position: "absolute",
                top: "--8px",
                zIndex: 1,
                backgroundColor: "#1f1d2b",
                width: "fit-content",
                padding: "5px",
                left: "-12px",
                boxShadow: "0px 1px 8px black",
                borderRadius: "8px",
              }}
            >
              {address}
            </p>
          ) : (
            <Button
              onClick={(e) => cb(e)}
              style={{
                color: "white",
                marginLeft: 20,
                position: "absolute",
                top: "8px",
                zIndex: 1,
                backgroundColor: "#1f1d2b",
                width: "fit-content",
                padding: "5px",
                left: "-12px",
                boxShadow: "0px 1px 8px black",
                borderRadius: "0px",
              }}
            >
              {" "}
              Show Address{" "}
            </Button>
          )}
        </>
      ) : (
        ""
      )}
    </>
    <div
      ref={(el) => (mapContainer.current = el)}
      id="map"
      style={{
        width: "100%",
        height: "calc(50vh - 80px)",
        visibility: isReady ? "visible" : "hidden",
      }}
    />
  </React.Fragment>
);

const Address = ({ rowData, address, cb, selectedRow }) => (
  <a
    onClick={(e) => {
      cb(e);
    }}
  >
    {rowData?.tableData?.id == selectedRow ? (
      <> {address ? <>{address}</> : "Show Address"} </>
    ) : (
      ""
    )}
  </a>
);
export default RouteReportPage;
