import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "notification",
  initialState: {
    notifications: {},
    IgnitionState: false,
    SpeedingAlarmState: "0",
    CustomAlertNotification: "",
  },
  reducers: {
    update(state, action) {
      action.payload.forEach(
        (item) => (state.notifications[item.deviceId] = item)
      );
    },
    updateIgnitionState(state, action) {
      state.IgnitionState = action.payload;
    },
    updateSpeedingAlarmState(state, action) {
      state.SpeedingAlarmState = action.payload;
    },
    SetCustomAlertNotification(state, action) {
      state.CustomAlertNotification = action.payload;
    },
  },
});

export { actions as notificationActions };
export { reducer as notificationReducer };
