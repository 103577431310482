import React from "react";

const TemperatureMeterView = ({ temp }) => (
  <div>
    <div className="temp-meter-wrap">
      <div className="temp-meter-svg">
        <img src="/temp-img.svg" alt="Temp Meter" />
      </div>
      <div className="temp-info">
        <h2>{temp} C</h2>
        <p>Refrigeration Temperature</p>
      </div>
    </div>
  </div>
);

export default TemperatureMeterView;
