import { makeStyles } from "@mui/styles";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef } from "react";
import { useAttributePreference } from "common/util/preferences";
import { styleMapbox1, styleMapbox } from "common/util/mapStyle";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    "& svg": {
      height: 30,
    },
  },
}));

const StopReportMapContainer = ({ isReady, index, lat, long, color }) => {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const marker = useRef(null);
  const mapboxAccessToken = useAttributePreference("mapboxAccessToken");

  const classes = useStyles();

  const handleClickOpen = (index, lat, long, color) => {
    if (!map.current) return; // wait for map to initialize
    map.current.setCenter([long, lat]);
    if (marker.current) {
      marker.current.remove();
    }
    marker.current = new mapboxgl.Marker({ color })
      .setLngLat([long, lat])
      .addTo(map.current);
  };

  useEffect(() => {
    handleClickOpen(index, lat, long, color);
    return () => {
      if (marker.current) {
        marker.current.remove();
      }
    };
  }, [index, lat, long, color]);

  // useEffect(() => {
  //   if(mapboxAccessToken && mapboxAccessToken.length > 0){
  //      mapboxgl.accessToken = window.atob(mapboxAccessToken);
  //   } else {
  //     mapboxgl.accessToken = '12345';
  //   }
  // }, [mapboxAccessToken])

  useEffect(() => {
    document
      .querySelector("#map")
      .scrollIntoView({ behavior: "smooth", block: "start" });
    mapboxgl.accessToken = process.env.REACT_APP_LIVE_MAP_BOX_TOKEN;

    if (map.current) return; // wait for map to initialize
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: styleMapbox1("tonypeter1/ckiguc7qu5xxv19pbji4pep9w"),
      zoom: 14,
      center: [0, 0],
    });
    handleClickOpen(index, lat, long, color);
  }, [isReady]);
  return (
    <div
      className={classes.root}
      id="map"
      ref={mapContainer}
      style={{
        height: "100%",
        width: "100%",
        visibility: isReady ? "visible" : "hidden",
      }}
    />
  );
};

export default StopReportMapContainer;
