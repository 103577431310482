import { useId, useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import { map } from "./core/MapView";
import { formatTime, getStatusColor } from "../common/util/formatter";
import { mapIconKey } from "./core/preloadImages";
import { findFonts } from "./core/mapUtil";
import {
  useAttributePreference,
  usePreference,
} from "../common/util/preferences";

const MapPositions = ({
  positions,
  onClick,
  showStatus,
  selectedPosition,
  titleField,
}) => {
  const id = useId();
  const clusters = `${id}-clusters`;
  const direction = `${id}-direction`;

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const iconScale = useAttributePreference("iconScale", desktop ? 0.75 : 1);

  const devices = useSelector((state) => state.devices.items);

  const mapCluster = useAttributePreference("mapCluster", true);
  const hours12 = usePreference("twelveHourFormat");
  const directionType = useAttributePreference("mapDirection", "selected");
  const selectedGroupFilter = useSelector(
    (state) => state.setting.selectedGroupFilter
  );
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const [filteredDevices, setFilteredDevices] = useState([]);

  useEffect(() => {
    if (selectedDeviceId) {
      setFilteredDevices([selectedDeviceId]);
    } else {
      const filteredDevicesArr =
        selectedGroupFilter == 0 || !selectedGroupFilter
          ? devices
          : _.filter(
              devices,
              (o) =>
                (!!o?.attributes?.groupIds &&
                  o?.attributes?.groupIds.includes(selectedGroupFilter)) ||
                o.groupId == selectedGroupFilter
            );
      const filteredDevicesId = _.map(filteredDevicesArr, "id");
      setFilteredDevices(filteredDevicesId);
    }
  }, [selectedDeviceId, positions, selectedGroupFilter]);

  const getStatusCategory = (status, deviceId) => {
    const found_pos = _.find(positions, { deviceId });
    if (found_pos) {
      if (status == "online" && found_pos.attributes.motion == true) {
        return "moving";
      }
      if (status == "online") {
        return "online";
      }
      if (status == "offline") {
        return "offline";
      }
      if (status == "unknown") {
        return "unknown";
      }
      return "unknown";
    }
    return "offline";
  };

  const createFeature = (devices, position, selectedPositionId) => {
    const device = devices[position.deviceId];

    const statusCategory = getStatusCategory(device.status, device.id);
    let showDirection;
    switch (directionType) {
      case "none":
        showDirection = false;
        break;
      case "all":
        showDirection = false;
        break;
      default:
        showDirection = false;
        break;
    }

    return {
      id: position.id,
      deviceId: position.deviceId,
      name: device.name,
      fixTime: formatTime(position.fixTime, "seconds", hours12),
      category: mapIconKey(statusCategory),
      color: showStatus
        ? position.attributes.color || getStatusColor(device.status)
        : "neutral",
      rotation: position.course,
      direction: showDirection,
    };
  };

  const onMouseEnter = () => (map.getCanvas().style.cursor = "pointer");
  const onMouseLeave = () => (map.getCanvas().style.cursor = "");

  const onMapClick = useCallback(
    (event) => {
      if (!event.defaultPrevented && onClick) {
        onClick();
      }
    },
    [onClick]
  );

  const onMarkerClick = useCallback(
    (event) => {
      event.preventDefault();
      const feature = event.features[0];
      if (onClick) {
        onClick(feature.properties.id, feature.properties.deviceId);
      }
    },
    [onClick]
  );

  const onClusterClick = useCallback(
    (event) => {
      event.preventDefault();
      const features = map.queryRenderedFeatures(event.point, {
        layers: [clusters],
      });
      const clusterId = features[0].properties.cluster_id;
      map.getSource(id).getClusterExpansionZoom(clusterId, (error, zoom) => {
        if (!error) {
          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom,
          });
        }
      });
    },
    [clusters]
  );

  useEffect(() => {
    map.addSource(id, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
      cluster: mapCluster,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });
    map.addLayer({
      id,
      type: "symbol",
      source: id,
      filter: ["!has", "point_count"],
      layout: {
        "icon-anchor": "center",
        "icon-image": "{category}-{color}",
        "icon-size": iconScale,
        "icon-allow-overlap": true,
        "icon-offset": [0, -20],
        "text-field": `{${titleField || "name"}}`,
        "text-allow-overlap": false,
        "text-anchor": "bottom",
        "text-justify": "center",
        "text-offset": [0, 2],
        "text-font": findFonts(map),
        "text-size": 14,
        "text-max-width": 12,
      },
      paint: {
        "text-halo-color": "#FFFF8A",
        "text-halo-width": 15,
        "text-halo-blur": 5,
      },
    });
    map.addLayer({
      id: clusters,
      type: "symbol",
      source: id,
      filter: ["has", "point_count"],
      layout: {
        "icon-image": "background",
        "icon-size": iconScale,
        "text-field": "{point_count_abbreviated}",
        "text-font": findFonts(map),
        "text-size": 14,
      },
    });
    map.addLayer({
      id: direction,
      type: "symbol",
      source: id,
      filter: ["all", ["!has", "point_count"], ["==", "direction", true]],
      layout: {
        "icon-image": "direction",
        "icon-size": iconScale,
        "icon-allow-overlap": true,
        "icon-rotate": ["get", "rotation"],
        "icon-rotation-alignment": "map",
      },
    });

    map.on("mouseenter", id, onMouseEnter);
    map.on("mouseleave", id, onMouseLeave);
    map.on("mouseenter", clusters, onMouseEnter);
    map.on("mouseleave", clusters, onMouseLeave);
    map.on("click", id, onMarkerClick);
    map.on("click", clusters, onClusterClick);
    map.on("click", onMapClick);

    return () => {
      map.off("mouseenter", id, onMouseEnter);
      map.off("mouseleave", id, onMouseLeave);
      map.off("mouseenter", clusters, onMouseEnter);
      map.off("mouseleave", clusters, onMouseLeave);
      map.off("click", id, onMarkerClick);
      map.off("click", clusters, onClusterClick);
      map.off("click", onMapClick);

      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getLayer(clusters)) {
        map.removeLayer(clusters);
      }
      if (map.getLayer(direction)) {
        map.removeLayer(direction);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [mapCluster, clusters, direction, onMarkerClick, onClusterClick]);

  useEffect(() => {
    if (positions.length) {
      const filteredPositions =
        !(selectedGroupFilter == 0) || selectedDeviceId
          ? _.filter(positions, (obj) => filteredDevices.includes(obj.deviceId))
          : positions;

      map.getSource(id)?.setData({
        type: "FeatureCollection",
        features: filteredPositions
          .filter((it) => devices.hasOwnProperty(it.deviceId))
          .map((position) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [position.longitude, position.latitude],
            },
            properties: createFeature(
              devices,
              position,
              selectedPosition && selectedPosition.id
            ),
          })),
      });
    }
  }, [
    mapCluster,
    clusters,
    direction,
    onMarkerClick,
    onClusterClick,
    devices,
    positions,
    filteredDevices,
    selectedPosition,
  ]);

  return null;
};

export default MapPositions;
