import React, { useState } from "react";
import {
  TextField,
  Paper,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  ButtonGroup,
  Button,
} from "@mui/material";
import { AddCircle, Edit, Save, Delete } from "@mui/icons-material";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import { DialogBox, useEffectAsync } from "reactHelper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  formatDistance,
  formatSpeed,
  numberDistanceToStringCommaSeprated,
} from "common/util/formatter";
import makeStyles from "@mui/styles/makeStyles";
import { useAttributePreference } from "common/util/preferences";
import { useTranslation } from "common/components/LocalizationProvider";
import usePositionAttributes from "common/attributes/usePositionAttributes";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    display: "flex",
    // padding: "15px",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  columnAction: {
    // width: theme.spacing(1),
    // padding: theme.spacing(0, 1),
  },
  textfieldClass: {
    "& .MuiInput-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
  },
  btn: {
    display: "flex",
    "& div": {
      margin: "5px",
    },
  },
}));
const MaintenanceTabPage = () => {
  const t = useTranslation();
  const positionAttributes = usePositionAttributes(t);
  const classes = useStyles();
  const speedUnit = useAttributePreference("speedUnit");
  const distanceUnit = useAttributePreference("distanceUnit");
  const [ShowAddMaintenanceContainer, setShowAddMaintenanceContainer] =
    useState(false);
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const [deviceId, setDeviceId] = useState(selectedDeviceId);

  const userId = useSelector(
    (state) => state.session.user && state.session.user.id
  );
  const [items, setItems] = useState([]);

  const [IsEdit, setIsEdit] = useState(false);
  const [height, setheight] = useState(window.innerHeight);
  const [notificationId, setNotificationId] = useState(null);
  const [SelectedRow, setSelectedRow] = useState();
  const [AddNewObject, setAddNewObject] = useState({
    id: 0,
    name: null,
    type: "odometer",
    start: null,
    period: null,
    attributes: {},
  });

  const [NotificationObject, setNotificationObject] = useState({
    id: -1,
    always: false,
    type: "maintenance",
    calendarId: 0,
    attributes: {},
    notificators: [],
  });

  const Position = useSelector((state) => {
    if (state.devices.selectedId) {
      const position =
        state.session.positions[state.devices.selectedId] || null;
      return position;
    }
    return null;
  });

  const convertAttribute = (key, value) => {
    // debugger;
    const attribute = positionAttributes[key];
    if (attribute && attribute.dataType) {
      switch (attribute.dataType) {
        case "speed":
          return formatSpeed(value, speedUnit);
        case "distance":
          return formatDistance(value, distanceUnit);
        default:
          return value;
      }
    }

    return value;
  };

  useEffectAsync(async () => {
    if (Position) {
      setAddNewObject({
        ...AddNewObject,
        start: Position.attributes.totalDistance,
        hoursStart: Position.attributes.hours,
      });
    }
    await handleClick();
  }, []);

  useEffectAsync(async () => {
    if (selectedDeviceId) {
      const res = await fetch(
        `/api/notifications?deviceId=${selectedDeviceId}`,
        {
          method: "GET",
        }
      );

      if (res.ok) {
        const data = await res.json();

        let maintentanceRequiredNotification = _.orderBy(
          _.filter(data, { type: "maintenance" }),
          ["id"],
          ["desc"]
        );

        let maintenanceRequiredOutput = _.first(
          maintentanceRequiredNotification
        );

        if (!!maintenanceRequiredOutput) {
          if (!!maintenanceRequiredOutput?.notificators) {
            let NotificationObjectData = { ...NotificationObject };
            NotificationObjectData.notificators =
              maintenanceRequiredOutput?.notificators;
            setNotificationId(maintenanceRequiredOutput?.id);
            setNotificationObject(NotificationObjectData);
          }
        }
      }
    }
  }, [selectedDeviceId]);

  const EditMaintenances = async () => {
    const obj = { ...AddNewObject };

    if (obj.type === "hours") {
      obj.start = obj.start;
      obj.period =
        (obj.attributes.period - obj.attributes.notify) * 3600 * 1000;
    } else {
      obj.period = (obj.attributes.period - obj.attributes.notify) * 1000;
    }

    const response = await fetch(`/api/maintenance/${AddNewObject.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    });

    HandleMaintainenceNotification();
    // debugger;
    if (response.ok) {
      formReset();

      handleClick();
      // var arr = [];
      // setItems(arr.push(await response.json()));
    }
  };

  const handleNotificationChange = (event) => {
    let notifiers = Array.isArray(NotificationObject.notificators)
      ? NotificationObject.notificators
      : NotificationObject.notificators.split(",");
    notifiers = notifiers.filter((item) => item);

    if (event.target.checked) {
      notifiers.push(event.target.name);
    } else {
      const index = notifiers.indexOf(event.target.name);
      if (index > -1) {
        notifiers.splice(index, 1);
      }
    }
    const NotificationObjectData = { ...NotificationObject };
    NotificationObjectData.notificators = notifiers;
    setNotificationObject({ ...NotificationObjectData });
  };

  const formReset = () => {
    setIsEdit(false);
    setSelectedRow(null);
    setShowAddMaintenanceContainer(false);
    setAddNewObject({
      id: 0,
      name: null,
      type: "odometer",
      period: null,
      start: Number(Position.attributes.totalDistance),
      attributes: { period: 0, notify: 0 },
    });
  };
  const AddMaintenances = async () => {
    // debugger
    const obj = { ...AddNewObject };

    if (obj.type === "hours") {
      obj.start = obj.start;
      obj.period =
        (obj.attributes.period - obj.attributes.notify) * 3600 * 1000;
    } else {
      obj.period = (obj.attributes.period - obj.attributes.notify) * 1000;
    }
    const response = await fetch("/api/maintenance", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    });
    // debugger;
    if (response.ok) {
      const resp = await response.json();

      await fetch("/api/permissions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId,
          maintenanceId: resp.id,
        }),
      });

      HandleMaintainenceNotification();

      await fetch("/api/permissions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          deviceId,
          maintenanceId: resp.id,
        }),
      });
      formReset();
      handleClick();
      // var arr = [];
      // setItems(arr.push(await response.json()));
    }
  };

  const HandleMaintainenceNotification = async () => {
    const NotificationObjectData = { ...NotificationObject };
    NotificationObjectData.notificators = Array.isArray(
      NotificationObjectData.notificators
    )
      ? NotificationObjectData.notificators.join(",")
      : NotificationObjectData.notificators;

    if (notificationId) {
      NotificationObjectData.id = notificationId;
      const notificationResponse = await fetch(
        `/api/notifications/${notificationId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(NotificationObjectData),
        }
      );

      if (notificationResponse.ok) {
        const notificationResp = await notificationResponse.json();
        await fetch("/api/permissions", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId,
            notificationId: notificationResp?.id,
          }),
        });
      }
    } else {
      const notificationResponse = await fetch("/api/notifications", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(NotificationObjectData),
      });

      if (notificationResponse.ok) {
        const notificationResp = await notificationResponse.json();
        await fetch("/api/permissions", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId,
            notificationId: notificationResp?.id,
          }),
        });
      }
    }
  };

  const handleClick = async () => {
    const response = await fetch(
      `/api/maintenance?userId=${userId}&deviceId=${deviceId}`
    );
    if (response.ok) {
      setItems(await response.json());
    }
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} lg={3}>
          <Paper className={classes.form}>
            <div>
              <ButtonGroup color="primary">
                <Button
                  color="primary"
                  startIcon={<AddCircle />}
                  onClick={() => {
                    setSelectedRow(null);
                    formReset();
                    setShowAddMaintenanceContainer(
                      !ShowAddMaintenanceContainer
                    );
                  }}
                >
                  {IsEdit ? "Edit" : "Add"}
                </Button>
              </ButtonGroup>
              {ShowAddMaintenanceContainer && (
                <Grid container xs={12} md={12} lg={12}>
                  <>
                    <TextField
                      margin="normal"
                      type="text"
                      onChange={(event) =>
                        setAddNewObject({
                          ...AddNewObject,
                          name: event.target.value,
                        })
                      }
                      label="Enter Name"
                      value={AddNewObject.name}
                      variant="filled"
                      fullWidth
                    />

                    {/* <Grid item xs={3} md={3} lg={3}>
                  <FormControl variant="filled" margin="normal" fullWidth>
                    <InputLabel>Select Type</InputLabel>

                    <Select
                      value={AddNewObject.type}
                      onChange={(event) =>
                        setAddNewObject({
                          ...AddNewObject,
                          type: event.target.value,
                        })
                      }
                      MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    >
                      <MenuItem value="Scheduled Service">
                        Scheduled Service
                      </MenuItem>
                      <MenuItem value="General Repairs ">
                        General Repairs{" "}
                      </MenuItem>
                      <MenuItem value="General Maintenance ">
                        General Maintenance{" "}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                    <FormControl variant="filled" margin="normal" fullWidth>
                      <InputLabel>Select Period</InputLabel>

                      <Select
                        value={AddNewObject.type}
                        onChange={(event) =>
                          setAddNewObject({
                            ...AddNewObject,
                            type: event.target.value,
                            start:
                              event.target.value == "odometer"
                                ? Position.attributes.totalDistance
                                : Position.attributes.hours,
                          })
                        }
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        <MenuItem value="odometer">odometer</MenuItem>
                        <MenuItem value="hours">hours</MenuItem>
                      </Select>
                    </FormControl>
                    {AddNewObject.type === "odometer" ? (
                      <TextField
                        margin="normal"
                        className={classes.textfieldClass}
                        type="number"
                        value={Number.parseFloat(
                          AddNewObject.start * 0.001
                        ).toFixed(2)}
                        // value={formatDistance(AddNewObject.start,distanceUnit)}
                        inputProps={{ readOnly: true }}
                        label="Start"
                        variant="filled"
                        fullWidth
                      />
                    ) : (
                      <TextField
                        margin="normal"
                        className={classes.textfieldClass}
                        type="text"
                        value={Number.parseFloat(
                          Math.round(AddNewObject.start) / 1000 / 60 / 60
                        ).toFixed(2)}
                        onChange={(event) =>
                          setAddNewObject({
                            ...AddNewObject,
                            start: event.target.value,
                          })
                        }
                        label="Start"
                        inputProps={{ readOnly: true }}
                        variant="filled"
                        fullWidth
                      />
                    )}

                    {AddNewObject.type === "odometer" ? (
                      <FormControl variant="filled" margin="normal" fullWidth>
                        <InputLabel>Select Period</InputLabel>

                        <Select
                          value={AddNewObject?.attributes?.period * 1000}
                          onChange={(event) =>
                            setAddNewObject({
                              ...AddNewObject,
                              attributes: {
                                ...AddNewObject?.attributes,
                                period: event.target.value / 1000,
                              },
                            })
                          }
                          MenuProps={{
                            classes: { paper: classes.dropdownStyle },
                          }}
                        >
                          <MenuItem value="500000">500 KM</MenuItem>
                          <MenuItem value="1000000">1,000 KM</MenuItem>
                          <MenuItem value="1500000">1,500 KM</MenuItem>
                          <MenuItem value="2000000">2,000 KM</MenuItem>
                          <MenuItem value="2500000">2,500 KM</MenuItem>
                          <MenuItem value="3000000">3,000 KM</MenuItem>
                          <MenuItem value="3500000">3,500 KM</MenuItem>
                          <MenuItem value="4000000">4,000 KM</MenuItem>
                          <MenuItem value="4500000">4,500 KM</MenuItem>
                          <MenuItem value="5000000">5,000 KM</MenuItem>
                          <MenuItem value="5500000">5,500 KM</MenuItem>
                          <MenuItem value="6000000">6000 KM</MenuItem>
                          <MenuItem value="6500000">6500 KM</MenuItem>
                          <MenuItem value="7000000">7000 KM</MenuItem>
                          <MenuItem value="7500000">7500 KM</MenuItem>
                          <MenuItem value="8000000">8000 KM</MenuItem>
                          <MenuItem value="8500000">8500 KM</MenuItem>
                          <MenuItem value="9000000">9000 KM</MenuItem>
                          <MenuItem value="9500000">9500 KM</MenuItem>
                          <MenuItem value="10000000">10,000 KM</MenuItem>
                          <MenuItem value="10500000">10,500 KM</MenuItem>
                          <MenuItem value="11000000">11,000 KM</MenuItem>
                          <MenuItem value="11500000">11,500 KM</MenuItem>
                          <MenuItem value="12000000">12,000 KM</MenuItem>
                          <MenuItem value="12500000">12,500 KM</MenuItem>
                          <MenuItem value="13000000">13,000 KM</MenuItem>
                          <MenuItem value="13500000">13,500 KM</MenuItem>
                          <MenuItem value="14000000">14,000 KM</MenuItem>
                          <MenuItem value="14500000">14,500 KM</MenuItem>
                          <MenuItem value="15000000">15,000 KM</MenuItem>
                          <MenuItem value="15500000">15,500 KM</MenuItem>
                          <MenuItem value="16000000">16,000 KM</MenuItem>
                          <MenuItem value="16500000">16,500 KM</MenuItem>
                          <MenuItem value="17000000">17,000 KM</MenuItem>
                          <MenuItem value="17500000">17,500 KM</MenuItem>
                          <MenuItem value="18000000">18,000 KM</MenuItem>
                          <MenuItem value="18500000">18,500 KM</MenuItem>
                          <MenuItem value="19000000">19,000 KM</MenuItem>
                          <MenuItem value="19500000">19,500 KM</MenuItem>
                          <MenuItem value="20000000">20,000 KM</MenuItem>
                          <MenuItem value="20500000">20,500 KM</MenuItem>
                          <MenuItem value="21000000">21,000 KM</MenuItem>
                          <MenuItem value="21500000">21,500 KM</MenuItem>
                          <MenuItem value="22000000">22,000 KM</MenuItem>
                          <MenuItem value="22500000">22,500 KM</MenuItem>
                          <MenuItem value="23000000">23,000 KM</MenuItem>
                          <MenuItem value="23500000">23,500 KM</MenuItem>
                          <MenuItem value="24000000">24,000 KM</MenuItem>
                          <MenuItem value="24500000">24,500 KM</MenuItem>
                          <MenuItem value="25000000">25,000 KM</MenuItem>
                          <MenuItem value="25500000">25,500 KM</MenuItem>
                          <MenuItem value="26000000">26,000 KM</MenuItem>
                          <MenuItem value="26500000">26,500 KM</MenuItem>
                          <MenuItem value="27000000">27,000 KM</MenuItem>
                          <MenuItem value="27500000">27,500 KM</MenuItem>
                          <MenuItem value="28000000">28,000 KM</MenuItem>
                          <MenuItem value="28500000">28,500 KM</MenuItem>
                          <MenuItem value="29000000">29,000 KM</MenuItem>
                          <MenuItem value="29500000">29,500 KM</MenuItem>
                          <MenuItem value="30000000">30,000 KM</MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        margin="normal"
                        className={classes.textfieldClass}
                        type="text"
                        value={
                          AddNewObject?.attributes?.period
                            ? Number(Math.round(AddNewObject.attributes.period))
                            : 0
                        }
                        onChange={(event) => {
                          if (!isNaN(event.target.value)) {
                            setAddNewObject({
                              ...AddNewObject,
                              attributes: {
                                ...AddNewObject?.attributes,
                                period: event.target.value,
                              },
                            });
                          }
                        }}
                        // value={formatDistance(AddNewObject.start,distanceUnit)}
                        label="Hours"
                        variant="filled"
                        fullWidth
                      />
                    )}

                    <FormControl variant="filled" margin="normal" fullWidth>
                      <InputLabel>Select Notify Before</InputLabel>
                      {AddNewObject.type == "odometer" ? (
                        <Select
                          value={Number(AddNewObject?.attributes?.notify)}
                          onChange={(event) => {
                            setAddNewObject({
                              ...AddNewObject,
                              attributes: {
                                ...AddNewObject?.attributes,
                                notify: event.target.value,
                              },
                            });
                          }}
                          MenuProps={{
                            classes: { paper: classes.dropdownStyle },
                          }}
                        >
                          <MenuItem value="100">100 KM</MenuItem>
                          <MenuItem value="200">200 KM</MenuItem>
                          <MenuItem value="300">300 KM</MenuItem>
                          <MenuItem value="400">400 KM</MenuItem>
                          <MenuItem value="500">500 KM</MenuItem>
                          <MenuItem value="600">600 KM</MenuItem>
                          <MenuItem value="700">700 KM</MenuItem>
                          <MenuItem value="800">800 KM</MenuItem>
                          <MenuItem value="900">900 KM</MenuItem>
                          <MenuItem value="1000">1000 KM</MenuItem>
                        </Select>
                      ) : (
                        <Select
                          value={Number(AddNewObject?.attributes?.notify)}
                          onChange={(event) => {
                            setAddNewObject({
                              ...AddNewObject,
                              attributes: {
                                ...AddNewObject?.attributes,
                                notify: event.target.value,
                              },
                            });
                          }}
                          MenuProps={{
                            classes: { paper: classes.dropdownStyle },
                          }}
                        >
                          <MenuItem value="50">50 Hr</MenuItem>
                          <MenuItem value="100">100 Hr</MenuItem>
                          <MenuItem value="150">150 Hr</MenuItem>
                          <MenuItem value="200">200 Hr</MenuItem>
                          <MenuItem value="250">250 Hr</MenuItem>
                          <MenuItem value="300">300 Hr</MenuItem>
                          <MenuItem value="350">350 Hr</MenuItem>
                          <MenuItem value="400">400 Hr</MenuItem>
                          <MenuItem value="450">450 Hr</MenuItem>
                          <MenuItem value="500">500 Hr</MenuItem>
                        </Select>
                      )}
                    </FormControl>
                    <FormControl
                      sx={{ m: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel component="legend">
                        Notification Type
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                NotificationObject.notificators.indexOf(
                                  "mail"
                                ) > -1
                              }
                              onChange={handleNotificationChange}
                              name="mail"
                            />
                          }
                          label="Mail"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                NotificationObject.notificators.indexOf("web") >
                                -1
                              }
                              onChange={handleNotificationChange}
                              name="web"
                            />
                          }
                          label="Web"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                NotificationObject.notificators.indexOf("sms") >
                                -1
                              }
                              onChange={handleNotificationChange}
                              name="sms"
                            />
                          }
                          label="SMS"
                        />
                        {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                NotificationObject.notificators.indexOf(
                                  "firebase"
                                ) > -1
                              }
                              onChange={handleNotificationChange}
                              name="firebase"
                            />
                          }
                          label="Push"
                        /> */}
                      </FormGroup>
                    </FormControl>
                  </>
                  <Grid xs={12}>
                    {AddNewObject.name &&
                      AddNewObject.type &&
                      (IsEdit ? (
                        <div className={classes.btn}>
                          <FormControl margin="normal" fullWidth>
                            <ButtonGroup color="primary" orientation="vertical">
                              <Button
                                onClick={() => EditMaintenances()}
                                color="primary"
                                startIcon={<Edit />}
                              >
                                Save Changes
                              </Button>
                            </ButtonGroup>
                            <ButtonGroup color="primary" orientation="vertical">
                              <Button
                                onClick={() => formReset()}
                                color="secondary"
                              >
                                Cancel
                              </Button>
                            </ButtonGroup>
                          </FormControl>
                        </div>
                      ) : (
                        <FormControl margin="normal" fullWidth>
                          <ButtonGroup color="primary">
                            <Button
                              onClick={() => AddMaintenances()}
                              color="secondary"
                              startIcon={<Save />}
                            >
                              Save
                            </Button>
                          </ButtonGroup>
                        </FormControl>
                      ))}
                  </Grid>
                </Grid>
              )}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={7} lg={9}>
          <div className={classes.root}>
            <MaterialTable
              columns={[
                {
                  title: t("sharedName"),
                  field: "name",
                  render: (rowData) => rowData.name,
                },
                {
                  title: t("sharedType"),
                  field: "type",
                  render: (rowData) => rowData.type,
                },
                {
                  title: t("maintenanceStart"),
                  field: "start",
                  render: (rowData) => {
                    if (rowData.type === "hours") {
                      return (
                        `${(Math.round(rowData.start) / 1000 / 60 / 60).toFixed(
                          2
                        )} ` + "Hr"
                      );
                    }
                    return `${Math.round(rowData.start * 0.001)} ${t(
                      "sharedKm"
                    )}`;
                  },
                },
                {
                  title: t("maintenancePeriod"),
                  field: "period",
                  render: (rowData) => {
                    if (rowData.type === "hours") {
                      return `${Number(rowData?.attributes?.period)} ` + "Hr";
                    }
                    return rowData?.attributes?.period;
                  },
                },
                {
                  title: "Notify Before",
                  field: "Notify",
                  render: (rowData) => rowData?.attributes?.notify,
                },
                {
                  title: "Action",
                  field: "Edit / Delete",
                  render: (rowData) => {
                    const EditCB = async (e) => {
                      setSelectedRow(rowData.tableData.id);
                      setIsEdit(true);
                      setShowAddMaintenanceContainer(
                        !ShowAddMaintenanceContainer
                      );
                      setAddNewObject({
                        name: rowData.name,
                        type: rowData.type,
                        start: rowData?.start ? rowData.start : 0,
                        period: rowData.period,
                        id: rowData.id,
                        attributes: rowData.attributes,
                      });
                      e.stopPropagation();
                    };

                    const DeleteCb = async (e) => {
                      const response = await fetch(
                        `/api/maintenance/${rowData.id}`,
                        {
                          method: "DELETE",
                        }
                      );
                      if (response) {
                        // formReset();
                        e.stopPropagation();
                        handleClick();
                      }
                      e.stopPropagation();
                    };
                    return (
                      <div style={{ display: "flex" }}>
                        <EditView cb={EditCB} />
                        <DeleteView cb={DeleteCb} />
                      </div>
                    );
                  },
                },
              ]}
              data={items.sort((a, b) =>
                a.id < b.id ? 1 : b.id < a.id ? -1 : 0
              )}
              title=""
              // onRowClick={(event, rowData) => {
              //   //  onMenuClick(event.currentTarget, rowData.tableData.id)
              //   console.log(rowData);
              //   setSelectedRow(rowData.tableData.id);
              //   setIsEdit(true);
              //   setShowAddMaintenanceContainer(!ShowAddMaintenanceContainer);
              //   setAddNewObject({
              //     name: rowData.name,
              //     type: rowData.type,
              //     start: rowData.start,
              //     period: rowData.period,
              //     id: rowData.id,
              //   });
              // }}
              options={{
                headerStyle: {
                  backgroundColor: "#1F1D2B",
                  color: "#FFFFFF",
                },
                sorting: true,
                paging: false,
                /* maxBodyHeight: calcHeight+'px', */
                defaultSort: "desc",
                rowStyle: (rowData) => ({
                  backgroundColor:
                    SelectedRow === rowData.tableData.id ? "#0d0d0d" : "",
                }),
              }}
              style={{
                color: "#57c7d4",
                overflowY: "scroll",
                height: height - 230,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const EditView = ({ cb }) => {
  const edit = (e) => {
    e.stopPropagation();
    cb(e);
  };
  return (
    <div
      style={{
        padding: "3px",
        margin: "0px 10px",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <EditOutlinedIcon onClick={(e) => edit(e)} />
    </div>
  );
};

const DeleteView = ({ cb }) => {
  const [Dialog, setDialog] = useState({
    isOpen: false,
    handleSuccess: null,
    handleClose: null,
    decription: "Description",
    title: "titile",
    type: "conform",
    closeBtnText: "Close",
    confmBtnText: "Agree",
    handleDisagreeClose: null,
  });
  const deleteRow = (e) => {
    const fun = async () => {
      setDialog({ isOpen: false });
      await cb(e);
    };

    e.stopPropagation();
    setDialog({
      isOpen: true,
      handleClose: () => setDialog({ isOpen: false }),
      handleDisagreeClose: () => {
        setDialog({ isOpen: false });
        // setMileage(ValueBuffer);
      },
      handleSuccess: fun,
      decription: "Are you sure you want to delete this record  ?",
      title: "Alert !!",
      type: "conform",
      confmBtnText: "Yes  ",
      closeBtnText: "No ",
    });
    // setDialog({...Dialog,handleSuccess:() => {cb(e)}})
  };

  return (
    <div
      style={{
        padding: "3px",
        margin: "0px 10px",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <DialogBox
        handleDisagreeClose={Dialog.handleDisagreeClose}
        handleClose={Dialog.handleClose}
        handleSuccess={Dialog.handleSuccess}
        open={Dialog.isOpen}
        decription={Dialog.decription}
        title={Dialog.title}
        type={Dialog.type}
        closeBtnText={Dialog.closeBtnText}
        confmBtnText={Dialog.confmBtnText}
      />
      <DeleteOutlineOutlinedIcon onClick={(e) => deleteRow(e)} />
    </div>
  );
};

export default MaintenanceTabPage;
