import React, { useRef, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";
import { errorsActions } from "./store";

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/* eslint-disable */
export const useEffectAsync = (effect, deps) => {
  const dispatch = useDispatch();
  const ref = useRef();
  useEffect(() => {
    effect()
      .then((result) => (ref.current = result))
      .catch((error) => dispatch(errorsActions.push(error.message)));

    return () => {
      const result = ref.current;
      if (result) {
        result();
      }
    };
  }, [...deps, dispatch]);
};

export const useCatch = (method) => {
  const dispatch = useDispatch();
  return (...parameters) => {
    method(...parameters).catch((error) =>
      dispatch(errorsActions.push(error.message))
    );
  };
};

export const useCatchCallback = (method, deps) => {
  return useCallback(useCatch(method), deps);
};

export const DialogBox = ({
  handleClose,
  handleSuccess,
  open,
  decription,
  title,
  type,
  closeBtnText,
  confmBtnText,
  handleDisagreeClose,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "green",
      "& .MuiPaper-root .MuiDialog-paperScrollPaper .MuiDialog-paper .MuiDialogTitle-root":
        {
          backgroundColor: "#0f0e15",
        },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{ backgroundColor: "#0f0e15" }}
        >
          {title}
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#0f0e15" }}>
          <DialogContentText id="alert-dialog-slide-description">
            {decription}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#0f0e15" }}>
          {type === "conform" ? (
            <React.Fragment>
              <Button onClick={handleDisagreeClose} color="primary">
                {closeBtnText}
              </Button>
              <Button onClick={handleSuccess} color="primary">
                {confmBtnText}
              </Button>
            </React.Fragment>
          ) : (
            <Button onClick={handleClose} color="primary">
              {closeBtnText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const convertUnicode = (input) => {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(
    `<!doctype html><body>${input}`,
    "text/html"
  ).body.textContent;
  return decodedString;
};
