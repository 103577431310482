import React, { useState } from "react";
import MainToolbar from "components/MainToolbar";
import makeStyles from "@mui/styles/makeStyles";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Grid,
  Paper,
  FormControl,
  Button,
  TextField,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "common/components/LocalizationProvider";
import { useEffectAsync, DialogBox } from "reactHelper";
import EditCollectionView from "EditCollectionView";
import MaterialTable from "material-table";
import { AddCircle, Edit, Save } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
}));

const GroupsView = ({ updateTimestamp, onMenuClick }) => {
  const classes = useStyles();
  const t = useTranslation();

  const [items, setItems] = useState([]);
  const [GroupContainer, setShowGroupContainer] = useState(false);
  const [GroupObject, setGroupObject] = useState({
    id: 0,
    name: null,
    groupId: 0,
    attributes: {},
  });

  const [IsEdit, setIsEdit] = useState(false);

  useEffectAsync(async () => {
    await getAllGroup();
  }, [updateTimestamp]);

  const ResetForm = () => {
    setGroupObject({
      id: 0,
      name: null,
      groupId: null,
      attributes: {},
    });
    setShowGroupContainer(false);
  };

  const getAllGroup = async () => {
    const response = await fetch("/api/groups");
    if (response.ok) {
      setItems(await response.json());
    }
  };
  const EditGroup = () => {
    fetch(`/api/groups/${GroupObject.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(GroupObject),
    })
      .then((res) => res.json())
      .then((result) => {
        setIsEdit(false);
        ResetForm();
        getAllGroup();
      });
  };

  const addNewGroup = () => {
    fetch("/api/groups", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(GroupObject),
    })
      .then((res) => res.json())
      .then((result) => {
        ResetForm();
        getAllGroup();
      });
  };

  const EditView = ({ cb }) => {
    const edit = (e) => {
      e.stopPropagation();
      cb(e);
    };
    return (
      <div
        style={{
          padding: "3px",
          margin: "0px 10px",
          borderRadius: "10px",
          cursor: "pointer",
        }}
      >
        <EditOutlinedIcon onClick={(e) => edit(e)} />
      </div>
    );
  };

  const DeleteView = ({ cb }) => {
    const [Dialog, setDialog] = useState({
      isOpen: false,
      handleSuccess: null,
      handleClose: null,
      decription: "Description",
      title: "titile",
      type: "conform",
      closeBtnText: "Close",
      confmBtnText: "Agree",
      handleDisagreeClose: null,
    });
    const deleteRow = (e) => {
      const fun = async () => {
        setDialog({ isOpen: false });
        await cb(e);
      };

      e.stopPropagation();
      setDialog({
        isOpen: true,
        handleClose: () => setDialog({ isOpen: false }),
        handleDisagreeClose: () => {
          setDialog({ isOpen: false });
          // setMileage(ValueBuffer);
        },
        handleSuccess: fun,
        decription: "Are you sure you want to delete this record  ?",
        title: "Alert !!",
        type: "conform",
        confmBtnText: "Yes  ",
        closeBtnText: "No ",
      });
      // setDialog({...Dialog,handleSuccess:() => {cb(e)}})
    };

    return (
      <div
        style={{
          padding: "3px",
          margin: "0px 10px",
          borderRadius: "10px",
          cursor: "pointer",
        }}
      >
        <DialogBox
          handleDisagreeClose={Dialog.handleDisagreeClose}
          handleClose={Dialog.handleClose}
          handleSuccess={Dialog.handleSuccess}
          open={Dialog.isOpen}
          decription={Dialog.decription}
          title={Dialog.title}
          type={Dialog.type}
          closeBtnText={Dialog.closeBtnText}
          confmBtnText={Dialog.confmBtnText}
        />
        <DeleteOutlineOutlinedIcon onClick={(e) => deleteRow(e)} />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={2}>
            <Paper className={classes.form}>
              <FormControl margin="normal" fullWidth>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddCircle />}
                  onClick={() => {
                    setShowGroupContainer(!GroupContainer);
                  }}
                >
                  {IsEdit ? "Edit Group " : "Add Group "}
                </Button>
                {GroupContainer && (
                  <>
                    <TextField
                      margin="normal"
                      type="text"
                      onChange={(event) =>
                        setGroupObject({
                          ...GroupObject,
                          name: event.target.value,
                        })
                      }
                      label="Group Name"
                      value={GroupObject.name}
                      variant="filled"
                    />

                    {GroupObject.name ? (
                      IsEdit ? (
                        <Button
                          onClick={() => EditGroup()}
                          color="secondary"
                          variant="contained"
                          startIcon={<Edit />}
                        >
                          Save Changes
                        </Button>
                      ) : (
                        <Button
                          onClick={() => addNewGroup()}
                          color="secondary"
                          variant="contained"
                          startIcon={<Save />}
                        >
                          Save
                        </Button>
                      )
                    ) : (
                      ""
                    )}
                  </>
                )}
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <MaterialTable
              columns={[
                {
                  title: t("sharedName"),
                  field: "name",
                  render: (rowData) => rowData.name,
                },
                {
                  title: "Action",
                  field: "Edit / Delete",
                  render: (rowData) => {
                    const EditCB = (e) => {
                      // setSelectedRow(rowData.tableData.id);
                      setGroupObject({
                        ...GroupObject,
                        name: rowData.name,
                        groupId: rowData.groupId,
                        id: rowData.id,
                      });
                      setIsEdit(true);
                      setShowGroupContainer(true);

                      // setIsEdit(true);
                      // setShowAddMaintenanceContainer(!ShowAddMaintenanceContainer);
                      // setAddNewObject({
                      //   name: rowData.name,
                      //   type: rowData.type,
                      //   start: rowData.start,
                      //   period: rowData.period,
                      //   id: rowData.id,
                      // });
                      e.stopPropagation();
                    };

                    const DeleteCb = async (e) => {
                      const response = await fetch(
                        `/api/groups/${rowData.id}`,
                        {
                          method: "DELETE",
                        }
                      );
                      if (response) {
                        // formReset();
                        e.stopPropagation();
                        getAllGroup();
                      }
                      e.stopPropagation();
                    };
                    return (
                      <div style={{ display: "flex" }}>
                        <EditView cb={EditCB} />
                        <DeleteView cb={DeleteCb} />
                      </div>
                    );
                  },
                },
              ]}
              data={_.orderBy(
                items,
                [(item) => item.name.toLowerCase()],
                ["asc"]
              )}
              title=""
              // icons={tableIcons}
              // onRowClick={(event, rowData) => {
              //   //setselectedDriverId(rowData.id);

              // }}
              options={{
                // exportButton: true,
                headerStyle: {
                  backgroundColor: "#1F1D2B",
                  color: "#FFFFFF",
                },
                sorting: true,
                paging: false,
                /* maxBodyHeight: calcHeight+'px', */
                defaultSort: "desc",
                rowStyle: (rowData) => ({
                  // backgroundColor:  (selectedRow === rowData.tableData.id) ? '#0d0d0d' : ''
                }),
              }}
              style={{
                color: "#57c7d4",
                // overflowY: "scroll",
                // height: height / 2 - 60,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>

    // <TableContainer>
    // <Table>
    //   <TableHead>
    //     <TableRow>
    //       <TableCell className={classes.columnAction} />
    //       <TableCell>{t('sharedName')}</TableCell>
    //     </TableRow>
    //   </TableHead>
    //   <TableBody>
    //     {items.map((item) => (
    //       <TableRow key={item.id}>
    //         <TableCell className={classes.columnAction} padding="none">
    //           <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.id)}>
    //             <MoreVertIcon />
    //           </IconButton>
    //         </TableCell>
    //         <TableCell>{item.name}</TableCell>
    //       </TableRow>
    //     ))}
    //   </TableBody>
    // </Table>
    // </TableContainer>
  );
};

const GroupsPage = () => (
  <>
    <MainToolbar />
    <EditCollectionView
      content={GroupsView}
      editPath="/settings/group"
      endpoint="groups"
    />
  </>
);

export default GroupsPage;
