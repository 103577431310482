import React from "react";
import { useMediaQuery, Paper, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material/styles";
import Copyright from "components/Copyright";
import Greetings from "components/Greetings";
import moment from "moment";
import LogoImage from "./LogoImage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: theme.palette.primary.background,
    height: "100%",
  },
  sidebar: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    background: theme.palette.colors.sidebarGrey,
    paddingBottom: theme.spacing(5),
    width: theme.dimensions.sidebarWidth,
    maxWidth: "600px",
    [theme.breakpoints.down("md")]: {
      alignItems: "flex-start",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.default,
    flex: 1,
    boxShadow: "-2px 0px 16px rgba(0, 0, 0, 0.25)",
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 25, 0, 0),
    },
  },
  form: {
    maxWidth: "618px",
    padding: theme.spacing(5),
    width: "100%",
    paddingRight: " 4rem !important",
    paddingLeft: " 4rem !important",
    [theme.breakpoints.down("md")]: {
      maxWidth: " 100%",
      paddingLeft: " 1.2rem !important",
      paddingRight: " 1.2rem !important",
      display: " flex",
      flexDirection: " column",
    },
  },
  Copyright: {
    color: "#c6c4c4",
    fontSize: "smaller",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <main className={classes.root}>
      <div className={classes.sidebar}>
        <form className={classes.form}>
          <LogoImage color={theme.palette.secondary.contrastText} />
          {!useMediaQuery(theme.breakpoints.down("sm")) && (
            <Box my={4}>
              <Greetings />
            </Box>
          )}
          {children}
          <Box mt={8}>
            <Copyright className={classes.Copyright} />
          </Box>
        </form>
      </div>
      {!useMediaQuery(theme.breakpoints.down("lg")) && (
        <Paper className={classes.paper} />
      )}
    </main>
  );
};

export default LoginLayout;
