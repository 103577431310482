import { createSlice } from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
  name: "setting",
  initialState: {
    SleepMode: false,
    SpeedingAlarm: 0,
    GroupId: "",
    Ignition: false,
    TempAlarm: 0,
    IsSleepModeVisible: false,
    IsSpeedingAlarmVisible: false,
    isSetIgnitionVisible: false,
    isTempVisible: false,
    PurchasedDevices: [],
    GroupList: [],
    Attributes: {},
    isDriverVisible: false,
    isMaintenanceVisible: false,
    isMileageVisible: false,
    isHourVisible: false,
    isDriverBehaviourVisible: false,
    isGroupsVisible: false,
    isNotificationsVisible: false,
    isPreferenceVisible: false,
    hoursEnabled: false,
    selectedGroupFilter: 0,
    isMapResetEnabled: true,
    isTempMapVisible: true,
    isCalendarVisible: false,
    isAssignedGroupVisible: false,
  },
  reducers: {
    setPurchasedDevices(state, action) {
      state.PurchasedDevices = action.payload;
    },
    setSelectedGroupFilter(state, action) {
      state.selectedGroupFilter = action.payload;
    },
    SetIsSleepModeVisible(state, action) {
      state.IsSleepModeVisible = action.payload;
    },
    SetIsSpeedingAlarmVisible(state, action) {
      state.IsSpeedingAlarmVisible = action.payload;
    },
    SetHoursEnabled(state, action) {
      state.hoursEnabled = action.payload;
    },
    SetisSetIgnitionVisible(state, action) {
      state.isSetIgnitionVisible = action.payload;
    },
    SetisTempVisible(state, action) {
      state.isTempVisible = action.payload;
    },
    SetisTempMapVisible(state, action) {
      state.isTempMapVisible = action.payload;
    },
    SetisGroupVisible(state, action) {
      state.isGroupVisible = action.payload;
    },
    SetIsMapResetEnabled(state, action) {
      state.isMapResetEnabled = action.payload;
    },
    SetisPreferenceVisible(state, action) {
      state.isPreferenceVisible = action.payload;
    },
    SetisHourVisible(state, action) {
      state.isHourVisible = action.payload;
    },
    SetisCalendarVisible(state, action) {
      state.isCalendarVisible = action.payload;
    },
    SetisAssignedGroupVisible(state, action) {
      state.isAssignedGroupVisible = action.payload;
    },
    SetisMileageVisible(state, action) {
      state.isMileageVisible = action.payload;
    },
    UpdateSleepMode(state, action) {
      state.SleepMode = action.payload;
    },
    UpdateSpeedingAlarm(state, action) {
      state.SpeedingAlarm = action.payload;
    },
    UpdateGroupId(state, action) {
      state.GroupId = action.payload;
    },
    UpdateAttributes(state, action) {
      state.Attributes = action.payload;
    },
    UpdateIgnition(state, action) {
      state.Ignition = action.payload;
    },
    UpdateTempAlarm(state, action) {
      state.TempAlarm = action.payload;
    },
    UpdateDriverVisible(state, action) {
      state.isDriverVisible = action.payload;
    },

    UpdateMaintenanceVisible(state, action) {
      state.isMaintenanceVisible = action.payload;
    },

    UpdateDriverBehaviourVisible(state, action) {
      state.isDriverBehaviourVisible = action.payload;
    },

    UpdateGroupsVisible(state, action) {
      state.isGroupsVisible = action.payload;
    },
    UpdateGroupList(state, action) {
      state.GroupList = action.payload;
    },

    UpdateNotificationsVisible(state, action) {
      state.isNotificationsVisible = action.payload;
    },
  },
});

export { actions as settingActions };
export { reducer as settingReducer };
