import React from "react";
import { Grid, Paper } from "@mui/material";
import MainToolbar from "components/MainToolbar";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
    height: "100%",
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
}));

const ReportLayoutPage = ({ children, filter, Map }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MainToolbar />
      <div className={classes.content}>
        <Grid container spacing={2} style={{ height: "100%" }}>
          <Grid item xs={12} md={3} lg={2}>
            <Paper className={classes.form}>{filter}</Paper>
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            lg={10}
            style={{ height: "90vh", overflow: "auto" }}
          >
            <div style={{ height: "50%", marginTop: 2, marginBottom: 2 }}>
              {children}
            </div>

            <div style={{ height: "50%", marginTop: 2, marginBottom: 2 }}>
              {Map}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ReportLayoutPage;
