import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from "@mui/styles/makeStyles";
import { Button, ButtonGroup, TextField } from "@mui/material";
import { AddCircle, Close } from "@mui/icons-material";

const DialBox = ({ handleSubmit }) => {
  const [content, setContent] = useState("");
  const handlePadInput = (buttonText) => {
    if (buttonText === "X") {
      setContent("");
    } else if (buttonText === "OK") {
      handleSubmit(content);
    } else {
      let new_content = content + buttonText;
      new_content = new_content.length > 4 ? content : new_content;

      setContent(new_content);
    }
  };

  return (
    <div id="padContainer">
      <Display content={content} />
      <DialPad
        onPadInput={handlePadInput}
        handleSubmit={handleSubmit}
        content={content}
      />
    </div>
  );
};
const Display = ({ content }) => <div className="display">{content}</div>;
const DialPad = ({ onPadInput }) => {
  const buttonContents = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "X",
    "0",
    "OK",
  ];
  const row_item_count = 3;
  const buttonRow = []; // _.chunk(buttonContents, row_item_count);
  for (let i = 0; i < buttonContents.length; i += row_item_count) {
    buttonRow.push(buttonContents.slice(i, i + row_item_count));
  }
  return (
    <div>
      {buttonRow.map((row) => (
        <DialRow rowButtons={row} onRowButtonClick={onPadInput} />
      ))}
    </div>
  );
};

const DialRow = ({ onRowButtonClick, rowButtons }) => (
  <div>
    {rowButtons.map((itemText) => (
      <DialButton text={itemText} onButtonClick={onRowButtonClick} />
    ))}
  </div>
);

const DialButton = ({ onButtonClick, text }) => {
  // propTypes : {
  //   onClick: PropTypes.func.isRequired
  // },
  const handleClick = () => {
    onButtonClick(text);
  };

  return (
    <button id="button" onClick={handleClick}>
      {text}{" "}
    </button>
  );
};

const NumberPad = ({ handleSubmit }) => {
  const useStyles = makeStyles(() => ({
    root: {
      "& .numpad": {
        // marginTop: "2em",
        marginRight: "auto",
        marginLeft: "auto",

        width: " 17em",
        height: " 24em",
        // backgroundColor: "#000"
      },
      "& table": {
        width: "inherit",
        height: "inherit",
        padding: "0.5em",
        borderSpacing: "0.3em",
      },
      "& .btn": {
        margin: "0.2em",
        padding: 0,
        position: "relative",
        borderRadius: "6px",
        backgroundColor: "#1a1925",
        cursor: "pointer",
        userSelect: "none",
      },
      /* http://tobiasahlin.com/blog/how-to-animate-box-shadow/ */
      "& .btn::after": {
        content: "",
        width: "100%",
        height: "100%",
        zIndex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        borderRadius: "6px",
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.7)",
        opacity: 1,
      },
      "& .btn.square": {
        width: "4em",
        height: "4em",
      },
      "& .btn:hover": { backgroundColor: "#454545" },

      "& .btn.active .btn:active": { backgroundColor: "#4B4B4B" },
      "& .btn.active::after  .btn:active::after": { opacity: 0 },

      "& .btn > .text": {
        position: "absolute",
        top: 0,
        left: "0.3em",

        color: "#d2d2d2",
        fontSize: "0.85em",
        textTransform: "uppercase",
      },
      "& .key-press-area": {
        marginTop: "1em",
        marginBottom: "1em",
        textAlign: "center",
        opacity: 0,
      },
      "& .key-press-area.visible": { opacity: 1 },

      "& a": {
        color: "blue",
        textDecoration: "underline",
      },
      "& a:hover a:active": { textDecoration: "none" },
      "& .MuiButton-root.Mui-disabled": {
        color: "#888888 !important",
        backgroundColor: "#313030 !important",
      },
      resize: {
        fontSize: 50,
      },
    },
  }));

  const [content, setContent] = useState("");
  const handlePadInput = (buttonText) => {
    if (buttonText === "X") {
      setContent("");
    } else if (buttonText === "OK") {
      handleSubmit(content);
    } else if (buttonText === "cancel") {
      setContent("");
      handleSubmit("");
    } else {
      let new_content = content + buttonText;
      new_content = new_content.length > 4 ? content : new_content;

      setContent(new_content);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* <div className="key-press-area">Key pressed</div> */}

      <div className="numpad">
        <table>
          <tr style={{ position: "relative" }}>
            <TextField
              style={{ width: "100%", position: "absolute" }}
              type="password"
              margin="normal"
              value={content}
              size="medium"
              onClick={null}
              InputProps={{ readOnly: true }}
              // onChange={(event) => setMileage(event.target.value)}
              // label="Password"
              variant="filled"
            />
          </tr>
          <tr>
            <td
              className="btn square key-seven"
              onClick={() => handlePadInput("1")}
            >
              <span className="text">1</span>
            </td>
            <td
              className="btn square key-eight"
              onClick={() => handlePadInput("2")}
            >
              <span className="text">2</span>
            </td>
            <td
              className="btn square key-nine"
              onClick={() => handlePadInput("3")}
            >
              <span className="text">3</span>
            </td>

            {/* <td className="btn key-add" rowspan="2"><span className="text">Set</span></td> */}
            <td className="btn key-add" rowSpan="2">
              <Button
                disabled={!(content && content.length === 4)}
                color="primary"
                style={{ height: "100%", width: "100%", padding: "0px" }}
                variant="contained"
                startIcon={<AddCircle />}
                onClick={() => handlePadInput("OK")}
              >
                Set
              </Button>
            </td>
          </tr>
          <tr>
            <td
              className="btn square key-four"
              onClick={() => handlePadInput("4")}
            >
              <span className="text">4</span>
            </td>
            <td
              className="btn square key-five"
              onClick={() => handlePadInput("5")}
            >
              <span className="text">5</span>
            </td>
            <td
              className="btn square key-six"
              onClick={() => handlePadInput("6")}
            >
              <span className="text">6</span>
            </td>
          </tr>
          <tr>
            <td
              className="btn square key-one"
              onClick={() => handlePadInput("7")}
            >
              <span className="text">7</span>
            </td>
            <td
              className="btn square key-two"
              onClick={() => handlePadInput("8")}
            >
              <span className="text">8</span>
            </td>
            <td
              className="btn square key-three"
              onClick={() => handlePadInput("9")}
            >
              <span className="text">9</span>
            </td>
            <td className="btn key-add" rowSpan="2">
              <Button
                color="secondary"
                style={{ height: "100%", width: "100%", padding: "0px" }}
                variant="outlined"
                onClick={() => handlePadInput("cancel")}
              >
                Cancel
              </Button>
            </td>
          </tr>
          <tr>
            <td
              className="btn key-zero"
              colSpan="2"
              onClick={() => handlePadInput("0")}
            >
              <span className="text">0</span>
            </td>
            <td
              className="btn square key-decimal"
              onClick={() => handlePadInput("X")}
            >
              <span className="text">Clear</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

const DialBoxContainer = ({ handleSubmit, open, setIsOpen }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "green",
      "& .MuiPaper-root .MuiDialog-paperScrollPaper .MuiDialog-paper .MuiDialogTitle-root":
        {
          backgroundColor: "#0f0e15",
        },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        keepMounted
        onClose={setIsOpen}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{ backgroundColor: "#0f0e15" }}
        >
          Enter Password
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#0f0e15" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <NumberPad handleSubmit={handleSubmit} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialBoxContainer;
