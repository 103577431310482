import React, { useState, useCallback, useEffect } from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import AppMainToolbar from "components/MainToolbar";
import StreetviewIcon from "@mui/icons-material/Streetview";
import AppBar from "@mui/material/AppBar";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "components/TabPanel";
import MaintenanceTabPage from "pages/MaintenanceTabPage";
import PositionPage from "pages/PositionPage";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { fetchToken, onMessageListener } from "../firebase";
import { sessionActions } from "store";

import { orange, yellow, cyan, green, pink } from "@mui/material/colors";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import TripReportPage from "pages/reports/TripReportPage";
import StopReportPage from "pages/reports/StopReportPage";
import DriverTabPage from "pages/DriverTabPage";
import SettingTabPage from "pages/SettingTabPage";
import GeofencesTab from "GeofencesTab";

import MapIcon from "@mui/icons-material/Map";
import PublicIcon from "@mui/icons-material/Public";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import PersonIcon from "@mui/icons-material/Person";
import VpnLockOutlinedIcon from "@mui/icons-material/VpnLockOutlined";
import AirlineSeatReclineExtraOutlinedIcon from "@mui/icons-material/AirlineSeatReclineExtraOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import _ from "lodash";
import { map } from "map/core/MapView";
import { useEffectAsync } from "reactHelper";
import { useAttributePreference } from "../common/util/preferences";
import MainMap from "./MainMap";
import MainToolbar from "./MainToolbar";
import useFilter from "./useFilter";
import EventsDrawer from "./EventsDrawer";
import usePersistedState from "../common/util/usePersistedState";
import { devicesActions } from "../store";
import StatusCard from "../common/components/StatusCard";
import BottomMenu from "../common/components/BottomMenu";
import DeviceList from "./DeviceList";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  tabs: {
    background: "#1F1D2B",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
    },
  },
  innerTab: {
    backgroundColor: "rgb(0, 0, 0)",
    height: `${window.innerHeight - 64}px`,
    overflowY: "scroll",
    paddingLeft: "370px",
    paddingTop: "84px",
    paddingRight: "4px",
    paddingBottom: "8px",
  },
  tabbar: {
    top: "64px",
    left: "370px",
    width: "calc(100% - 374px)",
    display: "flex",
    position: "absolute",
    marginTop: "4px",
    zIndex: "999",
  },
  sidebar: {
    pointerEvents: "none",
    background: "#000",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      left: 0,
      top: "64px",
      height: `calc(100% - ${theme.spacing(3)} - 48px)`,
      width: theme.dimensions.drawerWidthDesktop,
      margin: theme.spacing(0.5),
      zIndex: 3,
    },
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  header: {
    pointerEvents: "auto",
    zIndex: 6,
  },
  footer: {
    pointerEvents: "auto",
    zIndex: 5,
  },
  middle: {
    flex: 1,
    display: "grid",
  },
  contentMap: {
    pointerEvents: "auto",
    gridArea: "1 / 1",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 168px) !important",
    },
  },
  contentList: {
    pointerEvents: "auto",
    gridArea: "1 / 1",
    zIndex: 4,
  },
}));

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const MainPage = () => {
  const detail_color = orange[500]; //  #ff9800
  const today_color = yellow.A400; // #ffea00
  const map_color = cyan.A400; // #00e5ff
  const settings_color = green.A400; // #3f51b5
  const PersonIcon_color = pink[500];
  const Maintenance_color = cyan.A400;

  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [deviceName, setDeviceName] = useState([]);
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const mapOnSelect = useAttributePreference("mapOnSelect", true);

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const selectedDevice = useSelector((state) => state.devices.selectedItem);
  const [item, setItem] = useState();
  const [height, setheight] = useState(window.innerHeight);
  const Toggle = useSelector((state) => state.session.isShow);
  const deviceListOpen = useSelector((state) => state.session.deviceListOpen);
  const value = useSelector((state) => state.session.SelectedTab);
  const positions = useSelector((state) => state.session.positions);
  const user = useSelector((state) => state.session.user);
  const [filteredPositions, setFilteredPositions] = useState([]);
  const selectedPosition = filteredPositions.find(
    (position) => selectedDeviceId && position.deviceId === selectedDeviceId
  );

  const [notification, setNotification] = useState({ title: "", body: "" });
  const [show, setShow] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [currentNotificationToken, setCurrentNotificationToken] =
    useState(null);

  useEffect(() => {
    if (currentNotificationToken) {
      let finalNotificationToken = [];
      if (user?.attributes?.notificationTokens) {
        const userNotificationTokenArr =
          user.attributes.notificationTokens.split(",");
        if (!userNotificationTokenArr.includes(currentNotificationToken)) {
          finalNotificationToken = [
            ...userNotificationTokenArr,
            currentNotificationToken,
          ];
        } else {
          finalNotificationToken = [...userNotificationTokenArr];
        }
      } else {
        finalNotificationToken = [currentNotificationToken];
      }

      fetch(`/api/users/${user.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...user,
          attributes: {
            ...user.attributes,
            notificationTokens: finalNotificationToken.join(","),
          },
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)
        });
    }
  }, [currentNotificationToken]);

  useEffect(() => {
    //fetchToken(setTokenFound, setCurrentNotificationToken);
  }, []);

  // onMessageListener()
  //   .then((payload) => {
  //     setNotification({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     });
  //     setShow(true);
  //   })
  //   .catch((err) => console.log("failed: ", err));

  const onShowNotificationClicked = () => {
    setNotification({
      title: "Notification",
      body: "This is a test notification",
    });
    setShow(true);
  };

  const [filteredDevices, setFilteredDevices] = useState([]);

  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = usePersistedState("filter", {
    statuses: [],
    groups: [],
  });
  const [filterSort, setFilterSort] = usePersistedState("filterSort", "");
  const [filterMap, setFilterMap] = usePersistedState("filterMap", false);

  const [devicesOpen, setDevicesOpen] = useState(desktop);
  const [eventsOpen, setEventsOpen] = useState(false);

  const onEventsClick = useCallback(() => setEventsOpen(true), [setEventsOpen]);
  const devices = useSelector((state) => Object.values(state.devices.items));
  const isDriverVisible = useSelector((state) => state.setting.isDriverVisible);
  const isMaintenanceVisible = useSelector(
    (state) => state.setting.isMaintenanceVisible
  );
  const isDriverBehaviourVisible = useSelector(
    (state) => state.setting.isDriverBehaviourVisible
  );

  const handleChange = (event, newValue) => {
    if (newValue != 2) {
      dispatch(sessionActions.SelectTab(newValue));
    }
  };

  function a11yProps(index) {
    return {
      // id: `simple-tab-${index}`,
      // "aria-controls": `simple-tabpanel-${index}`,

      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (!!selectedDeviceId && selectedDevice.hasOwnProperty("name")) {
      setDeviceName(selectedDevice?.name);
    }
    if (!desktop && mapOnSelect && selectedDeviceId) {
      setDevicesOpen(false);
    }
  }, [desktop, mapOnSelect, selectedDeviceId]);

  useFilter(
    keyword,
    filter,
    filterSort,
    filterMap,
    positions,
    setFilteredDevices,
    setFilteredPositions
  );

  return (
    <div className={classes.root}>
      <AppMainToolbar />
      {desktop && (
        <>
          <TabPanel
            value={value}
            index={0}
            style={{
              height: isMobile
                ? height - 300
                : height - (selectedDeviceId && selectedDeviceId ? 64 : 64),
            }}
            // screenHeight={
            //   isMobile ? height - 300 : height - (selectedDeviceId ? 134 : 68)
            // }
          >
            <div
              className={classes.contentMap}
              style={{
                height: isMobile
                  ? height - 300
                  : height - (selectedDeviceId && selectedDeviceId ? 64 : 64),
              }}
            >
              <MainMap
                filteredPositions={filteredPositions}
                selectedPosition={selectedPosition}
                onEventsClick={onEventsClick}
              />
            </div>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            className={classes.innerTab}
            screenHeight={height - 115}
            style={{
              backgroundColor: "#000000",
              height: height - 64,
              overflowY: "scroll",
            }}
          >
            <Card className={classes.rootReport}>
              <CardContent className={classes.contentReport}>
                <TripReportPage id={selectedDeviceId} />
              </CardContent>
            </Card>
            <br />
            <Card className={classes.rootReport}>
              <CardContent className={classes.contentReport}>
                <StopReportPage id={selectedDeviceId} />
              </CardContent>
            </Card>
            <br />
          </TabPanel>
          <TabPanel
            className={classes.innerTab}
            value={value}
            index={2}
            style={{
              backgroundColor: "#000000",
              height: height - 64,
              overflowY: "scroll",
            }}
          >
            <PositionPage
              deviceId={selectedDeviceId}
              id={selectedPosition?.id}
            />
          </TabPanel>

          <TabPanel
            value={value}
            className={classes.innerTab}
            index={3}
            screenHeight={height}
            style={{
              backgroundColor: "#000000",
              height: height - 64,
              overflowY: "scroll",
            }}
          >
            <DriverTabPage />
          </TabPanel>

          <TabPanel
            className={classes.innerTab}
            value={value}
            index={4}
            style={{
              backgroundColor: "#000000",
              height: height - 64,
              overflowY: "scroll",
            }}
          >
            <MaintenanceTabPage />
          </TabPanel>
          <TabPanel
            value={value}
            className={classes.innerTab}
            index={5}
            screenHeight={height}
          >
            <GeofencesTab height={height} />
          </TabPanel>
          {/* <TabPanel value={value} index={6} screenHeight={height}>
                <DriversBehaviourTab />
            </TabPanel> */}
          <TabPanel
            className={classes.innerTab}
            value={value}
            index={7}
            style={{
              backgroundColor: "#000000",
              height: height - 64,
              overflowY: "scroll",
            }}
          >
            <SettingTabPage
              item={item}
              deviceName={deviceName}
              setItem={setItem}
            />
          </TabPanel>
        </>
      )}
      <div
        className={classes.sidebar}
        style={{
          height: devicesOpen
            ? `calc(100% - ${theme.spacing(3)} - 48px)`
            : "initial",
        }}
      >
        <Paper square elevation={3} className={classes.header}>
          <MainToolbar
            filteredDevices={filteredDevices}
            devicesOpen={devicesOpen}
            setDevicesOpen={setDevicesOpen}
            keyword={keyword}
            setKeyword={setKeyword}
            filter={filter}
            setFilter={setFilter}
            filterSort={filterSort}
            setFilterSort={setFilterSort}
            filterMap={filterMap}
            setFilterMap={setFilterMap}
          />
        </Paper>
        <div className={classes.middle}>
          {!desktop && (
            <div className={classes.contentMap}>
              <MainMap
                filteredPositions={filteredPositions}
                selectedPosition={selectedPosition}
                onEventsClick={onEventsClick}
              />
            </div>
          )}
          <Paper
            square
            className={classes.contentList}
            style={devicesOpen ? {} : { visibility: "hidden" }}
          >
            <DeviceList devices={filteredDevices} />
          </Paper>
        </div>
      </div>
      {desktop && (
        <div className={classes.tabbar}>
          <AppBar position="static">
            {!isMobile && Toggle && (
              <Tabs
                value={value}
                onChange={handleChange}
                // aria-label="simple tabs example"
                variant="scrollable"
                indicatorColor="primary"
                scrollButtons="auto"
                className={classes.tabs}
              >
                <Tab
                  icon={<PublicIcon style={{ color: map_color }} />}
                  className={classes.tabMenu}
                  label="Map"
                  {...a11yProps(0)}
                  style={{ color: "#FFFFFF" }}
                />
                <Tab
                  icon={<EventAvailableIcon style={{ color: today_color }} />}
                  className={classes.tabMenu}
                  label="Today"
                  {...a11yProps(1)}
                  style={{ color: "#FFFFFF" }}
                />
                {/* <Tab
                icon={<TextFieldsIcon style={{ color: detail_color }} />}
                className={classes.tabMenu}
                label="Details"
                {...a11yProps(2)}
                style={{ color: "#FFFFFF" }}
              /> */}

                {!!selectedPosition && (
                  <Tab
                    icon={<StreetviewIcon style={{ color: detail_color }} />}
                    component="a"
                    className={classes.tabMenu}
                    label="Street View"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${selectedPosition.latitude}%2C${selectedPosition.longitude}&heading=${selectedPosition.course}`}
                    style={{ color: "#FFFFFF" }}
                  />
                )}

                <Tab
                  icon={
                    <PersonIcon
                      style={
                        isDriverVisible
                          ? { color: PersonIcon_color }
                          : { color: "#6d6d6d" }
                      }
                    />
                  }
                  className={classes.tabMenu}
                  label="Drivers"
                  {...a11yProps(3)}
                  style={
                    isDriverVisible
                      ? { color: "#FFFFFF" }
                      : { color: "#6d6d6d" }
                  }
                  disabled={!isDriverVisible}
                />
                <Tab
                  icon={
                    <SettingsIcon
                      style={
                        isMaintenanceVisible
                          ? { color: Maintenance_color }
                          : { color: "#6d6d6d" }
                      }
                    />
                  }
                  className={classes.tabMenu}
                  label="Maintenance"
                  {...a11yProps(4)}
                  style={
                    isMaintenanceVisible
                      ? { color: "#FFFFFF" }
                      : { color: "#6d6d6d" }
                  }
                  disabled={!isMaintenanceVisible}
                />

                <Tab
                  icon={<VpnLockOutlinedIcon style={{ color: map_color }} />}
                  className={classes.tabMenu}
                  label="Geofences"
                  {...a11yProps(5)}
                  style={{ color: "#FFFFFF" }}
                />
                <Tab
                  icon={
                    <AirlineSeatReclineExtraOutlinedIcon
                      style={
                        isDriverBehaviourVisible
                          ? { color: detail_color }
                          : { color: "#6d6d6d" }
                      }
                    />
                  }
                  className={classes.tabMenu}
                  label="Driver Behaviour"
                  {...a11yProps(6)}
                  style={
                    isDriverBehaviourVisible
                      ? { color: "#FFFFFF" }
                      : { color: "#6d6d6d" }
                  }
                  disabled={!isDriverBehaviourVisible}
                />
                <Tab
                  icon={<SettingsIcon style={{ color: settings_color }} />}
                  className={classes.tabMenu}
                  label="Settings"
                  {...a11yProps(7)}
                  style={{ color: "#FFFFFF" }}
                />
              </Tabs>
            )}
          </AppBar>
        </div>
      )}
      <Snackbar
        style={{ display: "flex" }}
        id="showRefreshApp"
        open={show}
        autoHideDuration={5000}
        onClose={() => {
          setShow(false);
        }}
        sx={{ bottom: { xs: 40, sm: 40 } }}
      >
        <Alert severity="warning" sx={{ width: "100%" }}>
          {!!notification.title && <h1>{notification.title}</h1>}
          {!!notification.body && notification.body}
        </Alert>
      </Snackbar>
      <EventsDrawer open={eventsOpen} onClose={() => setEventsOpen(false)} />
      {selectedDeviceId && !value && (
        <>
          {!(devicesOpen && !desktop) && (
            <StatusCard
              deviceId={selectedDeviceId}
              position={selectedPosition}
              desktopPadding={theme.dimensions.drawerWidthDesktop}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MainPage;
