import { Typography, Link } from "@mui/material";

const Copyright = ({ className }) => (
  <Typography variant="body2" align="center" className={className}>
    {"Copyright © "}
    <Link color="inherit" href="#">
      shareyourdrive
    </Link>{" "}
    {new Date().getFullYear()}
  </Typography>
);

export default Copyright;
