import {
  amber,
  blueGrey,
  grey,
  green,
  indigo,
  red,
  common,
} from "@mui/material/colors";

const colors = {
  white: common.white,
  background: "#12151e",
  primary: "#3f51b5",
  secondary: "#f50057",
  positive: green[500],
  medium: amber[700],
  negative: red[500],
  neutral: grey[500],
  geometry: "#3bb2d0",
  blueGrey: grey[500],
  sidebarGrey: "#191c24",
};

export default {
  text: {
    primary: "#fff",
    secondary: "#fff",
    default: "#fff",
  },
  background: {
    default: colors.background,
  },
  primary: {
    main: colors.primary,
  },
  secondary: {
    main: colors.secondary,
    contrastText: colors.white,
  },
  colors,
};
