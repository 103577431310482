import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Button,
  TextField,
  ButtonGroup,
  createTheme,
} from "@mui/material";
import { useTranslation } from "common/components/LocalizationProvider";
import { useSelector } from "react-redux";
import moment from "moment";
import { convertUnicode } from "reactHelper";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles, ThemeProvider } from "@mui/styles";
import _ from "lodash";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import EventIcon from "@mui/icons-material/Event";

const useStyles = makeStyles((theme) => ({
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  customDatePicker: {
    "& label": {
      color: "#fff",
    },
    "& input": {
      color: "#fff",
    },
  },
  customDatePickerDialog: {
    "& .MuiDialogContent-root": {
      background: "#0f0e15 !important",
      color: "red",
    },
    "& .MuiDialog-paperScrollPaper": {
      background: "#1F1D2B",
      color: "#fff",
    },
    "& .MuiDayPicker-weekDayLabel": {
      color: "#fff",
    },
    "& .MuiPickersCalendarHeader-switchViewButton": {
      color: "#fff",
    },
    "& .MuiPickersArrowSwitcher-button": {
      color: "#fff",
    },
    "& .MuiInputLabel-formControl": {
      color: "#fff",
    },
    "& .MuiPickersToolbar-root": {
      background: "#3f51b5",
      color: "#fff",
    },
    "& .MuiPickersToolbar-root span": {
      color: "#fff",
    },
    "& .PrivatePickersToolbarText-root": {
      color: "#fff",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#f50057",
    },
    "& .MuiTabs-flexContainer": {
      background: "#3f51b5",
      color: "#fff",
    },
    "& .MuiTabs-flexContainer button": {
      color: "#fff",
    },
    "& .MuiDialogActions-root button": {
      color: "#3f51b5",
    },
    "& .MuiPickersDay-root": {
      color: "#fff",
      background: "none",
    },
    "& .Mui-selected.MuiPickersDay-root": {
      color: "#fff",
      background: "#3f51b5",
    },
    "& .MuiClock-amButton": {
      color: "#fff",
    },
    "& .MuiClock-pmButton": {
      color: "#fff",
    },
    "& .MuiClockPicker-root": {
      background: "#0f0e15",
    },
    "& .MuiClockNumber-root": {
      color: "#fff",
    },
    "& .MuiClockNumber-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiCalendarOrClockPicker-mobileKeyboardInputView .MuiInputBase-root": {
      color: "#fff",
    },
    "& .MuiCalendarOrClockPicker-mobileKeyboardInputView label": {
      color: "#fff",
    },
  },
  form: {
    padding: theme.spacing(1, 2, 2),
    backgroundColor: "#12151e", // '#1F1D2B'
  },
}));

const materialTheme = createTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        color: "#fff",
      },
    },
    MuiIconButton: {
      root: {
        color: "#2436ac",
      },
    },
    MuiInputBase: {
      input: {
        color: "#fff",
      },
      root: {
        color: "#fff",
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: "#000",
      },
    },
    MuiIconButton: {
      root: {
        color: "#000",
      },
    },
  },
});

const ReportFilter = ({ children, handleSubmit, showOnly, onDeviceChange }) => {
  const devices = useSelector((state) => Object.values(state.devices.items));
  const [deviceId, setDeviceId] = useState();
  const [period, setPeriod] = useState("today");
  const [from, setFrom] = useState(moment().subtract(1, "hour"));
  const [to, setTo] = useState(moment());

  const classes = useStyles();
  const t = useTranslation();

  const handleClick = (mail, json) => {
    let selectedFrom;
    let selectedTo;
    switch (period) {
      case "today":
        selectedFrom = moment().startOf("day");
        selectedTo = moment().endOf("day");
        break;
      case "yesterday":
        selectedFrom = moment().subtract(1, "day").startOf("day");
        selectedTo = moment().subtract(1, "day").endOf("day");
        break;
      case "thisWeek":
        selectedFrom = moment().startOf("week");
        selectedTo = moment().endOf("week");
        break;
      case "previousWeek":
        selectedFrom = moment().subtract(1, "week").startOf("week");
        selectedTo = moment().subtract(1, "week").endOf("week");
        break;
      case "thisMonth":
        selectedFrom = moment().startOf("month");
        selectedTo = moment().endOf("month");
        break;
      case "previousMonth":
        selectedFrom = moment().subtract(1, "month").startOf("month");
        selectedTo = moment().subtract(1, "month").endOf("month");
        break;
      default:
        selectedFrom = from;
        selectedTo = to;
        break;
    }

    const accept = json
      ? "application/json"
      : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    handleSubmit(
      deviceId,
      selectedFrom.toISOString(),
      selectedTo.toISOString(),
      mail,
      { Accept: accept }
    );
  };

  useEffect(() => {
    if (typeof onDeviceChange == "function") {
      onDeviceChange(deviceId);
    }
  }, [deviceId]);

  return (
    <>
      <FormControl variant="filled" margin="normal" fullWidth>
        <InputLabel>Vehicle</InputLabel>
        <Select
          value={deviceId}
          onChange={(e) => {
            setDeviceId(e.target.value);
          }}
          MenuProps={{ classes: { paper: classes.dropdownStyle } }}
        >
          {_.orderBy(devices, [(item) => item.name.toLowerCase()], ["asc"]).map(
            (device) => (
              <MenuItem value={device.id}>
                {convertUnicode(device.name)}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      <FormControl variant="filled" margin="normal" fullWidth>
        <InputLabel>{t("reportPeriod")}</InputLabel>
        <Select
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          MenuProps={{ classes: { paper: classes.dropdownStyle } }}
        >
          <MenuItem value="today">{t("reportToday")}</MenuItem>
          <MenuItem value="yesterday">{t("reportYesterday")}</MenuItem>
          <MenuItem value="thisWeek">{t("reportThisWeek")}</MenuItem>
          <MenuItem value="previousWeek">{t("reportPreviousWeek")}</MenuItem>
          <MenuItem value="thisMonth">{t("reportThisMonth")}</MenuItem>
          <MenuItem value="previousMonth">{t("reportPreviousMonth")}</MenuItem>
          <MenuItem value="custom">{t("reportCustom")}</MenuItem>
        </Select>
      </FormControl>
      {period === "custom" && (
        <ThemeProvider theme={materialTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              className={classes.customDatePicker}
              label={t("reportFrom")}
              inputFormat="DD/MM/YYYY hh:mm a"
              value={from}
              onChange={(date) => {
                setFrom(date);
              }}
              DialogProps={{
                className: classes.customDatePickerDialog,
              }}
              renderInput={(params) => (
                <TextField
                  margin="normal"
                  variant="filled"
                  className={classes.datePickerInput}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...params}
                />
              )}
            />
            <MobileDateTimePicker
              className={classes.customDatePicker}
              label={t("reportTo")}
              inputFormat="DD/MM/YYYY hh:mm a"
              value={to}
              minDate={from}
              onChange={(date) => setTo(date)}
              DialogProps={{
                className: classes.customDatePickerDialog,
              }}
              renderInput={(params) => (
                <TextField
                  margin="normal"
                  variant="filled"
                  className={classes.datePickerInput}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </ThemeProvider>
      )}
      {children}
      <FormControl margin="normal" fullWidth>
        <ButtonGroup
          color="primary"
          orientation="vertical"
          disabled={!deviceId}
        >
          <Button onClick={() => handleClick(false, true)}>
            {t("reportShow")}
          </Button>
          {!showOnly && (
            <Button onClick={() => handleClick(false, false)}>
              {t("reportExport")}
            </Button>
          )}
          {!showOnly && (
            <Button onClick={() => handleClick(true, false)}>
              {t("reportEmail")}
            </Button>
          )}
        </ButtonGroup>
      </FormControl>
    </>
  );
};

export default ReportFilter;
