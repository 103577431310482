import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dimensions from "../../common/theme/dimensions";
import { map } from "../core/MapView";
import { usePrevious } from "../../reactHelper";
import { useAttributePreference } from "../../common/util/preferences";

const MapSelectedDevice = () => {
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const previousDeviceId = usePrevious(selectedDeviceId);
  const mapFollow = useAttributePreference("mapFollow", false);

  const position = useSelector(
    (state) => state.session.positions[selectedDeviceId]
  );

  const user = useSelector((state) => Object.values(state.session.user));
  const longitude = user[10];
  const latitude = user[9];
  const zoom = user[11];
  const dispatch = useDispatch();

  // const mapData = useSelector(state => {
  //   if (state.devices.selectedId) {
  //     const position = state.session.positions[state.devices.selectedId] || null;
  //     if (position) {
  //       console.log("In center");
  //       console.log(position);
  //       return position;
  //     }
  //   }
  //   return null;
  // });

  // const mapCenter = useSelector(state => {
  //   if (state.devices.selectedId) {
  //     const position = state.session.positions[state.devices.selectedId] || null;
  //     if (position) {
  //       return [position.longitude, position.latitude];
  //     }
  //   }
  //   return null;
  // });

  // const recenterMap = () => {
  //   if(!selectedDeviceId){
  //     map.setCenter([longitude, latitude]);
  //     //map.setZoom(zoom);
  //     map.setZoom(map.getZoom());
  //     map.setCenter([longitude, latitude]);
  //     map.flyTo({center:[longitude, latitude]})
  //   } else {
  //     if(mapCenter)
  //     {
  //       map.setCenter(mapCenter)
  //       map.setZoom('17') ;
  //     }
  //     map.easeTo({ center: mapCenter});
  //   }

  // }

  useEffect(() => {
    if ((selectedDeviceId !== previousDeviceId || mapFollow) && position) {
      map.easeTo({
        center: [position.longitude, position.latitude],
        offset: [0, -dimensions.popupMapOffset / 2],
      });
    }
  });

  return null;
};

export default MapSelectedDevice;
